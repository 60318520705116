@keyframes fixedHeader{
    0%{
        top: 0%;
        transform: translateY(-100%);
    }
    100%{
        top: 0;
        transform: translateY(0%);

        @include shadow(s1);
    }
}

@keyframes bounceInUp {
    0% {
        transform: translateY(2000px);
    }

    60% {
        transform: translateY(-30px);
    }

    80% {
        transform: translateY(10px);
    }

    100% {
        transform: translateY(0) ;
    }
}

#header{
    // transition: .3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    background-color: white;
    &.scroll{
        top: 0%;
        left: 0;
        position: fixed;
        width: 100%;
        z-index: 999;
        transform: translateY(-100%);

        // animation-delay: 0.1s;
        animation: fixedHeader 1.5s ease forwards;
    }
}


.header {
    max-width: 100%;

    @include shadow(s1);
    position: relative;
    overflow: initial;

    @include rpmin(lg) {
        // overflow-x: hidden;
        box-shadow: initial;
    }

    &-container {
        display: flex;
        overflow: initial;
    }

    &-hamburger {
        position: absolute;
        top: 50%;
        left: 2%;
        transform: translateY(-50%);
        z-index: 1;
        width: 32px;
        height: 30px;
        background-color: transparent;
        cursor: pointer;

        @include rpmin(lg) {
            display: none;
        }

        .line {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 100%;
            height: 4px;
            background-color: map-get($color, bor2);
            border-radius: 4px;

            transition: .3s all linear;

            &:first-child {
                top: 10%;
                transform: translate(-50%, 0);
            }

            &:last-child {
                bottom: 10%;
                top: auto;
                transform: translate(-50%, 0);
            }
        }

        &.active {
            .line {
                &:not(:first-child):not(:last-child) {
                    display: none;
                }

                &:first-child {
                    top: 50%;
                    transform: translate(-50%, -50%) rotate(45deg);
                }

                &:last-child {
                    bottom: auto;
                    top: 50%;
                    transform: translate(-50%, -50%) rotate(-45deg);
                }
            }
        
            & ~ .header-nav-mobile{
                opacity: 1;
                transform: translate(0%, 0);
            }
        }
    }

    &-nav-mobile {
        position: absolute;
        top: 100%;
        left: 0;
        height: calc(100vh - 64px);
        opacity: 0;
        transform: translate(-100%, 0);
        transition: .3s all linear;

        background-color: map-get($color, fill6);
        z-index: 999;
        width: 100%;
        max-width: 100%;

        overflow: auto;

        @include shadow(s1);

        @include rpmin(sm) {
            width: 450px;
        }

        @include rpmin(lg) {
            display: none;
        }

        .nav {
            border-top: 1px solid map-get($color, bor2);
            padding: 0 24px;

            &__item {
                .search {
                    padding-top: 12px;
                    margin-left: 20px;
                    flex: 1;
                    display: flex;

                    border-bottom: 1px solid map-get($color, bor2);

                    input {
                        flex: 1;
                        background: transparent;
                        border: none;
                        outline: none;

                        &:focus {
                            outline: none;
                            border: none;
                        }

                        color: map-get($color, tx1);
                        @include regular;

                        &::placeholder {
                            color: map-get($color, tx1);
                        }

                        padding: 4px 4px 4px 10px;
                        @include family--primary;
                        font-size: 15px;
                    }

                    button {
                        display: block;

                        background: transparent;
                        border: none;
                        outline: none;

                        svg {
                            .search-btn-item {
                                stroke: map-get($color, tx1);
                                opacity: .3;
                            }
                        }

                        &:hover {
                            svg {
                                .search-btn-item {
                                    opacity: 1;
                                }
                            }
                        }

                        &:focus {
                            outline: none;
                            border: none;
                            opacity: .9;
                        }

                        &:hover {
                            opacity: .9;
                        }
                    }
                }

                .languages {
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    padding: 12px 0;

                    margin: -4px -6px 0 -6px;

                    &>* {
                        padding: 4px 6px 0 6px;
                    }

                    .choose {
                        color: map-get($color, tx1);
                        font-size: 15px;
                    }

                    &-list {
                        flex: 1;
                        display: flex;
                        flex-direction: row;
                        list-style-type: none;
                        align-items: center;
                    }

                    &-item {
                        width: 32px;
                        height: 22px;

                        cursor: pointer;

                        &:not(:first-child) {
                            margin-left: 12px;
                        }

                        position: relative;

                        &::after {
                            content: "";
                            position: absolute;
                            bottom: 0%;
                            left: 0%;
                            width: 100%;
                            height: 100%;
                            background-color: #1B2335;
                            opacity: 0.5;
                            transition: .3s ease;
                        }

                        &:hover,
                        &.active {
                            &::after {
                                height: 0%;
                                opacity: 0;
                            }
                        }
                    }
                }

                .menu {
                    display: flex;
                    flex-direction: column;
                    list-style: none;

                    &-item {
                        border-top: 1px solid map-get($color, bor2);

                        &>a {
                            cursor: pointer;
                            // display: block;
                            padding: 12px 0;

                            color: map-get($color, tx1);

                            @include bold;
                            text-transform: uppercase;
                            // @include sizeBs(bodyText3);
                            font-size: 15px;

                            display: flex;
                            flex-direction: row;
                            align-items: center;

                            span{                            
                                flex: 1;
                            }

                            svg{
                                transition: .5s ease;
                            }
                        }

                        &:focus,
                        &:hover,
                        &.active {
                            &>a {
                                color: map-get($color, hover1);   
                            }
                        }

                        &__sub{
                            height: 0;
                            opacity: 0;
                            transition: all .5s ease;
                            clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
                            visibility: hidden;

                        }

                        &.clicked{
                            a{
                                svg{
                                    transform: rotate(180deg);
                                }
                            }
                            .menu-item__sub{
                                opacity: 1;
                                height: auto;
                                clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
                                visibility: initial;
                            }
                        } 

                        &__sub{
                            ul{
                                list-style-type: none;
                                li{
                                    &.active{
                                        &>a{
                                            color: map-get($color, hover1);
                                        }
                                    }
                                    a{
                                        padding: 12px 0;
                                        color: map-get($color, tx1);

                                        @include bold;
                                        text-transform: uppercase;
                                        // @include sizeBs(bodyText3);
                                        font-size: 15px;

                                        display: flex;
                                        flex-direction: row;
                                        align-items: center;

                                        span{                            
                                            flex: 1;
                                        }

                                        svg{
                                            transition: all .5s ease;
                                        }
                                    }

                                    ul{
                                        height: 0;
                                        opacity: 0;
                                        transition: all .5s ease;
                                        clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
                                        visibility: hidden;
                                    }

                                    &.clicked{
                                        a{
                                            svg{
                                                transform: rotate(180deg);
                                            }
                                        }
                                        ul{
                                            opacity: 1;
                                            height: auto;
                                            clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
                                            visibility: initial;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    &-logo {
        position: relative;
        height: 48px;

        svg {
            height: 48px;
            width: 140px;
        }

        margin: 8px auto;

        @include rpmin(lg) {
            height: 72px;
            margin-top: 12px;
            margin-bottom: 12px;

            svg {
                height: 72px;
                width: 176px;
            }
        }

        @include rpmin(xl) {
            height: 86px;
            margin-top: 19px;
            margin-bottom: 19px;

            svg {
                width: 248px;
                height: 86px;
            }
        }
    }

    &-nav {
        display: none;

        @include rpmin(lg) {
            flex: 1;
            display: flex;
            flex-direction: column;
        }

        &-top {
            background-color: #1F6BB4;
            width: 50%;
            margin-left: 50%;
            padding: 15px 0;

            display: flex;
            align-items: center;
            justify-content: flex-end;

            .search {
                flex: 1;
                display: flex;

                input {
                    flex: 1;
                    background: transparent;
                    border: none;
                    outline: none;

                    &:focus {
                        outline: none;
                        border: none;
                    }

                    color: white;
                    @include bold;

                    &::placeholder {
                        color: white;
                    }

                    padding: 4px;
                    @include family--primary;
                }

                button {
                    display: block;

                    background: transparent;
                    border: none;
                    outline: none;

                    &:focus {
                        outline: none;
                        border: none;
                        opacity: .9;
                    }

                    &:hover {
                        opacity: .9;
                    }
                }
            }

            .languages {
                display: flex;
                flex-direction: row;
                list-style-type: none;
                align-items: center;

                &-item {
                    width: 32px;
                    height: 22px;

                    cursor: pointer;

                    &:not(:first-child) {
                        margin-left: 12px;
                    }

                    position: relative;

                    &::after {
                        content: "";
                        position: absolute;
                        bottom: 0%;
                        left: 0%;
                        width: 100%;
                        height: 100%;
                        background-color: #1B2335;
                        opacity: 0.5;
                        transition: .3s ease;
                    }

                    &:hover,
                    &.active {
                        &::after {
                            height: 0%;
                            opacity: 0;
                        }
                    }
                }
            }

            &>* {
                padding-left: 30px;
                margin-left: 30px;
                border-left: 2px solid white;
            }

            position: relative;

            &::before {
                content: "";
                position: absolute;
                top: 0;
                right: calc(100% - 1px);
                height: 100%;
                width: 100px;
                background-color: #1F6BB4;
                clip-path: polygon(100% 0, 0 0, 100% 101%);
            }

            &::after {
                content: "";
                position: absolute;
                top: 0;
                left: calc(100% - 1px);
                width: 1000px;
                background-color: #1F6BB4;
                height: 100%;
            }
        }

        &-bottom {
            width: 100%;
            flex: 1;

            .menu {
                display: flex;
                flex-direction: row;
                list-style: none;
                justify-content: flex-end;
                align-items: center;
                height: 100%;

                &-item {
                    white-space: nowrap;
                    height: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    &:not(:first-child) {
                        // @include spaceBs(s40, margin-left);
                        margin-left: 22px;

                        @include rpmin(xl) {
                            margin-left: 28px;
                        }
                        @include rpmin(xxl) {
                            margin-left: 36px;
                        }
                        @include rpmin(xxl) {
                            margin-left: 36px;
                        }

                        @include rpmin(yl) {
                            // margin-left: 48px;
                            margin-left: 3.4vw;
                        }
                    }

                    &>a {
                        color: #171717;
                        @include bold;
                        text-transform: uppercase;
                        // @include sizeBs(bodyText3);
                        font-size: 1.6rem;

                        @include rpmin(xxl) {
                            font-size: 1.7rem;
                        }

                        @include rpmin(yl) {
                            font-size: 1.9rem;
                        }
                    }

                    &:hover,
                    &.active {
                        &>a {
                            color: map-get($color, hover1);
                        }
                    }

                    &:hover{
                        .menu-item__sub{
                            display: block;
                        }
                    }

                    position: relative;
                    &__sub{
                        display: none;

                        z-index: 999;
                        background-color: transparent;
                        position: absolute;
                        top: 100%;
                        left: 50%;
                        transform: translateX(-50%);

                        // max-height: 50vh;
                        // overflow-y: auto;

                        ul{
                            // margin-top: 8px;
                            border-top: 6px solid #1F6BB4;

                            list-style-type: none;
                            box-shadow: 0 3px 6px rgba(0,0,0,.16);
                            /* width */
                            &::-webkit-scrollbar {
                                width: 3px;
                            }
                            
                            /* Tra  ck */
                            &::-webkit-scrollbar-track {
                                background: #f1f1f1;
                            }
                            
                            /* Handle */
                            &::-webkit-scrollbar-thumb {
                                background: #888;
                            }
                            
                            /* Handle on hover */
                            &::-webkit-scrollbar-thumb:hover {
                                background: #555;
                            }

                            li{
                                // margin-top: 1px;
                                &:not(:first-child){
                                    // margin-top: 1px;
                                    border-top: 1px solid #1F6BB4;
                                }

                                @media (max-height: 768px){
                                    margin-top: 0px;
                                    &:first-child{
                                        margin-top: 2px;
                                    }
                                }
                                background-color: white;
                                
                                @include shadow(s1);

                                &.active{
                                    &>a{
                                        color: map-get($color, hover1);
                                    }
                                }
                                
                                a{
                                    display: block;
                                    width: 300px;

                                    color: #171717;
                                    @include regular;
                                    font-size: 1.5rem;
                                    padding: 12px 16px;
                                    
                                    @include rpmin(yl) {
                                        font-size: 1.6rem;
                                    }
                                    display: flex;
                                    flex-direction: row;
                                    align-items: center;

                                    span{
                                        flex: 1;
                                        white-space: normal;
                                    }

                                    svg{
                                        transform: rotate(-90deg);
                                    }

                                    &:hover{
                                        color: map-get($color, hover1);
                                    }
                                }

                                position: relative;

                                &:hover{
                                    ul{
                                        display: block;
                                    }
                                }

                                ul{
                                    display: none;

                                    position: absolute;
                                    top: 0;
                                    left: 100%;

                                    li{
                                        &:first-child{
                                            margin-top: 0;
                                        }

                                        margin-left: 8px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}