.block-news-detail {
    position: relative;
    // margin-bottom: 50px;
    
    // .block-link {
    //     position: absolute;
    //     top: 0;
    //     bottom: 0;
    //     right: 0;
    //     left: 0;
    //     z-index: 1;
    // }

    display: flex;
    flex-direction: column;
    @include rpmin(sm){
        flex-direction: row;
    }

    &__img{
        flex: 0 0 100%;
        width: 100%;
        min-width: 180px;
        overflow: hidden;
        position: relative;

        @include rpmin(sm){
            flex: 0 0 40%;
            width: 40%;
        }

        &::after{
            position: absolute;
            bottom: -120%;
            left: 0;
            width: 100%;
            height: 100%;
            content: "";
            opacity: .3;
            transition: .7s cubic-bezier(0.52, 1.64, 0.37, 0.66);
            background-color: map-get($color, bor2 );
        }
        img{
            transition: .5s ease;
        }
    }
    &:hover{
        .block-news__img{
            &::after{
                bottom: 0;
            }
           img{
               transform: scale(1.1);
           }
           
        }
        .block-news__decs{
            &-title{
                color: map-get($color, tx2 );
            }
        }
        .btn-svg{
            transform: rotate(-45deg);
        }
        .btn-seemore_link{
            color: map-get($color, fill3 );
        }
        
    }

    &__decs {
        margin-top: 10px;
        margin-left: 0px;
        width: auto;
        display: flex;
        flex-direction: column;
        // justify-content: space-between;
        flex: 1;

        @include rpmin(sm){
            margin-top: 0;
            margin-left: 10px;
        }

        &>*:not(:last-child) {
            margin-bottom: 4px;
            @include rpmin(sm){
                margin-bottom: 4px;
            }
        }

        &-title {
            transition: .3s ease;
            @include p-line-clamp(2);
            color: map-get($color, txt1);
            text-transform: uppercase;
            line-height: 1.4;
            
            @include sizeBs(bodyText1);
        }

        &-time {
            display: inline-flex;
            align-items: center;

            .time {
                margin-left: 8px;
                color: map-get($color, tx4);
                @include italic;

                @include sizeBs(bodyText1);
            }

            svg{
                width: 14px;
                height: 14px;
            }
        }

        &-infor {
            color: map-get($color, txt1);
            line-height: 1.3;
            @include p-line-clamp(2);
            @include sizeBs(bodyText1);
            
            @include rpmin(xl){
                @include p-line-clamp(3);
            }
        }

        &-btn {
            position: relative;
            z-index: 2;
            margin-top: auto;
        }
    }
}