.ca-1 {
    &-list {
        .ca-1__col:nth-of-type(2n + 1) {
            @include rpmin(md) {
                border-right: 1px solid map-get($color, bor2);
            }
        }

        .ca-1__col:nth-of-type(n + 2) {
            .ca-1__item{
                padding-top: 12px;
                margin-top: 12px;
                border-top: 1px solid map-get($color, bor2);
    
                @include rpmin(md) {
                    padding-top: 0;
                    margin-top: 0;
                    border-top: none;
                }
            }

        }
    }

    &__item {
        height: 100%;

        
    }

    &-map{
        position: relative;
        width: 100%;
        height: 56.25vw;

        min-height: 450px;
        max-height: 725px;

        &>*{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }
}