.block_index-news {
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    
    background-color: white;

    &__img{
        width: 100%;
        overflow: hidden;
        position: relative;
        // flex: 1;
        padding-top: 62.5%;
        height: 0;
        &>img{
            position: absolute;
            top: 0;
            left: 0;
        }
        &::after{
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 100%;
            content: "";
            opacity: 0;
            transition: .8s ease;
            background-color: map-get($color, fill1 );
            mix-blend-mode: multiply;
            z-index: 1;
        }
        img{
            transition: .8s ease;
        }
        &-hover{
            position: absolute;
            top: 50%;
            transform: translate(-50%,-50%) scale(1.3);
            left: 50%;
            width: 30%;
            opacity: 0;
            transition: .8s ease;
            z-index: 2;
        }
    }
    &:hover{
        .block_index-news__img{
            &::after{
                opacity: .6;
            }
            img{
                transform: scale(1.2);
            }
            &-hover{
                opacity: 1;
                transform: translate(-50%,-50%) scale(1);
            }
           
        }
        .block_index-news__decs{
            
            &-title{
                color: map-get($color, tx2 );
            }
        }
        .btn-svg{
            transform: rotate(-45deg);
        }
        .btn-seemore_link{
            color: map-get($color, fill3 );
        }
        
    }

    &__decs {
        height: 100%;
        margin-left: unset;
        width: auto;
        display: flex;
        flex-direction: column;
        flex: 1;
        padding: 25px 20px;
        @include rp(tl){
            padding: 15px;
        }
        &>*:not(:last-child) {
            margin-bottom: map-get($spaceSection, 16);
            @include rp(tx){
                margin-bottom: map-get($spaceSection, 10);
            }
            @include rp(tl){
                margin-bottom: map-get($spaceSection, 8);
            }
        }

        &-title {
            transition: .3s ease;
            @include p-line-clamp(2);
            color: map-get($color, txt1);
            text-transform: uppercase;
            line-height: 1.4;
            @include rp(tx){
                @include p-line-clamp(1);
            }
            @include titleBs(small);
        }

        &-time {
            display: inline-flex;
            align-items: center;

            .time {
                margin-left: 10px;
                color: map-get($color, tx1);
                @include italic;

                @include sizeBs(bodyText1);
            }
        }

        &-infor {
            color: map-get($color, txt1);
            line-height: 1.3;
            @include p-line-clamp(2);

            @include sizeBs(bodyText1);
            
            @include rpmin(xl){
                @include p-line-clamp(3);
            }
        }

        &-btn {
            position: relative;
            z-index: 2;
            margin-top: auto;
        }
    }
}