.banner{
    position: relative;
    height: 0;
    
    padding-top: 160%;
    @include rpmin(sm){
        padding-top: 120%;
    }
    @include rpmin(md){
        padding-top: 75%;
    }
    @include rpmin(lg){
        padding-top: 56.25%;
    }

    &-wrapper{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    
        .swiper, .swiper-wrapper, .swiper-slide{
            height: 100%;
        }
    }

    &-slide{
        height: 100%;
    }
    &-img{
        // position: absolute;
        // top: 0;
        // left: 0;
        width: 100%;
        height: 100%;

        &::after{
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 1;
            opacity: 0.3;
            border: 1px solid map-get($color, bor2);
            background-color: map-get($color, fill8);
        }
    }

    &-content{
        z-index: 1;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 96%;

        @include rpmin(md){
            width: 82%;
        }
        @include rpmin(lg){
            width: 74%;
        }
        @include rpmin(xl){
            width: 72%;
        }
        @include rpmin(xxl){
            width: 58%;
        }
        @include rpmin(yl){
            width: 48%;
        }

        color: white;
        text-align: center;

        &__head{
            &>*{
                color: inherit;
                @include bold;
                @include titleBs(medium);
                @include uppercase;
            }
        }
        
        &__sub{
            margin-top: 15px;
            &>*{
                color: inherit;
                @include bold;
                @include titleBs(big);
                @include uppercase;
            }
        }

        &__desc{
            margin-top: 36px;
            &>*{
                @include bold;
                @include titleBs(small);
            }
        }
        &__btn{
            margin-top: 56px;
        }
    }
}