.block-table_3{
    .table_img{
        &-content{
            text-align: center;
            padding: 10px 0;
            @include sizeBs(bodyText1);
            color:  white;
            background-color: map-get($color,fill2  );
                
        }
        &-image{
           img{
               display: block;
           }
        }
    }
    .table_3{
        border-top: solid 1px rgba($color: map-get($color, bor2 ), $alpha: .4);
        border-left: solid 1px rgba($color: map-get($color, bor2 ), $alpha: .4);
        // margin-bottom: 40px;
        
        &-row{
            display: flex;
            height: 100%;
            border-bottom: solid 1px rgba($color: map-get($color, bor2 ), $alpha: .4);
            background-color: map-get($color,fill5  );
                
            .table-items{
                border-right: solid 1px rgba($color: map-get($color, bor2 ), $alpha: .4);
                height: 100%;
                &>*:not(:last-child){
                    border-bottom: solid 1px rgba($color: map-get($color, bor2 ), $alpha: .4);
                    
                }   
            }
            .items{
                text-align: center;
                padding: 10px 0;
                color: map-get($color,txt1);
            }
            .head{
                padding-left: 10px;
                padding-right: 10px;
                min-width: 120px;
                @include rpmin(lg){
                    padding-left: 60px;
                }

                display: flex;
                align-items: center;
                border-right: solid 1px rgba($color: map-get($color, bor2 ), $alpha: .4);
                &>*{
                    @include bold;
                }
            }
            .infor{
                flex: 1;
                display: flex;
                align-items: center;
            }
        }
    }
}