.option-form {
    // max-width: 420px;

    &__label {
        margin-bottom: 20px;
        @include sizeBs(bodyText1);
        color: map-get($color, tx1);

        span {
            color: map-get($color, hover1);
        }
    }

    &-select {
        position: relative;

        .option {
            &-active {
                cursor: pointer;

                display: flex;
                justify-content: center;
                align-items: center;

                margin-bottom: 20px;
                border: 1px solid #707070;
                padding: 20px 10px 20px 20px;

                @include sizeBs(small);
                color: map-get($color, tx1);

                .span {
                    flex: 1;
                }
                .span, .svg{
                    user-select: none;
                    pointer-events: none;

                    transition: .5s;
                }

                &.clicked ~ .option-drops {
                    // clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
                    height: auto;
                    opacity: 1;
                }

                &.clicked{
                    .svg{
                        transform: rotate(180deg);
                    }
                }
            }

            &-drops {
                position: absolute;
                top: 100%;
                left: 0;
                z-index: 11;
                
                width: 100%;
                max-height: 180px;
                overflow-y: auto;

                @include sizeBs(bodyText1);
                color: map-get($color, tx1);
                background-color: map-get($color, fill5);
                @include shadow(s1);

                &__item{
                    padding: 20px 10px 20px 20px;
                }
                transition: .5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
                // clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
                height: 0;
                opacity: 0;
            }
        }
    }
}

