.input{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    margin-left: -20px;
    margin-right: -20px;
    margin-top: -20px;

    &.ip-3{
        margin-left: -10px;
        margin-right: -10px;
        margin-top: -20px;
        .inputForm{
            width: 100%;

            padding-left: 10px;
            padding-right: 10px;
            padding-top: 20px;

            @include rpmin(md){
                width: 33.33333%;
            }
        }
    }

    .inputForm{
        display: flex;
        flex-direction: column;
        width: 100%;

        padding-left: 20px;
        padding-right: 20px;
        padding-top: 20px;
        
        @include rpmin(md){
            padding-right: 20px;
            width: 50%;
        }

        &.full{
            width: 100%;
            @include rpmin(md){
                width: 100%;
            }
        }

        
        
        &>label{
            margin-bottom: 20px;
            @include sizeBs(bodyText1);
            color: map-get($color,tx1 );

            span{
                color: map-get($color, hover1);
            }
        }

        &>input{
            padding: 15px 25px;
            border-radius: 0;
            border: solid 1px map-get($color,bor2 );
            @include sizeBs(small);
        }

        &>textarea{
            padding: 15px 25px;
            border-radius: 0;
            border: solid 1px map-get($color,bor2 );
            @include sizeBs(small);
        }
    }
}
