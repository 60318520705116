.surcharge{
    &-table{
        &__item{
            &-title{
                margin-bottom: 10px;
                &>*{
                    @include sizeBs(small);
                    color: map-get($color, tx3);
                    @include bold;
                }
            }
            &:not(:first-child){
                margin-top: 24px;
                @include rpmin(md){
                    margin-top: 30px;
                }
            }
        }
    }
}