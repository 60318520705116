// @import url('https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

// font-family: 'Open Sans', sans-serif;
// @import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');
// @font-face {
//   font-family: "sf";
//   src: url("../fonts/SANFRANCISCODISPLAY-REGULAR.OTF") format("truetype");
//   @include regular;
// }
// @import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

// @font-face {
//   font-family: "utm-avo";
//   src: url("../fonts/UTM Avo.ttf") format("truetype");
//   @include regular;
// }
// @font-face {
//   font-family: "utm-avo";
//   src: url("../fonts/UTM AvoBold.ttf") format("truetype");
//   @include bold;
// }
// @font-face {
//   font-family: "utm-avo";
//   src: url("../fonts/UTM AvoItalic.ttf") format("truetype");
//   @include italic;
// }


@font-face {
  font-family: "Helvetica Neue";
  src: url("../fonts/SVN-Helvetica Neue.ttf") format("truetype");
  @include regular;
}
@font-face {
  font-family: "Helvetica Neue";
  src: url("../fonts/SVN-Helvetica Neue Light.ttf") format("truetype");
  @include light;
}
@font-face {
  font-family: "Helvetica Neue";
  src: url("../fonts/SVN-Helvetica Neue Bold.ttf") format("truetype");
  @include bold;
}
@font-face {
  font-family: "Helvetica Neue";
  src: url("../fonts/SVN-Helvetica Neue Bold Italic.ttf") format("truetype");
  @include bold;
  @include italic;
}
@font-face {
  font-family: "Helvetica Neue";
  src: url("../fonts/SVN-Helvetica Neue Heavy.ttf") format("truetype");
  @include extra-bold;
}
