.swiper{
    &-pagination-bullet{
        width: 18px !important;
        height: 18px !important;
    
        border: 1px solid #FFFFFF;
        background: #FFFFFF;
        opacity: 1;
    
        &.swiper-pagination-bullet-active{
            background-color: #E50019;
        }
    }
    
    &-pagination{
        z-index: 3;
    }

}

.swiper-custom{
    --wBtn: 40px;
    --svgBtnHeight: 20px;
    --svgBtnWidth: 10px;
    --spaceBtn: 12px;
    
    @include rpmin(md){
        --wBtn: 60px;
        --svgBtnHeight: 31.92px;
        --svgBtnWidth: 13.433px;
        --spaceBtn: 53px;
    }

    &.space-small{
        --svgBtnHeight: 20px;
        --svgBtnWidth: 10px;
        --wBtn: 40px;
        --spaceBtn: 20px;
    }

    position: relative;
    padding: 0 calc(var(--wBtn) + var(--spaceBtn));

    .swiper-button-custom-next,
    .swiper-button-custom-prev{
        width: var(--wBtn);
        height: var(--wBtn);
        background-color: #1F6BB4;

        svg{
            width: var(--svgBtnWidth);
            height: var(--svgBtnHeight);
        }

        position: absolute;
        top: 50%;
        transform: translate(0%, -50%);
        z-index: 10;

        cursor: pointer;

        display: flex;
        justify-content: center;
        align-items: center;

        user-select: none;

        transition: .16s all linear;
        &.swiper-button-disabled{
            opacity: .6;
        }
    }

    .swiper-button-custom-prev{
        left: 0;
        right: auto;
    }
    .swiper-button-custom-next{
        left: auto;
        right: 0;
    }
}