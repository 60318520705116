#footer{
    background-color: map-get($color,mode2 );
    .copyright{
        background-color: map-get($color,info );
        width: 100%;
        padding: 15px 0;
        @include rp(mm){
            padding: 10px 0;
        }
        &-name{
            color: white;
            text-align: center;
            @include sizeBs(bodyText1);
            @include bold;
        }
    }
    .footer{
        display: flex;
        flex-wrap: wrap;
        margin-top: -40px;
        padding: 28px 0;

        &-block{
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            align-items: center;
            text-align: center;
            @include rpmin(sm){
                text-align: left;
                align-items: initial;
            }
            
            // flex: 0 0 calc((100% - 15% )/4);
            // width: calc((100% - 15% )/4);
            
            flex: 0 0 100%;
            width: 100%;
            padding-right: 0px;
            // margin-bottom: 30px;

            padding-top: 40px;

            @include rpmin(sm){
                flex: 0 0 50%;
                width: 50%;
                padding-right: 20px;

                &:nth-of-type(2n){
                    padding-right: 0;
                }
            }

            @include rpmin(lg){
                padding-right: 30px;
                flex: 0 0 calc(100% /4);
                width: calc(100% /4);

                &:nth-of-type(2n){
                    padding-right: 30px;
                }

                &:nth-of-type(4){
                    padding-right: 0;
                }

                
            }
                
            @include rpmin(xl){
                padding-right: 12px;
                flex: 0 0 calc((100% - 250px )/4);
                width: calc((100% - 250px )/4);

                &:nth-of-type(2n){
                    padding-right: 12px;
                }

                &:nth-of-type(4){
                    padding-right: 12px;
                }
            }
            @include rpmin(xxl){
                padding-right: 30px;
                flex: 0 0 calc((100% - 250px )/4);
                width: calc((100% - 250px )/4);

                &:nth-of-type(2n){
                    padding-right: 30px;
                }

                &:nth-of-type(4){
                    padding-right: 30px;
                }
            }
            @include rpmin(yl){
                padding-right: 60px;
                // margin-bottom: 40px;
            }
            
            &.map-block{
                flex: 0 0 100%;
                width: 100%;
                min-height: 400px;
                padding-right: 0;

                @include rpmin(xl){
                    width: 250px;
                    flex: 0 0 250px; 
                    min-height: initial;
                }
                // padding: unset;
                .footer-block_items{
                    height: 100%;
                    width: 100%;
                }
            }
            &_items{
                display: flex;
                flex-direction: column;
                width: 100%;

                &>*{                    
                    &:not(:first-child){
                        margin-top: 20px;
                    }
                }
                &:not(:first-child){
                    margin-top: 40px;
                }
            }
        }
        .mt-auto{
            margin-top: auto
        }

        &-title{
            @include sizeBs(bodyText3);
            @include bold;
            text-transform: uppercase;
            color:white;
        }
        &-infor{
            // display: flex;
            &>*{
                @include bold;
                color:white;
                transition: .3s ease;  
            }
            a:hover{
                color: map-get($color, mode3 );
            }
            span{
                margin-left: 5px;
                @include rpmin(xxl){
                    margin-left: 5px;
                }
                @include regular;
            }
            
        }

        &-secure{
            @include rpmin(sm){

            }
            img{
                width: auto;
                height: auto;
            }
        }

        &-link{
            display: flex;
            height: 37px;

            justify-content: center;
            @include rpmin(sm){    
                justify-content: initial;
            }

            &__img{
                height: 100%;
                img{
                    object-fit: contain;
                }
            }

            // &__img{
            //     &:not(:first-child){
            //         margin-left: 5px;
            //     }
            //     flex: 1;
            //     height: 100%;
            //     img{
            //         object-fit: contain;
            //     }
            // }
        }
        &-member{
            // display: flex;
            // height: 37px;
            // justify-content: center;
            // @include rpmin(sm){    
            //     justify-content: initial;
            // }

            // width: 205px;
            // max-width: 100%;
            // margin-left: auto;
            // margin-right: auto;
            // @include rpmin(sm){
            //     margin-left: initial;
            //     margin-right: initial;
            // }
            // @include rpmin(lg){
            //     width: 205px;
            // }
            // @include rpmin(xl){
            //     width: 97.4%;
            // }
            // @include rpmin(xxl){
            //     width: 86.5%;
            // }
            // @include rpmin(yl){
            //     width: 65.6%;
            // }

            height: 37px;
            display: flex;
            justify-content: center;
            flex-wrap: wrap;

            @include rpmin(sm){    
                justify-content: initial;
            }

            // width: 205px;
            max-width: 100%;
            margin-left: auto;
            margin-right: auto;
            @include rpmin(sm){
                margin-left: initial;
                margin-right: initial;
            }

            &__img{
                &:not(:first-child){
                    margin-left: 5px;
                }

                // height: 100%;
                img{
                    width: 37px;
                    height: 37px;
                    object-fit: cover;
                }
            }
        }
        &-svg{
            margin-right: 5px;
            svg{
                path{
                    transition: .2s ease;
                }
            }
            &:hover{
                svg{
                    path{
                        fill: map-get($color, mode3 )
                    }
                }
            }
        }
        &-img{
            max-width: 150px;
            margin-right: 5px;
            margin-top: 8px;
            img{
                width: auto;
                object-fit: contain;
            }
        }
        &-select{
            position: relative;
            // min-width: 220px;
            max-width: 100%;
            width: 100%;
            @include rpmin(sm){
                min-width: 220px;
                width: auto;
            }

            &::after{
                content: "";
                position: absolute;
                width: 0;
                height: 0;
                border-left: 6px solid transparent;
                border-right: 6px solid transparent;
                border-top: 12px solid #B50006;
                top: 50%;
                right: 15px;
                transform: translateY(-50%);
                z-index: 1;
                pointer-events: none;
            }
            #footerSelect{
                height: 37px;
                border: none;
                appearance: none;
                padding: 0 15px;
                color: map-get($color, bor2 );
                width: 100%;
            }
        }
    }
}