.box-1 {
    background: map-get($color, fill7);
    box-shadow: 0 3px 6px rgba(0, 0, 0, .16);
    transition: all 0.3s linear;
    position: relative;
    height: 100%;
    padding: 8px;

    &__content {
        padding: 30px 0;
        display: flex;
        flex-direction: column;
        align-items: center;

        text-align: center;
    }

    &__title {
        margin-top: 30px;

        &>* {
            @include bold;
            @include titleBs(small);
            @include uppercase;
            color: map-get($color, tx1);

            transition: all 0.3s linear;
        }
    }

    &__infor {
        margin-top: 24px;

        &>* {
            @include sizeBs(small);
            color: map-get($color, tx1);

            transition: all 0.3s linear;
        }
    }

    &:hover {
        background: map-get($color, fill2);

        .box-1__title {
            &>* {
                color: map-get($color, tx6);
            }
        }

        .box-1__infor {
            &>* {
                color: map-get($color, tx6);
            }
        }
    }
}