.index-4{
    position: relative;
    z-index: 1;

    padding-top: 15%;
    margin-top: -15%;
    &_wrapper{
        margin: auto;
        position: relative;
        z-index: 1;
    }
    // background-image: url("../assets/images/index/61769.png");
    background-repeat: no-repeat;
    background-size: cover;

    // animation: forwards;
    
    &::after{
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        content: "";
        background: rgb(31,107,180);
        background: linear-gradient(140deg, rgba(31,107,180,1) 19%, rgba(255,255,255,1) 100%);
        opacity: .15;
        // z-index: 1;
        pointer-events: none;
    }
    
    &_content{
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        margin-top:map-get($spaceSection, 48) ;
        @include rpmin(xl){
            padding: 0 30px;
            margin-top:map-get($spaceSection, 0) ;
        }
        &-decs{
            margin-top: 10px;
            @include bold;
            @include sizeBs(bodyText2);
            color: map-get($color, txt1 );
            line-height: 1.5;
            // @include p-line-clamp(4)
        }
    }
    &_swiper{
        // position: relative;
        .swiper-button-prev,
        .swiper-button-next{
            width: 50px;
            height: 50px;
            background-color: map-get($color, mode2 );
            @include rp(ts){
                width: 40px;
                height: 40px;
            }
            &::after{
                content: none;
            }
        }
        .swiper-button-prev{
            left: -(map-get($spaceSection, 82));
            
            @include rp(tl){
                top: 30%;
                left: -(map-get($spaceSection, 0));
            }
        }
        .swiper-button-next{
            right:-(map-get($spaceSection, 82));
            
            @include rp(tl){
                top: 30%;
                right: -(map-get($spaceSection, 0));
            }
            svg{
                transform: rotate(180deg);
            }
        }
        // .swiper-slide{
        //     // height: unset;
        // }
        .index-news{
            height: 100%;
        }
    }
    &_btnall{
        width: 100%;
        text-align: center;
    }
    &_partner{
        .partner{
            .swiper{
                padding-bottom: map-get($spaceSection, 82 );
                @include rp(ts){
                    padding-bottom: map-get($spaceSection, 48);
                }
                .swiper-slide{
                    @include shadow(s2);
                    background-color: white;
                    padding: 20px 20px;
                }
            }
        }
    }
}