.noSelect {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.margin-center{
    margin-left: auto;
    margin-right: auto;
}

@for $i from 1 through 10 {
    .p-line-clamp-#{$i} {
        display: -webkit-box;
        -webkit-line-clamp: #{$i};
        -webkit-box-orient: vertical;
        overflow: hidden;
    }
}

.wrapper {
    @include spaceBs(s66, padding-right);
    // padding-right: 66px;
}

.wrapper2 {
    @include spaceBs(s32, padding-right);
    // padding-right: 66px;
}

.block-link {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 1;
}

.title-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    &.left {
        justify-content: flex-start;
    }

    .sub-desc {
        text-align: center;
        margin-top: 18px;
        &>*{
            @include sizeBs(small);
            color: map-get($color, tx1);
        }
    }
}

// .space-82{
//     margin-top: 82px;
// }

@each $spaceNum,
$spaceVal in $spaceSection {
    .space-#{$spaceNum} {
        padding-top: $spaceVal;
    }
}

@each $key,
$size in $mdWidth {
    @media (min-width: $size) {

        @each $spaceNum,
        $spaceVal in $spaceSection {
            .space-#{$key}-#{$spaceNum} {
                padding-top: $spaceVal;
            }
        }
    }
}


// .full-content {
//     .text {
//         @include sizeBs(small);
//     }

//     p {
//         color: map-get($color, tx1);
//         @include sizeBs(small);
//         white-space: pre-wrap;
//         line-height: 1.23;
//     }

//     span {
//         @include sizeBs(small);
//     }

//     img {
//         width: 100%;
//         height: auto;
//     }

//     // p ~ p{
//     //     margin-top: 22px;
//     // }

//     // p ~ img{
//     //     margin-top: 24px;
//     // }
//     // img ~ img{
//     //     margin-top: 24px;
//     // }
//     // img ~ p{
//     //     margin-top: 20px;
//     // }

//     p.note {
//         @include sizeBs(note);
//         text-align: center;
//         width: 90%;
//         @include regular;
//     }



//     img~p.note {
//         margin-top: 8px;
//     }

//     &>* {
//         &:not(:first-child) {
//             margin-top: 24px;
//         }
//     }

//     p.line {
//         margin-top: 4px;
//     }

//     .head {
//         // text-transform: uppercase;
//         color: #1F6BB4;
//         @include bold;
//     }

//     ul {
//         list-style-type: none;

//         li {
//             @include sizeBs(small);
//             padding-left: 20px;
//             position: relative;

//             &::after {
//                 content: "";
//                 position: absolute;
//                 left: 0;
//                 transform: translate(0, -50%);

//                 background-color: #1F6BB4;
//                 border-radius: 50%;

//                 --rLi: 6px;
//                 --tLi: 10px;

//                 top: var(--tLi);
//                 width: var(--rLi);
//                 height: var(--rLi);

//                 @include rpmin(md) {
//                     --tLi: 10px;
//                     --rLi: 6px;
//                 }

//                 @include rpmin(xl) {
//                     --tLi: 11px;
//                     --rLi: 8px;
//                 }
//             }
//         }
//     }
// }

.content-line {
    .text {
        @include sizeBs(small);
    }

    p {
        color: map-get($color, tx1);
        @include sizeBs(small);
        white-space: pre-wrap;
        line-height: 1.23;
    }

    span {
        @include sizeBs(small);
    }

    img {
        width: 100%;
        height: auto;
    }

    // p ~ p{
    //     margin-top: 22px;
    // }

    // p ~ img{
    //     margin-top: 24px;
    // }
    // img ~ img{
    //     margin-top: 24px;
    // }
    // img ~ p{
    //     margin-top: 20px;
    // }

    p.note {
        @include sizeBs(note);
        text-align: center;
        width: 90%;
        @include regular;
    }

    img~p.note {
        margin-top: 8px;
    }

    // &>*{
    //     &:not(:first-child){
    //         margin-top: 24px;
    //     }
    // }

    // p.line{ 
    //     margin-top: 4px;
    // }

    .head {
        // text-transform: uppercase;
        color: #1F6BB4;
        @include bold;
    }

    .small{
        @include sizeBs(small);
    }
    .medium{
        @include sizeBs(medium);
    }
    .big{
        @include titleBs(medium);
    }

    .bold{
        @include bold;
    }

    .table{
        &>*{
            p{
                height: 100%;

                padding: 16px 12px;
                @include uppercase;
                @include sizeBs(small);
                @include bold;
                background-color: #F5F5F5;
                text-align: center;
            }
        }
    }
    
    .table2{
        &>*{
            p{
                height: 100%;

                padding: 40px 12px;
                @include uppercase;
                @include sizeBs(small);
                @include bold;
                background-color: #F5F5F5;
                text-align: center;
            }

            img{
                height: 100%;
            }
        }
    }


    ul {
        list-style-type: none;

        li {
            @include sizeBs(small);
            padding-left: 20px;
            position: relative;

            &::after {
                content: "";
                position: absolute;
                left: 0;
                transform: translate(0, -50%);

                background-color: #1F6BB4;
                border-radius: 50%;

                --rLi: 6px;
                --tLi: 10px;

                top: var(--tLi);
                width: var(--rLi);
                height: var(--rLi);

                @include rpmin(md) {
                    --tLi: 10px;
                    --rLi: 6px;
                }

                @include rpmin(xl) {
                    --tLi: 11px;
                    --rLi: 8px;
                }
            }
        }
    }
}


.social-contact {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    margin-top: -12px;
    margin-left: -8px;
    margin-right: -8px;

    &__item {
        display: block;
        padding-top: 12px;
        padding-left: 8px;
        padding-right: 8px;

        &-link {
            transition: .3s all linear;
            cursor: pointer;
            position: relative;

            svg {
                path {
                    transition: .3s all linear;
                }
            }

            &:hover {
                svg {
                    path {
                        fill: map-get($color, bor2);
                    }
                }
            }
        }
    }
}

.form-title {
    &>* {
        @include bold;
        color: map-get($color, tx1);
        @include sizeBs(big);
    }
}

.check-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    &__item {
        cursor: pointer;

        min-width: 82px;
        padding: 16px 16px;
        text-align: center;

        @include sizeBs(bodyText1);
        background: linear-gradient(to right,#FFFCFC, #EBEBEB);
        border: 1px solid #707070;

        @include bold;
        color: map-get($color, fill2);
    }
}