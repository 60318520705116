.section-popup{
    position: fixed;
    z-index: 9999;
    top: 0;
    left: 0;

    width: 100%;
    height: 100vh;

    transition: .5s ease;
    
    clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);

    &.show{
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
    }
    
    &::after{
        z-index: -2;
        @include pos-full;
        background-color: rgba(#133C63, 0.95);
    }

    .popup-video{
        @extend .container;
        height: 100%;   
        display: flex;
        justify-content: center;
        flex-wrap: wrap;

        &-wrapper{
            position: relative;

            width: 100%;
            height: 100%;

            @include flex-center;
            flex-direction: column;

            iframe{
                width: 100%;
                height: 70%;
                position: relative;
                z-index: 1;
            }

            .popup-video-photo{
                position: absolute;
                top: 50%;
                left: 50%;
                width: 100%;
                height: 70%;
                transform: translate(-50%, -50%);

                z-index: 0;
            }
        }
    }

    .popup-album{
        @extend .container;
        height: 100%;   
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
    
        &-wrapper{
            position: relative;

            @extend .col-12;

            @include rpmin(xl){
                flex: 0 0 auto;
                width: 58.33333%;
            }
            height: 100%;

            @include flex-center;
            flex-direction: column;
        }
    }
    .popup-album-swiper{
        position: relative;
        width: 100%;
        height: 50%;
        // overflow: hidden;
        
        .swiper{
            width: 100%;
            height: 100%;    
        }
    }
    
    .popup-album-thumbs{
        width: 100%;
        max-height: 20%;
        margin-top: 5%;
    }

    .popup-close{
        position: absolute;
        top: 0;
        right: 0;
        width: 25px;
        height: 25px;
        
        transform: translateY(calc(-100% - 20px));
        cursor: pointer;

        .line{
            position: absolute;
            top: 50%;
            left: 50%;
            width: 100%;
            height: 4px;
            border-radius: 4px;

            background-color: map-get($color, bor2);
            transition: .5s ease;

            &:first-child{
                transform: translate(-50%, -50%) rotate(45deg);
            }            
            &:last-child{
                transform: translate(-50%, -50%) rotate(-45deg);
            }            
        }

        &:hover{
            .line{
                &:first-child{
                    transform: translate(-50%, -50%) rotate(-45deg);
                }            
                &:last-child{
                    transform: translate(-50%, -50%) rotate(45deg);
                }
            }
        }
    }
}