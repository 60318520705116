
.block-table_1{
   
    .table_1{
        display: flex;
        flex-wrap: wrap;
        border-top: solid 1px rgba($color: map-get($color, bor2 ), $alpha: .4);
        border-left: solid 1px rgba($color: map-get($color, bor2 ), $alpha: .4);
        border-right: solid 1px rgba($color: map-get($color, bor2 ), $alpha: .4);
        &.scroll{
            @include rp(ml){
                overflow: scroll;
                flex-wrap: nowrap;
            } 
            
        }
        &.horizontal{
            @include rp(ml){
                flex-direction: column;
                overflow: scroll;
            }
            .table_1-col{
                @include rp(ml){
                    flex-direction: row;
                }
                &:not(:last-child){
                    @include rp(ml){
                        border-right: unset;
                        border-bottom: solid 1px rgba($color: map-get($color, bor2 ), $alpha: .4);
                    }
                }
            }
            .table-items{
                // padding: 0 10px;
                @include rp(ml){
                    min-height: 80px;
                    min-width: 100px;
                }
                
                &:not(:last-child){
                    @include rp(ml){
                        border-bottom: unset;
                        border-right: solid 1px rgba($color: map-get($color, bor2 ), $alpha: .4);
                    }
                }
            }
        } 
        &-col{
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            flex: 1;
            
            border-bottom: solid 1px rgba($color: map-get($color, bor2 ), $alpha: .4);
            &:not(:last-child){
                border-right: solid 1px rgba($color: map-get($color, bor2 ), $alpha: .4);
            }
            &.flex-0-5{
                flex: 0.5;
            }
            &.flex-1-5{
                flex: 1.5;
            }
            &.flex-2{
                flex: 2;
            }
            &.flex-2-5{
                flex: 2.5;
            }
            &.flex-3{
                flex: 3;
            }
            &.flex-3-5{
                flex: 3.5;
            }
            &.bold{
                .table-items{

                    @include bold;
                }
            }
            &.bg-white{
                .table-items{

                    background-color: white;
                    color: map-get($color,txt1);
                }
            }
        }
        .table-items{
            min-width: 60px;
            width: 100%;
            background-color: map-get($color,fill5  );
            min-height: 60px;
            max-height: 60px;
            @include rp(ml){
                min-width: 120px;
            }
            @include rp(mm){
                min-width: 70px;
            }
            // padding: 12px 8px ;
            display: flex;
            text-align: center;
            align-items: center;
            justify-content: center;
            color: map-get($color,txt1);
            &.bg-blue{
                background-color: map-get($color,fill2  );
                color:white;
                min-height: 80px;
                @include bold;
            }
            &.bg-light{
                min-height: 80px;
                background-color: #D8E5F2;
                color: map-get($color,txt1);
                @include bold;
            }
            &.bg-none{
                color: map-get($color,txt1);
                @include bold;
                text-transform: uppercase;
            }
            &.bold{
                @include bold;
            }
            
            &:not(:last-child){
                border-bottom: solid 1px rgba($color: map-get($color, bor2 ), $alpha: .4);
                
            }
        }
    }
    
}