.block-contact {
    width: 100%;

    &__img {
        width: 100%;
        padding-bottom: 80%;
        position: relative;

        .contact-img {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
        }
    }

    &__decs {
        flex: 1;

        &-title {
            padding: 16px 0;

            @include rpmin(lg) {
                padding: 25px 0;
            }

            text-align: center;
            @include sizeBs(big);
            color: map-get($color, txt1);
        }

        &-infor {

            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;

            padding: 12px 4px;

            @include rpmin(lg) {
                padding: 20px 4px;
            }

            background-color:#F7F6F6;
            @include sizeBs(bodyText1);
            color: map-get($color, txt1);
            text-align: center;

            &>*:not(:last-child) {
                margin-bottom: 5px;
            }
        }
    }
}