.table-detail{
    margin-top: 32px;
    @include rpmin(md){
        margin-top: 46px;
    }

    &__head{
        &>*{
            @include bold;
            @include sizeBs(small);
            color: map-get($color, tx1);
        }
    }

    &__img{
        margin-top: 18px;
        @include rpmin(md){
            margin-top: 16px;
        }
        img{
            width: auto;
            height: auto;
            max-width: 100%;
            max-height: 220px;
        }
    }
    &__system{
        margin-top: 32px;
        @include rpmin(md){
            margin-top: 24px;
        }

        .system{
            border: 1px solid map-get($color, bor2);
            border-collapse: collapse;
            tr{
                &>*{
                    padding: 10px 20px;
                    text-align: center;
                    // min-width: 276px;
                    border-collapse: collapse;
                    border: 1px solid map-get($color, bor2);

                    @include sizeBs(small);
                    color: map-get($color, tx1);
                }
                th{
                    @include bold;
                    &:not(:first-child){
                        display: none;
                        
                        @include rpmin(sm){
                            display: table-cell;
                        }
                    }
                }
                td{
                    &:first-child{
                        @include bold;
                    }
                }


                display: flex;
                flex-direction: column;

                @include rpmin(sm){
                    display: table-row;
                    flex-direction: initial;
                }
            }

        }
    }

    &__desc{
        margin-top: 20px;
        @include rpmin(md){
            margin-top: 16px;
        }
        &>*{
            @include regular;
            @include sizeBs(small);
            color: map-get($color, tx1);
        }
    }
}