.index-1{
    background-image: url(../assets/images/index/welcome.png);
    background-size: cover;
    padding-bottom: 64px;
    @include rpmin(md){
        padding-bottom: 110px;
    }

    // .col-12{
    //     margin: auto;
    // }

    .container{
        overflow: initial;
    }

    &__wrapper{
        margin: 0 auto;
    }
    
    .wrapper-topImg{
        // height: 100%;
        width: 100%;
        position: relative;
        padding-top: 62.5%;

        img{
            position: absolute;
            top: 0;
            left: 0;
        }

        // min-height: 400px;

    }

    .wrapper-topContent{   
        margin-top: 18px;
        padding: 0px 8px;
        @include rpmin(lg){
            padding-left: 40px;
        }
        @include rpmin(xxl){
            padding-left: 80px;
        }
        // margin: auto;
        &__infor{
            margin-top: 18px;
            @include rpmin(xxl){
                margin-top: 24px;
            }
            &>*{
                // @include bold;
                @include sizeBs(big);
                color: map-get($color , tx1 );
            }
        }
        &__btn{
            margin-top: 25px;
            @include rpmin(lg){
                margin-top: 30px;
            }
            @include rpmin(xxl){
                margin-top: 40px;
            }
        }
    }
    .wrapper-botContent{
        display: flex;
        flex-direction: column;
        align-items: center;
        // animation: fixedHeader 1.5s ease forwards;
        
        padding: 0px 5px;
        
        margin-top: 12px;
        
        &.right-border{
            @include rpmin(xxl){
                border-right: 2px solid map-get($color ,bor1 );
            }
        }
        // @include rpmin(sm){
        // }
        @include rpmin(xxl){
            border-right: 2px solid map-get($color ,bor1 );
        }
        &.non-border{
            border-right: unset;
        }
        &__img{
            height: 110px;
            display: flex;
            align-items: center;
        }
        &__title{
            margin-top: 32px;
            text-align: center;
            &>*{
                @include bold;
                @include titleBs(small);
                @include rpmin(sm){
                    @include titleBs(medium);
                }

                color: map-get($color , tx5 );
            }
        }
    }
}