.search{
    &-wrapper{ 
        .total>*{
            color: map-get($color, bor2);
            @include sizeBs(bodyText2);
        }
        .content{
            &>*{
                margin-top: 32px;
            }
            &-item{
                a{
                    @include bold;
                    color: map-get($color, main);
                    @include titleBs(small);
                    transition: .3s all;
                }
                p{
                    margin-top: 8px;
                }

                &:hover{
                    a{
                        color: map-get($color, mode3);
                    }
                }
            }
        }
    }
    
}