////////////////////////////////////////////////
/////////////////////////MEDIA ///////////////////

@mixin rp($canvas) {
    @media (max-width: map-get($media, #{$canvas})){
        @content;
    }
}

@mixin rpmin($canvas) {
    @media (min-width: map-get($mdWidth, #{$canvas})){
        @content;
    }
}
  
/////////////////////////MEDIA ///////////////////
//////////////////////////////////////////////////


@mixin pos-full{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

@mixin flex-center{
    display: flex;
    justify-content: center;
    align-items: center;
}
@mixin pos-center{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
