.index-2{
    &__wrapper{
        padding-bottom: 64px;
        @include rpmin(md){
            padding-bottom: 110px;
        }
    }
    .wrapper{
        &-title{
            display: flex;
            align-items: center;
            flex-direction: column;
            &__infor{
                &>*{
                    text-align: center;
                    @include bold;
                    @include sizeBs(bodyText2);
                    color: map-get($color , tx1 );
                    line-height: 1.4;
                }
            }
        }
        &-content{
            max-width: 1920px;
            margin: auto;
            .swiper-slide:nth-child(even){
                .wrapper-post {
                    flex-direction: column-reverse;
                }
            }
            .wrapper-post{
                display: flex;
                width: 100%;
                flex-direction: column;
                
                &__imgService{
                    width: 100%;
                    position: relative;
                    flex: 1;
                    padding-bottom: 118%;
                    @include rpmin(sm){
                        padding-bottom: 100%;
                    }

                    img {
                        flex: 1;
                        position: absolute;
                        top: 0;
                        left: 0;
                        bottom: 0;
                        right: 0;
                    }
                }
                &__detailService{
                    position: relative;
                    width: 100%;
                    padding-bottom: 118%;
                    @include rpmin(sm){
                        padding-bottom: 100%;
                    }
                    flex: 1;
                    .wrapper-post_box{
                        position: absolute;
                        top: 0;
                        left: 0;
                        bottom: 0;
                        right: 0;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                    }
                    // flex: 0 0 50%;
                    background: map-get($color , x );
                   
                    .detailService{
                        &-img{
                            width: 35%;
                            max-width: 150px;
                            img{
                                object-fit: contain;
                            }
                        }
                        &-decs{
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            align-items: center;
                            padding: 0 2vw ;
                            
                        }
                        &-title{
                            
                            &>*{
                                text-align: center;
                                @include bold;
                                @include titleBs(small);
                                color: map-get($color , tx1 );
                            }
                        }
                        &-infor{
                            &>*{
                                @include p-line-clamp(4);
                                @include rp(tm){
                                    @include p-line-clamp(2);

                                }
                                @include sizeBs(small);
                                text-align: center;
                                color: map-get($color , tx1 );
                            }
                        }
                    }
                    &-btn{
                        margin-top: 24px;
                    }
                }
            }
        }
        &-btn{
            display: flex;
            justify-content: center;
        }
    }
}