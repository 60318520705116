.block-address{
    &__header{
        position: relative;
        padding-bottom: 15px;
        margin-bottom: 15px;
        &::after{
            position: absolute;
            bottom: 0;
            left: 0;
            width: 120px;
            content: "";
            height: 1px;
            background-color:map-get($color,bor1);
        }
        &-office{
            @include sizeBs(big);
            color: map-get($color, bor2 );
            margin-bottom: 10px;

            display: flex;
            align-items: center;

            span{
                margin-left: 12px;
            }
        }
        &-name{
            @include sizeBs(big);
            color: map-get($color, txt1 );
        }
    }
    &__bottom{
        &>*:not(:last-child){
            margin-bottom: 15px;
            color: map-get($color, txt1);
            @include sizeBs(small);
        }
        &-items{
            span{
                margin-left: 10px;
            }
        }
    }
}