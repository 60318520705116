.post-2 {
    &__wrapper{
        display: flex;
    }
    &__img{
        width: 15%;
        min-width: 150px;
        min-height: 141px;
        
        @include rpmin(sm){
            width: 20%;
            min-width: 200px;
        }
    }
    &__content{
        padding: 4px 0px 4px 12px;
        @include rpmin(sm){
            padding: 12px 0px 12px 25px;
        }
        flex: 1;

        &--title{
            // min-height: 80px;
            
            &>*{
                @include uppercase;
                @include  titleBs(medium);
                @include bold;
                
                color: map-get($color, fill2);
               
            }
        }
        &--download{
            margin-top: 24px;
            @include rpmin(md){
                margin-top: 35px;
            }
            &>*{
                @include  sizeBs(big);
                @include bold;
                color: map-get($color, tx1);
                
            }
        }
    }
    &:hover{
        .post-2__content--download {
            &>*{
                color: map-get($color, fill4);
                
            }
        }
    }
}