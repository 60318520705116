.title-dgs {
    &__wrapper{
        display: flex;
        align-items: center;
    }
    &__content{
        // padding: 0px 18px;
        &>*{
            @include  titleBs(medium);
            @include bold;
            @include uppercase;
            color: map-get($color, tx3);
            text-align: center;
        }
    }
    &.bg{
        .title-dgs__wrapper{
            padding: 15px 0;
            justify-content: center;
            width: 100%;
            background: #1F6BB4;
            
        }
        .title-dgs__content {
            &>*{
                color: white;
            }
        }
        svg{
            path{
                fill: white;
            }
        }
    }
}

.title-dsg {
    &__wrapper{
        display: flex;
        align-items: center;
    }
    &__content{
        // padding: 0px 18px;
        &>*{
            @include  titleBs(medium);
            @include bold;
            @include uppercase;
            color: map-get($color, tx3);
            text-align: center;
        }
    }
    &.bg{
        .title-dsg__wrapper{
            padding: 15px 0;
            justify-content: center;
            width: 100%;
            background: #1F6BB4;
            
        }
        .title-dsg__content {
            &>*{
                color: white;
            }
        }
        svg{
            path{
                fill: white;
            }
        }
    }
}