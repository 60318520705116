.video{
    &-wrapper{
        &>*:first-child{
            @extend .col-12;
            .box-2{
                &__img{
                    padding-top: 56.25%;
                }
                &__video{
                    padding-top: 56.25%;
                }
            }
        }
        &>*:not(:first-child){
            @extend .col-6;
        }
    }

}

.search,
.video,
.image{
    &-more{
        text-align: center;
        @include rpmin(xl){
            text-align: left;
        }
    }
}
