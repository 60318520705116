#breadcrumb {
    width: 100%;
}

.breadcrumb {
    background-color: map-get($color, fill5);
    width: 100%;
    &-list {
        padding-top: 15px;
        padding-bottom: 18px;
        
        display: flex;
        align-items: center;
        list-style-type: none;

        overflow: auto;
    }
    &-item {
        display: flex;
        align-items: center;
        line-height: 1.4;
        white-space: nowrap;

        svg {
            path {
                stroke: map-get($color, fill4);
            }
        }
        & > * {
            // font-size: 19px;
            @include sizeBs(bodyText3);
            color: #171717;
            margin-right: 12px;
            @include bold;
        }
        &.active {
            .breadcrumb-item__link {
                // color: #2b2b2b;
                color: map-get($color, hover1);
            }
        }
        // &__link {

        // }
    }
}
