.box-2{
    display: flex;
    flex-direction: column;

    cursor: pointer;
    &:hover{
        .box-2__img{
            &::after{
                height: 100%;
            }
        }
    }

    &__img{
        width: 100%;
        height: 0;
        position: relative;
        padding-top: 100%;

        img{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }

        &::after{
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 0%;
            z-index: 1;
            background-color: map-get($color, fill2);
            opacity: 0.5;

            transition: 0.5s ease;
            transform-origin: top;
        }
    }

    &__album{
        padding: 16px 8px;
        
        @include rpmin(md){
            padding: 20px 12px;
        }

        width: 100%;
        text-align: center;

        &>*{
            @include titleBs(small);
            @include bold;
            color: map-get($color, tx1);
            transition: .5s ease;
        }
    }

    &__video{
        width: 100%;
        height: 0;
        position: relative;
        padding-top: 100%;

        img{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }

        &--play{
            position: absolute;
            top: 50%;
            left: 50%;
            z-index: 1;

            transition: 0.5s ease;
            transform-origin: center;

            transform: translate(-50%, -50%) scale(1);
            opacity: 1;

            @include rpmin(lg){
                transform: translate(-50%, -50%) scale(0);
                opacity: 0.5;
            }

            svg{
                width: 55px;
                height: 46px;
                
                @include rpmin(sm){
                    width: 90px;
                    height: 76px;
                }

                @include rpmin(lg){
                    width: 130px;
                    height: 110px;
                }
            }
        }
    }

    &[data-box="video"]{
        &:hover{
            .box-2__video--play{
                transform: translate(-50%, -50%) scale(1);
                opacity: 1;
            }

            .box-2__album{
                &>*{
                    color: map-get($color, hover1);
                }
            }
        }
    }
}
