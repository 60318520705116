.index-3{
    position: relative;
    z-index: 2;
    
    .traingle{
        position: absolute;
        z-index: 1;
        top: 97%;
        left: 50%;
        transform: translate(-50%, -50%) rotate(300deg);
    }

    .plane{
        position: absolute;
        z-index: 2;
        // bottom: 2%;
        // left: 45%;
        transform: translate(-50%, 50%);
        @extend .container;

        &--animation{
            width: 100%;
            height: 100%;
        }

        img{
            width: auto;
            height: auto;
            max-height: 100%;
            object-fit: contain;
            object-position: left;
        }

        display: none;
        @include rpmin(sm){
            display: block;
        }

        height: calc(20vw + 56px);
        bottom: 8%;
        left: 18%;

        @include rpmin(sm){
            bottom: 4%;
            left: 10%;
        }
        @include rpmin(md){
            height: calc(18vw + 56px);
            bottom: 8%;
            left: 18%;
        }
        @include rpmin(lg){
            height: calc(15vw + 56px);
            bottom: 7%;
            left: 30%;
        }
        @include rpmin(xl){
            height: calc(180px + 120px);
            bottom: 2%;
            left: 45%;
        }
    }
    
    // background: linear-gradient(140deg, rgba(31,107,180,1) 19%, rgba(255,255,255,1) 100%);
    &--layer-1{
        background: #D1D1D1;
        clip-path: ellipse(120% 99% at 50% 0%);
        
        @include rpmin(md){
            clip-path: ellipse(100% 99% at 50% 0%);
        }
        @include rpmin(lg){
            clip-path: ellipse(87% 99% at 50% 0%);
        }
    }
    &--layer-2{
        // background-image: url("../assets/images/index/i3.png");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        
        z-index: 1;
        position: relative;
        margin: auto;

        padding-top: 78px;
        @include rpmin(md){
            padding-top: 56px;
        }

        padding-bottom: 20%;
        clip-path: ellipse(120% 78% at 50% 18%);
        
        @include rpmin(md){
            padding-bottom: 18%;
            clip-path: ellipse(100% 78% at 50% 18%);
        }
        @include rpmin(lg){
            padding-bottom: 15%;
            clip-path: ellipse(87% 78% at 50% 19%);
        }
        @include rpmin(xl){
            padding-bottom: 180px;
        }
    }

    .overlay-img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
    }

    // &_wrapper{
    //     display: flex;
    //     flex-direction: column;
    //     justify-content: center;
    //     align-items: center;
    //     margin: auto;

    //     &-sub{
    //         margin-top: 20px;
    //         text-align: center;

    //         color: map-get($color, tx1);
    //         @include titleBs(medium);
    //         @include bold;
    //         @include uppercase;
    //     }

    //     &-form{
    //         margin-top: 52px;
    //         @include rpmin(md){
    //             margin-top: 36px;
    //         }
    //         .form-input{
    //             input,textarea{
    //                 border: none;
    //                 padding: 10px;
                    
    //                 width: 100%;
    //                 min-height: 60px;

    //                 color: rgba(map-get($color, tx1), .75);
    //                 background-color: white;
                    
    //                 @include shadow(s2);
    //                 @include size(bodyText1);
    //             }
    //         }
    //         &>*:not(:last-child){
    //             margin-bottom: 18px;

    //             @include rpmin(md){
    //                 margin-bottom: 38px;
    //             }
    //         }
    //     }
    // }

    &_wrapper{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: auto;
        &-decs{
            @include titleBs(medium);
            color: map-get($color, tx1 );
            text-transform: uppercase;
            text-align: center;
            padding-left: 30px;
            padding-right: 30px;
        }
        &-form{
            margin-top: 52px;
            @include rpmin(md){
                margin-top: 36px;
            }

            .form-input{
                input,textarea{
                    width: 100%;
                    min-height: 60px;
                    border: none;
                    padding: 10px;
                    resize: none;

                    @include shadow(s2);
                    @include sizeBs(bodyText1);
                    background-color: white;
                    color: map-get($color, tx1);
                    
                    &::placeholder{
                        color: rgba(map-get($color, tx1), .75);
                    }
                }
                input:focus::-webkit-input-placeholder,
                textarea:focus::-webkit-input-placeholder { color:transparent; }
                input:focus:-moz-placeholder,
                textarea:focus:-moz-placeholder { color:transparent; } /* FF 4-18 */
                input:focus::-moz-placeholder,
                textarea:focus::-moz-placeholder { color:transparent; } /* FF 19+ */
                input:focus:-ms-input-placeholder,
                textarea:focus:-ms-input-placeholder { color:transparent; } /* IE 10+ */
            }
            &>*:not(:last-child){
                margin-bottom:map-get($spaceSection, 20 ) ;
                @include rpmin(md){
                    margin-bottom:map-get($spaceSection, 38 ) ;
                }
            }
        }
    }
    

}