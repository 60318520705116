.container-1{
    &-table{
        &__item{
            &:not(:first-child){
                margin-top: 32px;
                
                @include rpmin(md){
                    margin-top: 40px;
                }
            }
        }
    }
}