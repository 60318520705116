.inputSubmit{
    width: 50%;
    padding-right: 20px;
    &.btn-full{
        button{
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
        }
    }
    &.full-center{
        width: 100%;
        padding-right: 0px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}