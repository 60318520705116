.si-1{
    .button-choose{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        color: white;
        background-color: #1F6BB4;
        border: 1px solid #1F6BB4;

        padding: 22px 8px;
        @include regular;
        // @include sizeBs(bodyText2);
        font-size: 18px;
        @include rpmin(sm){
            font-size: 2vw;
        }
        // @include rpmin(md){
        //     font-size: 2vw;
        // }

        @include rpmin(xl){
            font-size: 1.2vw;
        }
        @include rpmin(xxl){
            font-size: 2rem;
        }

        cursor: pointer;
        transition: .3s all linear;

        user-select: none;
        white-space: nowrap;

        &.active,
        &:hover,
        &:focus{
            background-color: white;
            color: #171717;
        }
    }
    &__section{
        display: none;
        &.active{
            display: block;
        }
        &-form{
            .block-form{
                &:not(:first-child){
                    margin-top: 40px;
                }
            }
        }
    }
}
