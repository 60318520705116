.nav-bar {
    max-width: 444px;
    margin: 0 auto;

    @include rpmin(xl) {
        max-width: 534px;
    }

    &__wrapper {
        width: 100%;
    }

    &__img {
        margin-bottom: 50px;
    }

    &__content {
        &--title {
            display: flex;
            justify-content: center;
            padding-bottom: 24px;
            border-bottom: 1px solid map-get($color, bor2);
            &>* {
                @include bold;
                @include title(medium);
                @include uppercase;
                color: map-get($color, tx4);
            }

        }

        &--list {
            list-style-type: none;
        }

        &--item {
            position: relative;
            transition: .5s linear;
            border-bottom: 1px solid map-get($color, bor2);
            
            // &::after {
            //     position: absolute;
            //     top: 0;
            //     left: 0;
            //     width: 100%;
            //     height: 1px;
            //     content: "";
            //     background: map-get($color, bor2);
            // }

            .nav-link {
                display: block;

                @include bold;
                @include sizeBs(bodyText2);
                @include uppercase;
                color: map-get($color, tx1);

                padding: 18px 0px;

                transition: .3s linear;
            }

            &:hover,
            &.active {
                .nav-link {
                    color: map-get($color, hover1);
                }

                .flex-list {
                    &>* {
                        color: map-get($color, hover1);
                    }
                }
            }


            .flex-list {
                display: flex;
                position: relative;
                // padding-bottom: 24px;
                // border-bottom: 1px solid map-get($color, bor2);

                cursor: pointer;

                // &::before {
                //     position: absolute;
                //     bottom: 0;
                //     left: 0;
                //     width: 100%;
                //     height: 1px;
                //     content: "";
                //     background: map-get($color, bor2);
                // }

                &>* {
                    @include bold;
                    @include sizeBs(bodyText2);
                    @include uppercase;
                    color: map-get($color, tx1);

                    transition: .3s linear;
                    display: block;

                    padding: 18px 0px;

                    transition: .3s linear;
                }

                &:hover {
                    .btnDropdown {
                        color: map-get($color, tx4);
                    }
                }

                .icons {
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    margin-left: 4px;
                    transition: 0.5s ease;
                    transform-origin: center;
                }

                &.show{
                    .icons{
                        transform: rotate(180deg);
                    }
                }

                &.show ~ .dropdownList{
                    opacity: 1;
                    height: auto;
                    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
                    visibility: initial;
                }
            }
            
            .dropdownList {
                list-style-type: none;
                opacity: 0;
                visibility: hidden;
                transition: all 0.3s linear;
                
                height: 0;
                clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);

                // border-bottom: 1px solid map-get($color, bor2);
            }

            .dropdownItem {
                &>* { 
                    display: block;
                    padding: 12px 0px;

                    @include bold;
                    @include sizeBs(bodyText2);
                    @include uppercase;
                    color: map-get($color, tx1);

                    transition: .3s ease;

                    // border-bottom: 1px solid map-get($color, bor2);
                }

                &:hover,
                &.active {
                    &>*{
                        color: map-get($color, hover1);
                    }
                }

            }
        }
    }
}