.swiper{
    width: 100%;
}
// .nav-custom{
//     .nav{
//         margin-top: 40px;
//         display: flex;
//         align-items: center;
//         // width: 100%;
//         padding: 0 10px;
//         position: absolute;
//         right: 0;
//         .swiper-button-next,.swiper-button-prev{
//             position: unset;
//             // top: 100%;
//             width: auto;
//             left: unset;
//             right: unset;
//             margin-right: 20px;
//             // margin-left: auto;
//             &::after{
//                 display: none;
//             }
//         }
//     }
    
// }
// .swiper-pagination{
//     margin-left: -15px;
//     padding: 5px 0;
//    .swiper-pagination-bullet{
//        background: map-get($color, black-3 );
//        opacity: 1;
//        margin: 0 15px !important;
//        height: 10px;
//        width: 10px;
//        transform: unset;
//        &.swiper-pagination-bullet-active{
//         transform: scale(1.6);
//         background: map-get($color, main );
//        }
//    }
// }