.block-dictionary{
    .dictionary{
        &-alpha{
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            margin: 0 -3px;
            margin-bottom: 40px;
            &_items{
                min-width: 50px;
                margin: 0 3px;
                padding: 5px 15px;
                text-align: center;
                background-color: map-get($color, mode2);
                cursor: pointer;
                color: white;
                transition: .2s ease;
                border-radius: 10px;
                text-transform: uppercase;
                @include bold;
                @include sizeBs(bodyText1);
                margin-bottom: 5px;
                &:hover,
                &.active{
                    background-color: map-get($color, hover1);
                }
            }
        }
        &-words{
            display: none;
            overflow: visible;
            &.active{
                display: block;
                animation: fadeUp 1 500ms ;
                @keyframes fadeUp {
                    0%{
                        transform: translateY(100px);
                    }
                    100%{
                        transform: translateY(0px);
                    }
                }
            }
            &_items:not(:first-child){
                margin-top: 60px;

            }
            &_items>*:not(:first-child){
                margin-top: 5px;
            }
            &_items>*{
                color: map-get($color, txt1 );
                
            }
        }
    }
}