.swiper-pagination-bullet {
  width: 18px !important;
  height: 18px !important;
  border: 1px solid #FFFFFF;
  background: #FFFFFF;
  opacity: 1; }
  .swiper-pagination-bullet.swiper-pagination-bullet-active {
    background-color: #E50019; }

.swiper-pagination {
  z-index: 3; }

.swiper-custom {
  --wBtn: 40px;
  --svgBtnHeight: 20px;
  --svgBtnWidth: 10px;
  --spaceBtn: 12px;
  position: relative;
  padding: 0 calc(var(--wBtn) + var(--spaceBtn)); }
  @media (min-width: 768px) {
    .swiper-custom {
      --wBtn: 60px;
      --svgBtnHeight: 31.92px;
      --svgBtnWidth: 13.433px;
      --spaceBtn: 53px; } }
  .swiper-custom.space-small {
    --svgBtnHeight: 20px;
    --svgBtnWidth: 10px;
    --wBtn: 40px;
    --spaceBtn: 20px; }
  .swiper-custom .swiper-button-custom-next,
  .swiper-custom .swiper-button-custom-prev {
    width: var(--wBtn);
    height: var(--wBtn);
    background-color: #1F6BB4;
    position: absolute;
    top: 50%;
    transform: translate(0%, -50%);
    z-index: 10;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
    transition: .16s all linear; }
    .swiper-custom .swiper-button-custom-next svg,
    .swiper-custom .swiper-button-custom-prev svg {
      width: var(--svgBtnWidth);
      height: var(--svgBtnHeight); }
    .swiper-custom .swiper-button-custom-next.swiper-button-disabled,
    .swiper-custom .swiper-button-custom-prev.swiper-button-disabled {
      opacity: .6; }
  .swiper-custom .swiper-button-custom-prev {
    left: 0;
    right: auto; }
  .swiper-custom .swiper-button-custom-next {
    left: auto;
    right: 0; }

.box-pagination {
  margin-top: 24px;
  list-style-type: none;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center; }
  @media (min-width: 992px) {
    .box-pagination {
      margin-top: 32px; } }
  @media (min-width: 1400px) {
    .box-pagination {
      margin-top: 40px; } }
  .box-pagination > * {
    width: 40px;
    height: 40px;
    background-color: #1F6BB4;
    color: white;
    font-size: 1.8rem;
    font-weight: 700;
    white-space: nowrap;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center; }
    @media (min-width: 768px) {
      .box-pagination > * {
        font-size: 1.9rem; } }
    @media (min-width: 1400px) {
      .box-pagination > * {
        font-size: 2rem; } }
    .box-pagination > *.active {
      background-color: #707070;
      cursor: initial; }
    .box-pagination > *:not(:first-child) {
      margin-left: 8px; }
    .box-pagination > *.btn-hidden {
      opacity: 0.6;
      cursor: initial; }
  .box-pagination .next {
    transform: rotate(180deg); }

/*!
* source: https://getbootstrap.com/
* Bootstrap Grid v5.0.2 (https://getbootstrap.com/)
* Copyright 2011-2021 The Bootstrap Authors
* Copyright 2011-2021 Twitter, Inc.
* Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
*/
.container, .section-popup .popup-video, .section-popup .popup-album, .index-3 .plane {
  --bs-gutter-x: -1.5rem;
  width: 96%;
  margin-right: auto;
  margin-left: auto;
  overflow: hidden; }

@media (min-width: 576px) {
  .container, .section-popup .popup-video, .section-popup .popup-album, .index-3 .plane {
    --bs-gutter-x: 0;
    max-width: 540px; } }

@media (min-width: 768px) {
  .container, .section-popup .popup-video, .section-popup .popup-album, .index-3 .plane {
    --bs-gutter-x: 0;
    max-width: 720px; } }

@media (min-width: 992px) {
  .container, .section-popup .popup-video, .section-popup .popup-album, .index-3 .plane {
    --bs-gutter-x: 0;
    max-width: 960px; } }

@media (min-width: 1200px) {
  .container, .section-popup .popup-video, .section-popup .popup-album, .index-3 .plane {
    --bs-gutter-x: 0;
    max-width: 1140px; } }

@media (min-width: 1400px) {
  .container, .section-popup .popup-video, .section-popup .popup-album, .index-3 .plane {
    --bs-gutter-x: 0;
    max-width: 1320px; } }

@media (min-width: 1680px) {
  .container, .section-popup .popup-video, .section-popup .popup-album, .index-3 .plane {
    --bs-gutter-x: 0;
    max-width: 1640px; } }

.col {
  flex: 1 0 0%; }

.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(var(--bs-gutter-y) * -1);
  margin-right: calc(var(--bs-gutter-x) * -.5);
  margin-left: calc(var(--bs-gutter-x) * -.5);
  width: calc(100% + (var(--bs-gutter-x) * 0.5 * 2)); }
  .row > * {
    box-sizing: border-box;
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
    padding-right: calc(var(--bs-gutter-x) * .5);
    padding-left: calc(var(--bs-gutter-x) * .5);
    margin-top: var(--bs-gutter-y); }
  .row-cols-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%; }
  .row-cols-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%; }

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%; }
  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 91.66667%; }
  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 83.33333%; }
  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 75%; }
  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 66.66667%; }
  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 58.33333%; }
  .row-cols-sm-7 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-sm-8 > * {
    flex: 0 0 auto;
    width: 41.66667%; }
  .row-cols-sm-9 > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-sm-10 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-sm-11 > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .row-cols-sm-12 > * {
    flex: 0 0 auto;
    width: 8.33333%; } }

@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%; }
  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 91.66667%; }
  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 83.33333%; }
  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 75%; }
  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 66.66667%; }
  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 58.33333%; }
  .row-cols-md-7 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-md-8 > * {
    flex: 0 0 auto;
    width: 41.66667%; }
  .row-cols-md-9 > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-md-10 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-md-11 > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .row-cols-md-12 > * {
    flex: 0 0 auto;
    width: 8.33333%; } }

@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%; }
  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 91.66667%; }
  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 83.33333%; }
  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 75%; }
  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 66.66667%; }
  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 58.33333%; }
  .row-cols-lg-7 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-lg-8 > * {
    flex: 0 0 auto;
    width: 41.66667%; }
  .row-cols-lg-9 > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-lg-10 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-lg-11 > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .row-cols-lg-12 > * {
    flex: 0 0 auto;
    width: 8.33333%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%; }
  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 91.66667%; }
  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 83.33333%; }
  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 75%; }
  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 66.66667%; }
  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 58.33333%; }
  .row-cols-xl-7 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-xl-8 > * {
    flex: 0 0 auto;
    width: 41.66667%; }
  .row-cols-xl-9 > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-xl-10 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-xl-11 > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .row-cols-xl-12 > * {
    flex: 0 0 auto;
    width: 8.33333%; } }

@media (min-width: 1400px) {
  .col-xxl {
    flex: 1 0 0%; }
  .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 91.66667%; }
  .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 83.33333%; }
  .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 75%; }
  .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 66.66667%; }
  .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 58.33333%; }
  .row-cols-xxl-7 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-xxl-8 > * {
    flex: 0 0 auto;
    width: 41.66667%; }
  .row-cols-xxl-9 > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-xxl-10 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-xxl-11 > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .row-cols-xxl-12 > * {
    flex: 0 0 auto;
    width: 8.33333%; } }

@media (min-width: 1680px) {
  .col-yl {
    flex: 1 0 0%; }
  .row-cols-yl-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-yl-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-yl-2 > * {
    flex: 0 0 auto;
    width: 91.66667%; }
  .row-cols-yl-3 > * {
    flex: 0 0 auto;
    width: 83.33333%; }
  .row-cols-yl-4 > * {
    flex: 0 0 auto;
    width: 75%; }
  .row-cols-yl-5 > * {
    flex: 0 0 auto;
    width: 66.66667%; }
  .row-cols-yl-6 > * {
    flex: 0 0 auto;
    width: 58.33333%; }
  .row-cols-yl-7 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-yl-8 > * {
    flex: 0 0 auto;
    width: 41.66667%; }
  .row-cols-yl-9 > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-yl-10 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-yl-11 > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .row-cols-yl-12 > * {
    flex: 0 0 auto;
    width: 8.33333%; } }

.col-auto {
  flex: 0 0 auto;
  width: auto; }

.col-1 {
  flex: 0 0 auto;
  width: 8.33333%; }

.col-1-important {
  flex: 0 0 auto !important;
  width: 8.33333% !important; }

.col-2 {
  flex: 0 0 auto;
  width: 16.66667%; }

.col-2-important {
  flex: 0 0 auto !important;
  width: 16.66667% !important; }

.col-3 {
  flex: 0 0 auto;
  width: 25%; }

.col-3-important {
  flex: 0 0 auto !important;
  width: 25% !important; }

.col-4 {
  flex: 0 0 auto;
  width: 33.33333%; }

.col-4-important {
  flex: 0 0 auto !important;
  width: 33.33333% !important; }

.col-5 {
  flex: 0 0 auto;
  width: 41.66667%; }

.col-5-important {
  flex: 0 0 auto !important;
  width: 41.66667% !important; }

.col-6, .video-wrapper > *:not(:first-child) {
  flex: 0 0 auto;
  width: 50%; }

.col-6-important {
  flex: 0 0 auto !important;
  width: 50% !important; }

.col-7 {
  flex: 0 0 auto;
  width: 58.33333%; }

.col-7-important {
  flex: 0 0 auto !important;
  width: 58.33333% !important; }

.col-8 {
  flex: 0 0 auto;
  width: 66.66667%; }

.col-8-important {
  flex: 0 0 auto !important;
  width: 66.66667% !important; }

.col-9 {
  flex: 0 0 auto;
  width: 75%; }

.col-9-important {
  flex: 0 0 auto !important;
  width: 75% !important; }

.col-10 {
  flex: 0 0 auto;
  width: 83.33333%; }

.col-10-important {
  flex: 0 0 auto !important;
  width: 83.33333% !important; }

.col-11 {
  flex: 0 0 auto;
  width: 91.66667%; }

.col-11-important {
  flex: 0 0 auto !important;
  width: 91.66667% !important; }

.col-12, .section-popup .popup-album-wrapper, .video-wrapper > *:first-child {
  flex: 0 0 auto;
  width: 100%; }

.col-12-important {
  flex: 0 0 auto !important;
  width: 100% !important; }

.offset-0 {
  margin-left: 0%; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

.gx-8 {
  --bs-gutter-x: 8px; }

.gy-8 {
  --bs-gutter-y: 8px; }

.gx-16 {
  --bs-gutter-x: 16px; }

.gy-16 {
  --bs-gutter-y: 16px; }

.gx-27 {
  --bs-gutter-x: 27px; }

.gy-27 {
  --bs-gutter-y: 27px; }

.gx-32 {
  --bs-gutter-x: 32px; }

.gy-32 {
  --bs-gutter-y: 32px; }

.gx-40 {
  --bs-gutter-x: 40px; }

.gy-40 {
  --bs-gutter-y: 40px; }

.gx-48 {
  --bs-gutter-x: 48px; }

.gy-48 {
  --bs-gutter-y: 48px; }

.gx-56 {
  --bs-gutter-x: 56px; }

.gy-56 {
  --bs-gutter-y: 56px; }

.gx-64 {
  --bs-gutter-x: 64px; }

.gy-64 {
  --bs-gutter-y: 64px; }

@media (min-width: 576px) {
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-sm-0 > * {
    margin-left: 0%; }
  .offset-sm-1 > * {
    margin-left: 8.33333%; }
  .offset-sm-2 > * {
    margin-left: 16.66667%; }
  .offset-sm-3 > * {
    margin-left: 25%; }
  .offset-sm-4 > * {
    margin-left: 33.33333%; }
  .offset-sm-5 > * {
    margin-left: 41.66667%; }
  .offset-sm-6 > * {
    margin-left: 50%; }
  .offset-sm-7 > * {
    margin-left: 58.33333%; }
  .offset-sm-8 > * {
    margin-left: 66.66667%; }
  .offset-sm-9 > * {
    margin-left: 75%; }
  .offset-sm-10 > * {
    margin-left: 83.33333%; }
  .offset-sm-11 > * {
    margin-left: 91.66667%; }
  .gx-sm-8 {
    --bs-gutter-x: 8px; }
  .gy-sm-8 {
    --bs-gutter-y: 8px; }
  .gx-sm-16 {
    --bs-gutter-x: 16px; }
  .gy-sm-16 {
    --bs-gutter-y: 16px; }
  .gx-sm-27 {
    --bs-gutter-x: 27px; }
  .gy-sm-27 {
    --bs-gutter-y: 27px; }
  .gx-sm-32 {
    --bs-gutter-x: 32px; }
  .gy-sm-32 {
    --bs-gutter-y: 32px; }
  .gx-sm-40 {
    --bs-gutter-x: 40px; }
  .gy-sm-40 {
    --bs-gutter-y: 40px; }
  .gx-sm-48 {
    --bs-gutter-x: 48px; }
  .gy-sm-48 {
    --bs-gutter-y: 48px; }
  .gx-sm-56 {
    --bs-gutter-x: 56px; }
  .gy-sm-56 {
    --bs-gutter-y: 56px; }
  .gx-sm-64 {
    --bs-gutter-x: 64px; }
  .gy-sm-64 {
    --bs-gutter-y: 64px; } }

@media (min-width: 768px) {
  .col-md-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-md-0 > * {
    margin-left: 0%; }
  .offset-md-1 > * {
    margin-left: 8.33333%; }
  .offset-md-2 > * {
    margin-left: 16.66667%; }
  .offset-md-3 > * {
    margin-left: 25%; }
  .offset-md-4 > * {
    margin-left: 33.33333%; }
  .offset-md-5 > * {
    margin-left: 41.66667%; }
  .offset-md-6 > * {
    margin-left: 50%; }
  .offset-md-7 > * {
    margin-left: 58.33333%; }
  .offset-md-8 > * {
    margin-left: 66.66667%; }
  .offset-md-9 > * {
    margin-left: 75%; }
  .offset-md-10 > * {
    margin-left: 83.33333%; }
  .offset-md-11 > * {
    margin-left: 91.66667%; }
  .gx-md-8 {
    --bs-gutter-x: 8px; }
  .gy-md-8 {
    --bs-gutter-y: 8px; }
  .gx-md-16 {
    --bs-gutter-x: 16px; }
  .gy-md-16 {
    --bs-gutter-y: 16px; }
  .gx-md-27 {
    --bs-gutter-x: 27px; }
  .gy-md-27 {
    --bs-gutter-y: 27px; }
  .gx-md-32 {
    --bs-gutter-x: 32px; }
  .gy-md-32 {
    --bs-gutter-y: 32px; }
  .gx-md-40 {
    --bs-gutter-x: 40px; }
  .gy-md-40 {
    --bs-gutter-y: 40px; }
  .gx-md-48 {
    --bs-gutter-x: 48px; }
  .gy-md-48 {
    --bs-gutter-y: 48px; }
  .gx-md-56 {
    --bs-gutter-x: 56px; }
  .gy-md-56 {
    --bs-gutter-y: 56px; }
  .gx-md-64 {
    --bs-gutter-x: 64px; }
  .gy-md-64 {
    --bs-gutter-y: 64px; } }

@media (min-width: 992px) {
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-lg-0 > * {
    margin-left: 0%; }
  .offset-lg-1 > * {
    margin-left: 8.33333%; }
  .offset-lg-2 > * {
    margin-left: 16.66667%; }
  .offset-lg-3 > * {
    margin-left: 25%; }
  .offset-lg-4 > * {
    margin-left: 33.33333%; }
  .offset-lg-5 > * {
    margin-left: 41.66667%; }
  .offset-lg-6 > * {
    margin-left: 50%; }
  .offset-lg-7 > * {
    margin-left: 58.33333%; }
  .offset-lg-8 > * {
    margin-left: 66.66667%; }
  .offset-lg-9 > * {
    margin-left: 75%; }
  .offset-lg-10 > * {
    margin-left: 83.33333%; }
  .offset-lg-11 > * {
    margin-left: 91.66667%; }
  .gx-lg-8 {
    --bs-gutter-x: 8px; }
  .gy-lg-8 {
    --bs-gutter-y: 8px; }
  .gx-lg-16 {
    --bs-gutter-x: 16px; }
  .gy-lg-16 {
    --bs-gutter-y: 16px; }
  .gx-lg-27 {
    --bs-gutter-x: 27px; }
  .gy-lg-27 {
    --bs-gutter-y: 27px; }
  .gx-lg-32 {
    --bs-gutter-x: 32px; }
  .gy-lg-32 {
    --bs-gutter-y: 32px; }
  .gx-lg-40 {
    --bs-gutter-x: 40px; }
  .gy-lg-40 {
    --bs-gutter-y: 40px; }
  .gx-lg-48 {
    --bs-gutter-x: 48px; }
  .gy-lg-48 {
    --bs-gutter-y: 48px; }
  .gx-lg-56 {
    --bs-gutter-x: 56px; }
  .gy-lg-56 {
    --bs-gutter-y: 56px; }
  .gx-lg-64 {
    --bs-gutter-x: 64px; }
  .gy-lg-64 {
    --bs-gutter-y: 64px; } }

@media (min-width: 1200px) {
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-xl-0 > * {
    margin-left: 0%; }
  .offset-xl-1 > * {
    margin-left: 8.33333%; }
  .offset-xl-2 > * {
    margin-left: 16.66667%; }
  .offset-xl-3 > * {
    margin-left: 25%; }
  .offset-xl-4 > * {
    margin-left: 33.33333%; }
  .offset-xl-5 > * {
    margin-left: 41.66667%; }
  .offset-xl-6 > * {
    margin-left: 50%; }
  .offset-xl-7 > * {
    margin-left: 58.33333%; }
  .offset-xl-8 > * {
    margin-left: 66.66667%; }
  .offset-xl-9 > * {
    margin-left: 75%; }
  .offset-xl-10 > * {
    margin-left: 83.33333%; }
  .offset-xl-11 > * {
    margin-left: 91.66667%; }
  .gx-xl-8 {
    --bs-gutter-x: 8px; }
  .gy-xl-8 {
    --bs-gutter-y: 8px; }
  .gx-xl-16 {
    --bs-gutter-x: 16px; }
  .gy-xl-16 {
    --bs-gutter-y: 16px; }
  .gx-xl-27 {
    --bs-gutter-x: 27px; }
  .gy-xl-27 {
    --bs-gutter-y: 27px; }
  .gx-xl-32 {
    --bs-gutter-x: 32px; }
  .gy-xl-32 {
    --bs-gutter-y: 32px; }
  .gx-xl-40 {
    --bs-gutter-x: 40px; }
  .gy-xl-40 {
    --bs-gutter-y: 40px; }
  .gx-xl-48 {
    --bs-gutter-x: 48px; }
  .gy-xl-48 {
    --bs-gutter-y: 48px; }
  .gx-xl-56 {
    --bs-gutter-x: 56px; }
  .gy-xl-56 {
    --bs-gutter-y: 56px; }
  .gx-xl-64 {
    --bs-gutter-x: 64px; }
  .gy-xl-64 {
    --bs-gutter-y: 64px; } }

@media (min-width: 1400px) {
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-xxl-0 > * {
    margin-left: 0%; }
  .offset-xxl-1 > * {
    margin-left: 8.33333%; }
  .offset-xxl-2 > * {
    margin-left: 16.66667%; }
  .offset-xxl-3 > * {
    margin-left: 25%; }
  .offset-xxl-4 > * {
    margin-left: 33.33333%; }
  .offset-xxl-5 > * {
    margin-left: 41.66667%; }
  .offset-xxl-6 > * {
    margin-left: 50%; }
  .offset-xxl-7 > * {
    margin-left: 58.33333%; }
  .offset-xxl-8 > * {
    margin-left: 66.66667%; }
  .offset-xxl-9 > * {
    margin-left: 75%; }
  .offset-xxl-10 > * {
    margin-left: 83.33333%; }
  .offset-xxl-11 > * {
    margin-left: 91.66667%; }
  .gx-xxl-8 {
    --bs-gutter-x: 8px; }
  .gy-xxl-8 {
    --bs-gutter-y: 8px; }
  .gx-xxl-16 {
    --bs-gutter-x: 16px; }
  .gy-xxl-16 {
    --bs-gutter-y: 16px; }
  .gx-xxl-27 {
    --bs-gutter-x: 27px; }
  .gy-xxl-27 {
    --bs-gutter-y: 27px; }
  .gx-xxl-32 {
    --bs-gutter-x: 32px; }
  .gy-xxl-32 {
    --bs-gutter-y: 32px; }
  .gx-xxl-40 {
    --bs-gutter-x: 40px; }
  .gy-xxl-40 {
    --bs-gutter-y: 40px; }
  .gx-xxl-48 {
    --bs-gutter-x: 48px; }
  .gy-xxl-48 {
    --bs-gutter-y: 48px; }
  .gx-xxl-56 {
    --bs-gutter-x: 56px; }
  .gy-xxl-56 {
    --bs-gutter-y: 56px; }
  .gx-xxl-64 {
    --bs-gutter-x: 64px; }
  .gy-xxl-64 {
    --bs-gutter-y: 64px; } }

@media (min-width: 1680px) {
  .col-yl-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-yl-1 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-yl-2 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-yl-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-yl-4 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-yl-5 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-yl-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-yl-7 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-yl-8 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-yl-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-yl-10 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-yl-11 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-yl-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-yl-0 > * {
    margin-left: 0%; }
  .offset-yl-1 > * {
    margin-left: 8.33333%; }
  .offset-yl-2 > * {
    margin-left: 16.66667%; }
  .offset-yl-3 > * {
    margin-left: 25%; }
  .offset-yl-4 > * {
    margin-left: 33.33333%; }
  .offset-yl-5 > * {
    margin-left: 41.66667%; }
  .offset-yl-6 > * {
    margin-left: 50%; }
  .offset-yl-7 > * {
    margin-left: 58.33333%; }
  .offset-yl-8 > * {
    margin-left: 66.66667%; }
  .offset-yl-9 > * {
    margin-left: 75%; }
  .offset-yl-10 > * {
    margin-left: 83.33333%; }
  .offset-yl-11 > * {
    margin-left: 91.66667%; }
  .gx-yl-8 {
    --bs-gutter-x: 8px; }
  .gy-yl-8 {
    --bs-gutter-y: 8px; }
  .gx-yl-16 {
    --bs-gutter-x: 16px; }
  .gy-yl-16 {
    --bs-gutter-y: 16px; }
  .gx-yl-27 {
    --bs-gutter-x: 27px; }
  .gy-yl-27 {
    --bs-gutter-y: 27px; }
  .gx-yl-32 {
    --bs-gutter-x: 32px; }
  .gy-yl-32 {
    --bs-gutter-y: 32px; }
  .gx-yl-40 {
    --bs-gutter-x: 40px; }
  .gy-yl-40 {
    --bs-gutter-y: 40px; }
  .gx-yl-48 {
    --bs-gutter-x: 48px; }
  .gy-yl-48 {
    --bs-gutter-y: 48px; }
  .gx-yl-56 {
    --bs-gutter-x: 56px; }
  .gy-yl-56 {
    --bs-gutter-y: 56px; }
  .gx-yl-64 {
    --bs-gutter-x: 64px; }
  .gy-yl-64 {
    --bs-gutter-y: 64px; } }

/*# sourceMappingURL=bootstrap-grid.css.map */
@keyframes rotateAround3Item1 {
  0% {
    stroke-width: 0; }
  65% {
    stroke-width: 0.3; }
  70% {
    stroke-width: 0.7; }
  80% {
    stroke-width: 0.75; }
  90% {
    stroke-width: 0.7; }
  95% {
    stroke-width: 0.3; }
  100% {
    stroke-width: 0; } }

@keyframes rotate360deg {
  to {
    transform: translate(-50%, -50%) rotate(360deg); } }

@keyframes hoverBox {
  0% {
    opacity: 1;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%); }
  100% {
    clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
    opacity: 0.5; } }

@keyframes StrokeLineDown {
  0% {
    stroke-dashoffset: 0; }
  100% {
    stroke-dashoffset: -200; } }

@keyframes StrokeLineUp {
  0% {
    stroke-dashoffset: 0; }
  100% {
    stroke-dashoffset: 200; } }

@keyframes bounceInBottom {
  0% {
    opacity: 0;
    transform: translate3d(0, -700px, 0); }
  75% {
    opacity: 1;
    transform: translate3d(0, -25px, 0); }
  80% {
    transform: translate3d(0, 5px, 0); }
  90% {
    transform: translate3d(0, -5px, 0); }
  to {
    transform: translateZ(0);
    opacity: 1; } }

@keyframes bounceOpacity {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes lightSpeedIn {
  0% {
    transform: translateX(100%) skewX(-30deg);
    opacity: 0; }
  60% {
    transform: translateX(-20%) skewX(30deg);
    opacity: 1; }
  80% {
    transform: translateX(0%) skewX(-15deg);
    opacity: 1; }
  100% {
    transform: translateX(0%) skewX(0deg);
    opacity: 1; } }

*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0; }

html, body {
  -webkit-touch-callout: none;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-font-smoothing: antialiased;
  scroll-behavior: smooth;
  line-height: 1.2;
  font-family: "Roboto", sans-serif;
  font-weight: 400; }

html {
  font-size: 52.5%; }
  @media (min-width: 769px) {
    html {
      font-size: 57.5%; } }
  @media (min-width: 1281px) {
    html {
      font-size: 62.5%; } }

body {
  font-size: 1.6rem;
  background-color: #ffffff;
  color: #171717;
  max-width: 100%;
  overflow-x: hidden; }

main {
  position: relative;
  width: 100%; }

.swiper-slide {
  height: auto; }

main,
#banner,
#bannerSub,
#breadcrumb {
  margin-left: auto;
  margin-right: auto; }

article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  -webkit-text-decoration-skip-ink: none;
  text-decoration-skip-ink: none; }

.hidden {
  display: none; }

address {
  margin-bottom: 0;
  font-style: normal;
  line-height: inherit; }

button {
  background: transparent;
  border: none;
  outline: none; }
  button:hover {
    background: transparent;
    border: none;
    outline: none; }

b,
strong {
  font-weight: bolder;
  font-weight: 700; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -0.25em; }

sup {
  top: -0.5em; }

a {
  text-decoration: none;
  background-color: transparent; }

pre,
code,
kbd,
samp {
  font-family: inherit;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 0;
  overflow: auto; }

figure {
  margin: 0; }

img {
  border-style: none;
  max-width: 100%;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0;
  padding-bottom: 0;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: 0; }

button {
  border-radius: 0; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

select {
  word-wrap: normal; }

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

*:focus,
*:active {
  outline: none; }

@font-face {
  font-family: "Helvetica Neue";
  src: url("../fonts/SVN-Helvetica Neue.ttf") format("truetype");
  font-weight: 400; }

@font-face {
  font-family: "Helvetica Neue";
  src: url("../fonts/SVN-Helvetica Neue Light.ttf") format("truetype");
  font-weight: 300; }

@font-face {
  font-family: "Helvetica Neue";
  src: url("../fonts/SVN-Helvetica Neue Bold.ttf") format("truetype");
  font-weight: 700; }

@font-face {
  font-family: "Helvetica Neue";
  src: url("../fonts/SVN-Helvetica Neue Bold Italic.ttf") format("truetype");
  font-weight: 700;
  font-style: italic; }

@font-face {
  font-family: "Helvetica Neue";
  src: url("../fonts/SVN-Helvetica Neue Heavy.ttf") format("truetype");
  font-weight: 800; }

.noSelect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.margin-center {
  margin-left: auto;
  margin-right: auto; }

.p-line-clamp-1 {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden; }

.p-line-clamp-2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden; }

.p-line-clamp-3 {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden; }

.p-line-clamp-4 {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden; }

.p-line-clamp-5 {
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden; }

.p-line-clamp-6 {
  display: -webkit-box;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
  overflow: hidden; }

.p-line-clamp-7 {
  display: -webkit-box;
  -webkit-line-clamp: 7;
  -webkit-box-orient: vertical;
  overflow: hidden; }

.p-line-clamp-8 {
  display: -webkit-box;
  -webkit-line-clamp: 8;
  -webkit-box-orient: vertical;
  overflow: hidden; }

.p-line-clamp-9 {
  display: -webkit-box;
  -webkit-line-clamp: 9;
  -webkit-box-orient: vertical;
  overflow: hidden; }

.p-line-clamp-10 {
  display: -webkit-box;
  -webkit-line-clamp: 10;
  -webkit-box-orient: vertical;
  overflow: hidden; }

.wrapper {
  padding-right: 0; }
  @media (min-width: 1200px) {
    .wrapper {
      padding-right: 6.6rem; } }

.wrapper2 {
  padding-right: 0; }
  @media (min-width: 1200px) {
    .wrapper2 {
      padding-right: 3.2rem; } }

.block-link {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 1; }

.title-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column; }
  .title-wrapper.left {
    justify-content: flex-start; }
  .title-wrapper .sub-desc {
    text-align: center;
    margin-top: 18px; }
    .title-wrapper .sub-desc > * {
      font-size: 15px;
      color: #171717; }
      @media (min-width: 768px) {
        .title-wrapper .sub-desc > * {
          font-size: 1.5rem; } }
      @media (min-width: 1200px) {
        .title-wrapper .sub-desc > * {
          font-size: 1.6rem; } }

.space-120 {
  padding-top: 120px; }

.space-82 {
  padding-top: 82px; }

.space-64 {
  padding-top: 64px; }

.space-60 {
  padding-top: 60px; }

.space-56 {
  padding-top: 56px; }

.space-54 {
  padding-top: 54px; }

.space-48 {
  padding-top: 48px; }

.space-45 {
  padding-top: 45px; }

.space-42 {
  padding-top: 42px; }

.space-40 {
  padding-top: 40px; }

.space-38 {
  padding-top: 38px; }

.space-36 {
  padding-top: 36px; }

.space-32 {
  padding-top: 32px; }

.space-24 {
  padding-top: 24px; }

.space-20 {
  padding-top: 20px; }

.space-16 {
  padding-top: 16px; }

.space-12 {
  padding-top: 12px; }

.space-10 {
  padding-top: 10px; }

.space-8 {
  padding-top: 8px; }

.space-4 {
  padding-top: 4px; }

.space-0 {
  padding-top: 0px; }

@media (min-width: 576px) {
  .space-sm-120 {
    padding-top: 120px; }
  .space-sm-82 {
    padding-top: 82px; }
  .space-sm-64 {
    padding-top: 64px; }
  .space-sm-60 {
    padding-top: 60px; }
  .space-sm-56 {
    padding-top: 56px; }
  .space-sm-54 {
    padding-top: 54px; }
  .space-sm-48 {
    padding-top: 48px; }
  .space-sm-45 {
    padding-top: 45px; }
  .space-sm-42 {
    padding-top: 42px; }
  .space-sm-40 {
    padding-top: 40px; }
  .space-sm-38 {
    padding-top: 38px; }
  .space-sm-36 {
    padding-top: 36px; }
  .space-sm-32 {
    padding-top: 32px; }
  .space-sm-24 {
    padding-top: 24px; }
  .space-sm-20 {
    padding-top: 20px; }
  .space-sm-16 {
    padding-top: 16px; }
  .space-sm-12 {
    padding-top: 12px; }
  .space-sm-10 {
    padding-top: 10px; }
  .space-sm-8 {
    padding-top: 8px; }
  .space-sm-4 {
    padding-top: 4px; }
  .space-sm-0 {
    padding-top: 0px; } }

@media (min-width: 768px) {
  .space-md-120 {
    padding-top: 120px; }
  .space-md-82 {
    padding-top: 82px; }
  .space-md-64 {
    padding-top: 64px; }
  .space-md-60 {
    padding-top: 60px; }
  .space-md-56 {
    padding-top: 56px; }
  .space-md-54 {
    padding-top: 54px; }
  .space-md-48 {
    padding-top: 48px; }
  .space-md-45 {
    padding-top: 45px; }
  .space-md-42 {
    padding-top: 42px; }
  .space-md-40 {
    padding-top: 40px; }
  .space-md-38 {
    padding-top: 38px; }
  .space-md-36 {
    padding-top: 36px; }
  .space-md-32 {
    padding-top: 32px; }
  .space-md-24 {
    padding-top: 24px; }
  .space-md-20 {
    padding-top: 20px; }
  .space-md-16 {
    padding-top: 16px; }
  .space-md-12 {
    padding-top: 12px; }
  .space-md-10 {
    padding-top: 10px; }
  .space-md-8 {
    padding-top: 8px; }
  .space-md-4 {
    padding-top: 4px; }
  .space-md-0 {
    padding-top: 0px; } }

@media (min-width: 992px) {
  .space-lg-120 {
    padding-top: 120px; }
  .space-lg-82 {
    padding-top: 82px; }
  .space-lg-64 {
    padding-top: 64px; }
  .space-lg-60 {
    padding-top: 60px; }
  .space-lg-56 {
    padding-top: 56px; }
  .space-lg-54 {
    padding-top: 54px; }
  .space-lg-48 {
    padding-top: 48px; }
  .space-lg-45 {
    padding-top: 45px; }
  .space-lg-42 {
    padding-top: 42px; }
  .space-lg-40 {
    padding-top: 40px; }
  .space-lg-38 {
    padding-top: 38px; }
  .space-lg-36 {
    padding-top: 36px; }
  .space-lg-32 {
    padding-top: 32px; }
  .space-lg-24 {
    padding-top: 24px; }
  .space-lg-20 {
    padding-top: 20px; }
  .space-lg-16 {
    padding-top: 16px; }
  .space-lg-12 {
    padding-top: 12px; }
  .space-lg-10 {
    padding-top: 10px; }
  .space-lg-8 {
    padding-top: 8px; }
  .space-lg-4 {
    padding-top: 4px; }
  .space-lg-0 {
    padding-top: 0px; } }

@media (min-width: 1200px) {
  .space-xl-120 {
    padding-top: 120px; }
  .space-xl-82 {
    padding-top: 82px; }
  .space-xl-64 {
    padding-top: 64px; }
  .space-xl-60 {
    padding-top: 60px; }
  .space-xl-56 {
    padding-top: 56px; }
  .space-xl-54 {
    padding-top: 54px; }
  .space-xl-48 {
    padding-top: 48px; }
  .space-xl-45 {
    padding-top: 45px; }
  .space-xl-42 {
    padding-top: 42px; }
  .space-xl-40 {
    padding-top: 40px; }
  .space-xl-38 {
    padding-top: 38px; }
  .space-xl-36 {
    padding-top: 36px; }
  .space-xl-32 {
    padding-top: 32px; }
  .space-xl-24 {
    padding-top: 24px; }
  .space-xl-20 {
    padding-top: 20px; }
  .space-xl-16 {
    padding-top: 16px; }
  .space-xl-12 {
    padding-top: 12px; }
  .space-xl-10 {
    padding-top: 10px; }
  .space-xl-8 {
    padding-top: 8px; }
  .space-xl-4 {
    padding-top: 4px; }
  .space-xl-0 {
    padding-top: 0px; } }

@media (min-width: 1400px) {
  .space-xxl-120 {
    padding-top: 120px; }
  .space-xxl-82 {
    padding-top: 82px; }
  .space-xxl-64 {
    padding-top: 64px; }
  .space-xxl-60 {
    padding-top: 60px; }
  .space-xxl-56 {
    padding-top: 56px; }
  .space-xxl-54 {
    padding-top: 54px; }
  .space-xxl-48 {
    padding-top: 48px; }
  .space-xxl-45 {
    padding-top: 45px; }
  .space-xxl-42 {
    padding-top: 42px; }
  .space-xxl-40 {
    padding-top: 40px; }
  .space-xxl-38 {
    padding-top: 38px; }
  .space-xxl-36 {
    padding-top: 36px; }
  .space-xxl-32 {
    padding-top: 32px; }
  .space-xxl-24 {
    padding-top: 24px; }
  .space-xxl-20 {
    padding-top: 20px; }
  .space-xxl-16 {
    padding-top: 16px; }
  .space-xxl-12 {
    padding-top: 12px; }
  .space-xxl-10 {
    padding-top: 10px; }
  .space-xxl-8 {
    padding-top: 8px; }
  .space-xxl-4 {
    padding-top: 4px; }
  .space-xxl-0 {
    padding-top: 0px; } }

@media (min-width: 1680px) {
  .space-yl-120 {
    padding-top: 120px; }
  .space-yl-82 {
    padding-top: 82px; }
  .space-yl-64 {
    padding-top: 64px; }
  .space-yl-60 {
    padding-top: 60px; }
  .space-yl-56 {
    padding-top: 56px; }
  .space-yl-54 {
    padding-top: 54px; }
  .space-yl-48 {
    padding-top: 48px; }
  .space-yl-45 {
    padding-top: 45px; }
  .space-yl-42 {
    padding-top: 42px; }
  .space-yl-40 {
    padding-top: 40px; }
  .space-yl-38 {
    padding-top: 38px; }
  .space-yl-36 {
    padding-top: 36px; }
  .space-yl-32 {
    padding-top: 32px; }
  .space-yl-24 {
    padding-top: 24px; }
  .space-yl-20 {
    padding-top: 20px; }
  .space-yl-16 {
    padding-top: 16px; }
  .space-yl-12 {
    padding-top: 12px; }
  .space-yl-10 {
    padding-top: 10px; }
  .space-yl-8 {
    padding-top: 8px; }
  .space-yl-4 {
    padding-top: 4px; }
  .space-yl-0 {
    padding-top: 0px; } }

.content-line .text {
  font-size: 15px; }
  @media (min-width: 768px) {
    .content-line .text {
      font-size: 1.5rem; } }
  @media (min-width: 1200px) {
    .content-line .text {
      font-size: 1.6rem; } }

.content-line p {
  color: #171717;
  font-size: 15px;
  white-space: pre-wrap;
  line-height: 1.23; }
  @media (min-width: 768px) {
    .content-line p {
      font-size: 1.5rem; } }
  @media (min-width: 1200px) {
    .content-line p {
      font-size: 1.6rem; } }

.content-line span {
  font-size: 15px; }
  @media (min-width: 768px) {
    .content-line span {
      font-size: 1.5rem; } }
  @media (min-width: 1200px) {
    .content-line span {
      font-size: 1.6rem; } }

.content-line img {
  width: 100%;
  height: auto; }

.content-line p.note {
  font-size: 14px;
  text-align: center;
  width: 90%;
  font-weight: 400; }
  @media (min-width: 1200px) {
    .content-line p.note {
      font-size: 1.4rem; } }

.content-line img ~ p.note {
  margin-top: 8px; }

.content-line .head {
  color: #1F6BB4;
  font-weight: 700; }

.content-line .small {
  font-size: 15px; }
  @media (min-width: 768px) {
    .content-line .small {
      font-size: 1.5rem; } }
  @media (min-width: 1200px) {
    .content-line .small {
      font-size: 1.6rem; } }

.content-line .big {
  font-size: 2.6rem; }
  @media (min-width: 768px) {
    .content-line .big {
      font-size: 2.8rem; } }
  @media (min-width: 1400px) {
    .content-line .big {
      font-size: 3.2rem; } }

.content-line .bold {
  font-weight: 700; }

.content-line .table > * p {
  height: 100%;
  padding: 16px 12px;
  text-transform: uppercase;
  font-size: 15px;
  font-weight: 700;
  background-color: #F5F5F5;
  text-align: center; }
  @media (min-width: 768px) {
    .content-line .table > * p {
      font-size: 1.5rem; } }
  @media (min-width: 1200px) {
    .content-line .table > * p {
      font-size: 1.6rem; } }

.content-line .table2 > * p {
  height: 100%;
  padding: 40px 12px;
  text-transform: uppercase;
  font-size: 15px;
  font-weight: 700;
  background-color: #F5F5F5;
  text-align: center; }
  @media (min-width: 768px) {
    .content-line .table2 > * p {
      font-size: 1.5rem; } }
  @media (min-width: 1200px) {
    .content-line .table2 > * p {
      font-size: 1.6rem; } }

.content-line .table2 > * img {
  height: 100%; }

.content-line ul {
  list-style-type: none; }
  .content-line ul li {
    font-size: 15px;
    padding-left: 20px;
    position: relative; }
    @media (min-width: 768px) {
      .content-line ul li {
        font-size: 1.5rem; } }
    @media (min-width: 1200px) {
      .content-line ul li {
        font-size: 1.6rem; } }
    .content-line ul li::after {
      content: "";
      position: absolute;
      left: 0;
      transform: translate(0, -50%);
      background-color: #1F6BB4;
      border-radius: 50%;
      --rLi: 6px;
      --tLi: 10px;
      top: var(--tLi);
      width: var(--rLi);
      height: var(--rLi); }
      @media (min-width: 768px) {
        .content-line ul li::after {
          --tLi: 10px;
          --rLi: 6px; } }
      @media (min-width: 1200px) {
        .content-line ul li::after {
          --tLi: 11px;
          --rLi: 8px; } }

.social-contact {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: -12px;
  margin-left: -8px;
  margin-right: -8px; }
  .social-contact__item {
    display: block;
    padding-top: 12px;
    padding-left: 8px;
    padding-right: 8px; }
    .social-contact__item-link {
      transition: .3s all linear;
      cursor: pointer;
      position: relative; }
      .social-contact__item-link svg path {
        transition: .3s all linear; }
      .social-contact__item-link:hover svg path {
        fill: #707070; }

.form-title > * {
  font-weight: 700;
  color: #171717;
  font-size: 2rem; }
  @media (min-width: 768px) {
    .form-title > * {
      font-size: 2.2rem; } }
  @media (min-width: 1200px) {
    .form-title > * {
      font-size: 2.4rem; } }

.check-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap; }
  .check-list__item {
    cursor: pointer;
    min-width: 82px;
    padding: 16px 16px;
    text-align: center;
    font-size: 15px;
    background: linear-gradient(to right, #FFFCFC, #EBEBEB);
    border: 1px solid #707070;
    font-weight: 700;
    color: #1F6BB4; }
    @media (min-width: 768px) {
      .check-list__item {
        font-size: 1.6rem; } }
    @media (min-width: 1400px) {
      .check-list__item {
        font-size: 1.8rem; } }

@keyframes rotateSvg {
  0% {
    transform: rotate(0); }
  100% {
    transform: rotate(359deg); } }

@keyframes strokeSvg {
  0% {
    stroke-dashoffset: 0;
    stroke-dasharray: 15 5; }
  100% {
    stroke-dashoffset: -179;
    stroke-dasharray: 15 5; } }

.settings {
  z-index: 999;
  position: fixed;
  top: 20%;
  right: 0%;
  --timeAnimation: 3s;
  display: none; }
  @media (min-width: 992px) {
    .settings {
      display: block; } }
  .settings-item:not(:first-child) {
    margin-top: 38px; }
  .settings-item .fixed {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-start; }
    .settings-item .fixed.fixed--white {
      --bgFixed: #fff;
      --fillFixed: #1F6BB4;
      --fillhover01: #1F6BB4;
      --fillhover02: #fff; }
      .settings-item .fixed.fixed--white .fixed-item__icon path {
        transition: .5s ease; }
      .settings-item .fixed.fixed--white .fixed-item:hover .pathFixed01 {
        fill: var(--fillhover01); }
      .settings-item .fixed.fixed--white .fixed-item:hover .pathFixed02 {
        fill: var(--fillhover02); }
    .settings-item .fixed.fixed--main {
      --bgFixed: #1F6BB4;
      --fillFixed: #fff;
      --fillhover: #1F6BB4; }
      .settings-item .fixed.fixed--main .fixed-item__icon path {
        transition: .5s ease; }
      .settings-item .fixed.fixed--main .fixed-item:hover .fixed-item__icon path {
        fill: var(--fillhover); }
    .settings-item .fixed-icons {
      background-color: var(--bgFixed);
      width: 77px;
      height: 77px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50% 0 0 50%;
      cursor: pointer; }
      .settings-item .fixed-icons .rotateSvg {
        transform-origin: center;
        animation: rotateSvg var(--timeAnimation) linear infinite; }
      .settings-item .fixed-icons .strokeSvg {
        animation: strokeSvg var(--timeAnimation) linear infinite; }
      .settings-item .fixed-icons.clicked ~ .fixed-list {
        transform: scale(1);
        height: auto;
        width: auto;
        padding: 20px 18px;
        border-radius: 0 0 0 20px; }
    .settings-item .fixed-list {
      background-color: var(--bgFixed);
      height: 0;
      width: 0;
      padding: 0;
      transform: scale(0);
      transform-origin: top left;
      transition: .5s ease; }
    .settings-item .fixed-item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 20px 10px;
      min-width: 105px;
      border: 1px solid var(--fillFixed);
      border-radius: 10px;
      cursor: pointer;
      transition: .5s ease; }
      .settings-item .fixed-item:not(:first-child) {
        margin-top: 16px; }
      .settings-item .fixed-item__title {
        margin-top: 10px; }
        .settings-item .fixed-item__title > * {
          transition: .5s ease;
          font-weight: 700;
          color: var(--fillFixed);
          font-size: 15px; }
          @media (min-width: 768px) {
            .settings-item .fixed-item__title > * {
              font-size: 1.5rem; } }
          @media (min-width: 1200px) {
            .settings-item .fixed-item__title > * {
              font-size: 1.6rem; } }
      .settings-item .fixed-item:hover {
        background-color: var(--fillFixed); }
        .settings-item .fixed-item:hover .fixed-item__title > * {
          color: var(--bgFixed); }

.scroll-to-top {
  z-index: 999;
  position: fixed;
  bottom: 20%;
  right: 20px;
  width: 28px;
  height: 28px;
  background-color: #1F6BB4;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  cursor: pointer;
  transition: .5s ease; }
  .scroll-to-top svg {
    transform: rotate(-90deg);
    transform-origin: center; }
  .scroll-to-top:hover {
    background-color: #B50006; }

.btn-seemore {
  display: inline-flex;
  align-items: center;
  color: white; }
  .btn-seemore_link {
    transition: .3s ease-in-out;
    margin-left: 10px;
    text-transform: uppercase;
    color: #171717;
    font-weight: 700;
    font-size: 15px; }
    @media (min-width: 768px) {
      .btn-seemore_link {
        font-size: 1.5rem; } }
    @media (min-width: 1200px) {
      .btn-seemore_link {
        font-size: 1.6rem; } }
  .btn-seemore .btn-svg {
    margin-bottom: -3px;
    transition: .3s ease-in-out; }
  .btn-seemore:hover .btn-svg {
    transform: rotate(-45deg); }
  .btn-seemore:hover .btn-seemore_link {
    color: #E50019; }
  .btn-seemore.nonupercase .btn-seemore_link {
    text-transform: capitalize; }
  .btn-seemore.bg {
    padding: 15px 25px;
    background-color: #1F6BB4;
    transition: .3s ease-in-out;
    position: relative; }
    .btn-seemore.bg.full-width {
      width: 100%;
      text-align: center;
      display: flex;
      justify-content: center; }
    .btn-seemore.bg:hover {
      background-color: #0C1E72; }
      .btn-seemore.bg:hover .btn-seemore_link {
        color: white; }
      .btn-seemore.bg:hover .btn-svg path {
        stroke: white; }
    .btn-seemore.bg .btn-seemore_link {
      color: white; }
    .btn-seemore.bg .btn-svg path {
      stroke: white; }
  .btn-seemore.red {
    padding: 15px 25px;
    background-color: #E50019;
    transition: .3s ease-in-out;
    position: relative; }
    .btn-seemore.red.full-width {
      text-align: center;
      display: flex;
      justify-content: center; }
    .btn-seemore.red:hover {
      background-color: #E50019; }
      .btn-seemore.red:hover .btn-seemore_link {
        color: white; }
      .btn-seemore.red:hover .btn-svg path {
        stroke: white; }
    .btn-seemore.red .btn-seemore_link {
      color: white; }
    .btn-seemore.red .btn-svg path {
      stroke: white; }
  .btn-seemore.calling {
    opacity: .6;
    pointer-events: none;
    cursor: initial;
    background-color: #0C1E72; }
    .btn-seemore.calling .btn-svg {
      transform: rotate(-45deg); }
    .btn-seemore.calling .btn-seemore_link {
      color: white; }

.input {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-left: -20px;
  margin-right: -20px;
  margin-top: -20px; }
  .input.ip-3 {
    margin-left: -10px;
    margin-right: -10px;
    margin-top: -20px; }
    .input.ip-3 .inputForm {
      width: 100%;
      padding-left: 10px;
      padding-right: 10px;
      padding-top: 20px; }
      @media (min-width: 768px) {
        .input.ip-3 .inputForm {
          width: 33.33333%; } }
  .input .inputForm {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 20px; }
    @media (min-width: 768px) {
      .input .inputForm {
        padding-right: 20px;
        width: 50%; } }
    .input .inputForm.full {
      width: 100%; }
      @media (min-width: 768px) {
        .input .inputForm.full {
          width: 100%; } }
    .input .inputForm > label {
      margin-bottom: 20px;
      font-size: 15px;
      color: #171717; }
      @media (min-width: 768px) {
        .input .inputForm > label {
          font-size: 1.6rem; } }
      @media (min-width: 1400px) {
        .input .inputForm > label {
          font-size: 1.8rem; } }
      .input .inputForm > label span {
        color: #B50006; }
    .input .inputForm > input {
      padding: 15px 25px;
      border-radius: 0;
      border: solid 1px #707070;
      font-size: 15px; }
      @media (min-width: 768px) {
        .input .inputForm > input {
          font-size: 1.5rem; } }
      @media (min-width: 1200px) {
        .input .inputForm > input {
          font-size: 1.6rem; } }
    .input .inputForm > textarea {
      padding: 15px 25px;
      border-radius: 0;
      border: solid 1px #707070;
      font-size: 15px; }
      @media (min-width: 768px) {
        .input .inputForm > textarea {
          font-size: 1.5rem; } }
      @media (min-width: 1200px) {
        .input .inputForm > textarea {
          font-size: 1.6rem; } }

.inputSubmit {
  width: 50%;
  padding-right: 20px; }
  .inputSubmit.btn-full button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%; }
  .inputSubmit.full-center {
    width: 100%;
    padding-right: 0px;
    display: flex;
    justify-content: center;
    align-items: center; }

.block-news {
  position: relative;
  display: flex;
  flex-direction: column; }
  @media (min-width: 576px) {
    .block-news {
      flex-direction: row; } }
  .block-news__img {
    flex: 0 0 100%;
    width: 100%;
    min-width: 260px;
    overflow: hidden;
    position: relative; }
    @media (min-width: 576px) {
      .block-news__img {
        flex: 0 0 40%;
        width: 40%; } }
    .block-news__img::after {
      position: absolute;
      bottom: -120%;
      left: 0;
      width: 100%;
      height: 100%;
      content: "";
      opacity: .3;
      transition: 0.7s cubic-bezier(0.52, 1.64, 0.37, 0.66);
      background-color: #707070; }
    .block-news__img img {
      transition: .5s ease; }
  .block-news:hover .block-news__img::after {
    bottom: 0; }
  .block-news:hover .block-news__img img {
    transform: scale(1.1); }
  .block-news:hover .block-news__decs-title {
    color: #00388E; }
  .block-news:hover .btn-svg {
    transform: rotate(-45deg); }
  .block-news:hover .btn-seemore_link {
    color: #E50019; }
  .block-news__decs {
    margin-top: 10px;
    margin-left: 0px;
    width: auto;
    display: flex;
    flex-direction: column;
    flex: 1; }
    @media (min-width: 576px) {
      .block-news__decs {
        margin-top: 0;
        margin-left: 20px; } }
    .block-news__decs > *:not(:last-child) {
      margin-bottom: 15px; }
      @media (max-width: 1024px) {
        .block-news__decs > *:not(:last-child) {
          margin-bottom: 10px; } }
    .block-news__decs-title {
      transition: .3s ease;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-transform: uppercase;
      line-height: 1.4;
      font-size: 2rem; }
      @media (min-width: 768px) {
        .block-news__decs-title {
          font-size: 2.2rem; } }
      @media (min-width: 1400px) {
        .block-news__decs-title {
          font-size: 2.4rem; } }
    .block-news__decs-time {
      display: inline-flex;
      align-items: center; }
      .block-news__decs-time .time {
        margin-left: 10px;
        color: #E50019;
        font-style: italic;
        font-size: 15px; }
        @media (min-width: 768px) {
          .block-news__decs-time .time {
            font-size: 1.6rem; } }
        @media (min-width: 1400px) {
          .block-news__decs-time .time {
            font-size: 1.8rem; } }
    .block-news__decs-infor {
      line-height: 1.3;
      display: -webkit-box;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
      overflow: hidden;
      font-size: 15px; }
      @media (min-width: 768px) {
        .block-news__decs-infor {
          font-size: 1.6rem; } }
      @media (min-width: 1400px) {
        .block-news__decs-infor {
          font-size: 1.8rem; } }
      @media (min-width: 1200px) {
        .block-news__decs-infor {
          display: -webkit-box;
          -webkit-line-clamp: 5;
          -webkit-box-orient: vertical;
          overflow: hidden; } }
    .block-news__decs-btn {
      position: relative;
      z-index: 2;
      margin-top: auto; }

.block-news-detail {
  position: relative;
  display: flex;
  flex-direction: column; }
  @media (min-width: 576px) {
    .block-news-detail {
      flex-direction: row; } }
  .block-news-detail__img {
    flex: 0 0 100%;
    width: 100%;
    min-width: 180px;
    overflow: hidden;
    position: relative; }
    @media (min-width: 576px) {
      .block-news-detail__img {
        flex: 0 0 40%;
        width: 40%; } }
    .block-news-detail__img::after {
      position: absolute;
      bottom: -120%;
      left: 0;
      width: 100%;
      height: 100%;
      content: "";
      opacity: .3;
      transition: 0.7s cubic-bezier(0.52, 1.64, 0.37, 0.66);
      background-color: #707070; }
    .block-news-detail__img img {
      transition: .5s ease; }
  .block-news-detail:hover .block-news__img::after {
    bottom: 0; }
  .block-news-detail:hover .block-news__img img {
    transform: scale(1.1); }
  .block-news-detail:hover .block-news__decs-title {
    color: #00388E; }
  .block-news-detail:hover .btn-svg {
    transform: rotate(-45deg); }
  .block-news-detail:hover .btn-seemore_link {
    color: #E50019; }
  .block-news-detail__decs {
    margin-top: 10px;
    margin-left: 0px;
    width: auto;
    display: flex;
    flex-direction: column;
    flex: 1; }
    @media (min-width: 576px) {
      .block-news-detail__decs {
        margin-top: 0;
        margin-left: 10px; } }
    .block-news-detail__decs > *:not(:last-child) {
      margin-bottom: 4px; }
      @media (min-width: 576px) {
        .block-news-detail__decs > *:not(:last-child) {
          margin-bottom: 4px; } }
    .block-news-detail__decs-title {
      transition: .3s ease;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-transform: uppercase;
      line-height: 1.4;
      font-size: 15px; }
      @media (min-width: 768px) {
        .block-news-detail__decs-title {
          font-size: 1.6rem; } }
      @media (min-width: 1400px) {
        .block-news-detail__decs-title {
          font-size: 1.8rem; } }
    .block-news-detail__decs-time {
      display: inline-flex;
      align-items: center; }
      .block-news-detail__decs-time .time {
        margin-left: 8px;
        color: #E50019;
        font-style: italic;
        font-size: 15px; }
        @media (min-width: 768px) {
          .block-news-detail__decs-time .time {
            font-size: 1.6rem; } }
        @media (min-width: 1400px) {
          .block-news-detail__decs-time .time {
            font-size: 1.8rem; } }
      .block-news-detail__decs-time svg {
        width: 14px;
        height: 14px; }
    .block-news-detail__decs-infor {
      line-height: 1.3;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      font-size: 15px; }
      @media (min-width: 768px) {
        .block-news-detail__decs-infor {
          font-size: 1.6rem; } }
      @media (min-width: 1400px) {
        .block-news-detail__decs-infor {
          font-size: 1.8rem; } }
      @media (min-width: 1200px) {
        .block-news-detail__decs-infor {
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          overflow: hidden; } }
    .block-news-detail__decs-btn {
      position: relative;
      z-index: 2;
      margin-top: auto; }

.nav-bar {
  max-width: 444px;
  margin: 0 auto; }
  @media (min-width: 1200px) {
    .nav-bar {
      max-width: 534px; } }
  .nav-bar__wrapper {
    width: 100%; }
  .nav-bar__img {
    margin-bottom: 50px; }
  .nav-bar__content--title {
    display: flex;
    justify-content: center;
    padding-bottom: 24px;
    border-bottom: 1px solid #707070; }
    .nav-bar__content--title > * {
      font-weight: 700;
      font-size: 3.2rem;
      text-transform: uppercase;
      color: #E50019; }
  .nav-bar__content--list {
    list-style-type: none; }
  .nav-bar__content--item {
    position: relative;
    transition: .5s linear;
    border-bottom: 1px solid #707070; }
    .nav-bar__content--item .nav-link {
      display: block;
      font-weight: 700;
      font-size: 1.8rem;
      text-transform: uppercase;
      color: #171717;
      padding: 18px 0px;
      transition: .3s linear; }
      @media (min-width: 768px) {
        .nav-bar__content--item .nav-link {
          font-size: 1.9rem; } }
      @media (min-width: 1400px) {
        .nav-bar__content--item .nav-link {
          font-size: 2rem; } }
    .nav-bar__content--item:hover .nav-link, .nav-bar__content--item.active .nav-link {
      color: #B50006; }
    .nav-bar__content--item:hover .flex-list > *, .nav-bar__content--item.active .flex-list > * {
      color: #B50006; }
    .nav-bar__content--item .flex-list {
      display: flex;
      position: relative;
      cursor: pointer; }
      .nav-bar__content--item .flex-list > * {
        font-weight: 700;
        font-size: 1.8rem;
        text-transform: uppercase;
        color: #171717;
        transition: .3s linear;
        display: block;
        padding: 18px 0px;
        transition: .3s linear; }
        @media (min-width: 768px) {
          .nav-bar__content--item .flex-list > * {
            font-size: 1.9rem; } }
        @media (min-width: 1400px) {
          .nav-bar__content--item .flex-list > * {
            font-size: 2rem; } }
      .nav-bar__content--item .flex-list:hover .btnDropdown {
        color: #E50019; }
      .nav-bar__content--item .flex-list .icons {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 4px;
        transition: 0.5s ease;
        transform-origin: center; }
      .nav-bar__content--item .flex-list.show .icons {
        transform: rotate(180deg); }
      .nav-bar__content--item .flex-list.show ~ .dropdownList {
        opacity: 1;
        height: auto;
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
        visibility: initial; }
    .nav-bar__content--item .dropdownList {
      list-style-type: none;
      opacity: 0;
      visibility: hidden;
      transition: all 0.3s linear;
      height: 0;
      clip-path: polygon(0 0, 100% 0, 100% 0, 0 0); }
    .nav-bar__content--item .dropdownItem > * {
      display: block;
      padding: 12px 0px;
      font-weight: 700;
      font-size: 1.8rem;
      text-transform: uppercase;
      color: #171717;
      transition: .3s ease; }
      @media (min-width: 768px) {
        .nav-bar__content--item .dropdownItem > * {
          font-size: 1.9rem; } }
      @media (min-width: 1400px) {
        .nav-bar__content--item .dropdownItem > * {
          font-size: 2rem; } }
    .nav-bar__content--item .dropdownItem:hover > *, .nav-bar__content--item .dropdownItem.active > * {
      color: #B50006; }

.post-1__content {
  margin-top: 16px; }
  @media (min-width: 768px) {
    .post-1__content {
      margin-top: 22px; } }
  .post-1__content > * {
    font-size: 1.6rem;
    text-align: center;
    color: #293D9C; }
  .post-1__content .font24 {
    font-size: 2.4rem;
    text-align: center;
    font-weight: 700;
    color: #171717; }

.post-2__wrapper {
  display: flex; }

.post-2__img {
  width: 15%;
  min-width: 150px;
  min-height: 141px; }
  @media (min-width: 576px) {
    .post-2__img {
      width: 20%;
      min-width: 200px; } }

.post-2__content {
  padding: 4px 0px 4px 12px;
  flex: 1; }
  @media (min-width: 576px) {
    .post-2__content {
      padding: 12px 0px 12px 25px; } }
  .post-2__content--title > * {
    text-transform: uppercase;
    font-size: 2.6rem;
    font-weight: 700;
    color: #1F6BB4; }
    @media (min-width: 768px) {
      .post-2__content--title > * {
        font-size: 2.8rem; } }
    @media (min-width: 1400px) {
      .post-2__content--title > * {
        font-size: 3.2rem; } }
  .post-2__content--download {
    margin-top: 24px; }
    @media (min-width: 768px) {
      .post-2__content--download {
        margin-top: 35px; } }
    .post-2__content--download > * {
      font-size: 2rem;
      font-weight: 700;
      color: #171717; }
      @media (min-width: 768px) {
        .post-2__content--download > * {
          font-size: 2.2rem; } }
      @media (min-width: 1200px) {
        .post-2__content--download > * {
          font-size: 2.4rem; } }

.post-2:hover .post-2__content--download > * {
  color: #B50006; }

.title-dgs__wrapper {
  display: flex;
  align-items: center; }

.title-dgs__content > * {
  font-size: 2.6rem;
  font-weight: 700;
  text-transform: uppercase;
  color: #B50006;
  text-align: center; }
  @media (min-width: 768px) {
    .title-dgs__content > * {
      font-size: 2.8rem; } }
  @media (min-width: 1400px) {
    .title-dgs__content > * {
      font-size: 3.2rem; } }

.title-dgs.bg .title-dgs__wrapper {
  padding: 15px 0;
  justify-content: center;
  width: 100%;
  background: #1F6BB4; }

.title-dgs.bg .title-dgs__content > * {
  color: white; }

.title-dgs.bg svg path {
  fill: white; }

.title-dsg__wrapper {
  display: flex;
  align-items: center; }

.title-dsg__content > * {
  font-size: 2.6rem;
  font-weight: 700;
  text-transform: uppercase;
  color: #B50006;
  text-align: center; }
  @media (min-width: 768px) {
    .title-dsg__content > * {
      font-size: 2.8rem; } }
  @media (min-width: 1400px) {
    .title-dsg__content > * {
      font-size: 3.2rem; } }

.title-dsg.bg .title-dsg__wrapper {
  padding: 15px 0;
  justify-content: center;
  width: 100%;
  background: #1F6BB4; }

.title-dsg.bg .title-dsg__content > * {
  color: white; }

.title-dsg.bg svg path {
  fill: white; }

.block-services {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column; }
  .block-services__img {
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    width: 100%;
    overflow: hidden;
    padding-bottom: 70%;
    position: relative;
    margin-bottom: 20px;
    transition: .5s ease; }
    .block-services__img .services-img {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0; }
    .block-services__img .services-hover {
      overflow: hidden;
      transition: .5s ease;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 0;
      background-color: white;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center; }
      .block-services__img .services-hover_img {
        flex: 2;
        display: flex;
        align-items: center;
        position: relative; }
        .block-services__img .services-hover_img::after {
          position: absolute;
          bottom: 15px;
          left: 50%;
          transform: translateX(-50%);
          background-color: #1F6BB4;
          content: "";
          width: 70%;
          height: 1px; }
        .block-services__img .services-hover_img img {
          height: 50%;
          object-fit: contain;
          opacity: 0; }
      .block-services__img .services-hover_decs {
        flex: 1;
        color: #1F6BB4;
        font-size: 15px;
        text-align: center; }
        @media (min-width: 768px) {
          .block-services__img .services-hover_decs {
            font-size: 1.6rem; } }
        @media (min-width: 1400px) {
          .block-services__img .services-hover_decs {
            font-size: 1.8rem; } }
    .block-services__img img {
      transition: .5s ease; }
  .block-services:hover .block-services__img .services-hover {
    height: 100%; }
    .block-services:hover .block-services__img .services-hover_img img {
      opacity: 1; }
  .block-services:hover .block-services__decs-title {
    color: #00388E; }
  .block-services:hover .btn-svg {
    transform: rotate(-45deg); }
  .block-services:hover .btn-seemore_link {
    color: #E50019; }
  .block-services__decs {
    height: 100%;
    width: auto;
    display: flex;
    flex-direction: column; }
    .block-services__decs > *:not(:last-child) {
      margin-bottom: 10px; }
    .block-services__decs-title {
      transition: .3s ease;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      font-size: 15px;
      line-height: 1.4; }
      @media (min-width: 768px) {
        .block-services__decs-title {
          font-size: 1.6rem; } }
      @media (min-width: 1400px) {
        .block-services__decs-title {
          font-size: 1.8rem; } }
    .block-services__decs-infor {
      line-height: 1.3;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden; }
    .block-services__decs-btn {
      position: relative;
      z-index: 2;
      margin-top: auto; }

.block-contact {
  width: 100%; }
  .block-contact__img {
    width: 100%;
    padding-bottom: 80%;
    position: relative; }
    .block-contact__img .contact-img {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0; }
  .block-contact__decs {
    flex: 1; }
    .block-contact__decs-title {
      padding: 16px 0;
      text-align: center;
      font-size: 2rem; }
      @media (min-width: 992px) {
        .block-contact__decs-title {
          padding: 25px 0; } }
      @media (min-width: 768px) {
        .block-contact__decs-title {
          font-size: 2.2rem; } }
      @media (min-width: 1200px) {
        .block-contact__decs-title {
          font-size: 2.4rem; } }
    .block-contact__decs-infor {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      padding: 12px 4px;
      background-color: #F7F6F6;
      font-size: 15px;
      text-align: center; }
      @media (min-width: 992px) {
        .block-contact__decs-infor {
          padding: 20px 4px; } }
      @media (min-width: 768px) {
        .block-contact__decs-infor {
          font-size: 1.6rem; } }
      @media (min-width: 1400px) {
        .block-contact__decs-infor {
          font-size: 1.8rem; } }
      .block-contact__decs-infor > *:not(:last-child) {
        margin-bottom: 5px; }

.block-address__header {
  position: relative;
  padding-bottom: 15px;
  margin-bottom: 15px; }
  .block-address__header::after {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 120px;
    content: "";
    height: 1px;
    background-color: #1F6BB4; }
  .block-address__header-office {
    font-size: 2rem;
    color: #707070;
    margin-bottom: 10px;
    display: flex;
    align-items: center; }
    @media (min-width: 768px) {
      .block-address__header-office {
        font-size: 2.2rem; } }
    @media (min-width: 1200px) {
      .block-address__header-office {
        font-size: 2.4rem; } }
    .block-address__header-office span {
      margin-left: 12px; }
  .block-address__header-name {
    font-size: 2rem; }
    @media (min-width: 768px) {
      .block-address__header-name {
        font-size: 2.2rem; } }
    @media (min-width: 1200px) {
      .block-address__header-name {
        font-size: 2.4rem; } }

.block-address__bottom > *:not(:last-child) {
  margin-bottom: 15px;
  font-size: 15px; }
  @media (min-width: 768px) {
    .block-address__bottom > *:not(:last-child) {
      font-size: 1.5rem; } }
  @media (min-width: 1200px) {
    .block-address__bottom > *:not(:last-child) {
      font-size: 1.6rem; } }

.block-address__bottom-items span {
  margin-left: 10px; }

.block-table_1 .table_1 {
  display: flex;
  flex-wrap: wrap;
  border-top: solid 1px rgba(112, 112, 112, 0.4);
  border-left: solid 1px rgba(112, 112, 112, 0.4);
  border-right: solid 1px rgba(112, 112, 112, 0.4); }
  @media (max-width: 540px) {
    .block-table_1 .table_1.scroll {
      overflow: scroll;
      flex-wrap: nowrap; } }
  @media (max-width: 540px) {
    .block-table_1 .table_1.horizontal {
      flex-direction: column;
      overflow: scroll; } }
  @media (max-width: 540px) {
    .block-table_1 .table_1.horizontal .table_1-col {
      flex-direction: row; } }
  @media (max-width: 540px) {
    .block-table_1 .table_1.horizontal .table_1-col:not(:last-child) {
      border-right: unset;
      border-bottom: solid 1px rgba(112, 112, 112, 0.4); } }
  @media (max-width: 540px) {
    .block-table_1 .table_1.horizontal .table-items {
      min-height: 80px;
      min-width: 100px; } }
  @media (max-width: 540px) {
    .block-table_1 .table_1.horizontal .table-items:not(:last-child) {
      border-bottom: unset;
      border-right: solid 1px rgba(112, 112, 112, 0.4); } }
  .block-table_1 .table_1-col {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    border-bottom: solid 1px rgba(112, 112, 112, 0.4); }
    .block-table_1 .table_1-col:not(:last-child) {
      border-right: solid 1px rgba(112, 112, 112, 0.4); }
    .block-table_1 .table_1-col.flex-0-5 {
      flex: 0.5; }
    .block-table_1 .table_1-col.flex-1-5 {
      flex: 1.5; }
    .block-table_1 .table_1-col.flex-2 {
      flex: 2; }
    .block-table_1 .table_1-col.flex-2-5 {
      flex: 2.5; }
    .block-table_1 .table_1-col.flex-3 {
      flex: 3; }
    .block-table_1 .table_1-col.flex-3-5 {
      flex: 3.5; }
    .block-table_1 .table_1-col.bold .table-items {
      font-weight: 700; }
    .block-table_1 .table_1-col.bg-white .table-items {
      background-color: white; }
  .block-table_1 .table_1 .table-items {
    min-width: 60px;
    width: 100%;
    background-color: #F5F5F5;
    min-height: 60px;
    max-height: 60px;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center; }
    @media (max-width: 540px) {
      .block-table_1 .table_1 .table-items {
        min-width: 120px; } }
    @media (max-width: 480px) {
      .block-table_1 .table_1 .table-items {
        min-width: 70px; } }
    .block-table_1 .table_1 .table-items.bg-blue {
      background-color: #1F6BB4;
      color: white;
      min-height: 80px;
      font-weight: 700; }
    .block-table_1 .table_1 .table-items.bg-light {
      min-height: 80px;
      background-color: #D8E5F2;
      font-weight: 700; }
    .block-table_1 .table_1 .table-items.bg-none {
      font-weight: 700;
      text-transform: uppercase; }
    .block-table_1 .table_1 .table-items.bold {
      font-weight: 700; }
    .block-table_1 .table_1 .table-items:not(:last-child) {
      border-bottom: solid 1px rgba(112, 112, 112, 0.4); }

.table_title {
  text-align: center;
  padding: 20px 0;
  font-size: 15px;
  color: white;
  background-color: #1F6BB4; }
  @media (min-width: 768px) {
    .table_title {
      font-size: 1.6rem; } }
  @media (min-width: 1400px) {
    .table_title {
      font-size: 1.8rem; } }

.block-table_2 {
  overflow: scroll; }
  .block-table_2::-webkit-scrollbar {
    display: none; }
  .block-table_2 .table_2 {
    width: 100%; }
    .block-table_2 .table_2 tr td, .block-table_2 .table_2 tr th {
      min-width: 40px;
      padding: 15px 4px;
      text-align: center;
      background-color: #F5F5F5; }
      @media (min-width: 992px) {
        .block-table_2 .table_2 tr td, .block-table_2 .table_2 tr th {
          padding: 15px 10px; } }
      .block-table_2 .table_2 tr td.bg-blue, .block-table_2 .table_2 tr th.bg-blue {
        background-color: #1F6BB4;
        color: white;
        min-height: 80px;
        font-weight: 700; }
      .block-table_2 .table_2 tr td.f-30, .block-table_2 .table_2 tr th.f-30 {
        width: 30%; }
      .block-table_2 .table_2 tr td.f-35, .block-table_2 .table_2 tr th.f-35 {
        width: 35%; }
      .block-table_2 .table_2 tr td.f-40, .block-table_2 .table_2 tr th.f-40 {
        width: 40%; }
      .block-table_2 .table_2 tr td.f-45, .block-table_2 .table_2 tr th.f-45 {
        width: 45%; }
      .block-table_2 .table_2 tr td.f-50, .block-table_2 .table_2 tr th.f-50 {
        width: 50%; }
      .block-table_2 .table_2 tr td.bold, .block-table_2 .table_2 tr th.bold {
        font-weight: 700; }
      .block-table_2 .table_2 tr td.text, .block-table_2 .table_2 tr th.text {
        min-width: 180px; }
  .block-table_2 table, .block-table_2 th, .block-table_2 td {
    border: solid 1px rgba(112, 112, 112, 0.4);
    border-collapse: collapse; }

.block-table_3 .table_img-content {
  text-align: center;
  padding: 10px 0;
  font-size: 15px;
  color: white;
  background-color: #1F6BB4; }
  @media (min-width: 768px) {
    .block-table_3 .table_img-content {
      font-size: 1.6rem; } }
  @media (min-width: 1400px) {
    .block-table_3 .table_img-content {
      font-size: 1.8rem; } }

.block-table_3 .table_img-image img {
  display: block; }

.block-table_3 .table_3 {
  border-top: solid 1px rgba(112, 112, 112, 0.4);
  border-left: solid 1px rgba(112, 112, 112, 0.4); }
  .block-table_3 .table_3-row {
    display: flex;
    height: 100%;
    border-bottom: solid 1px rgba(112, 112, 112, 0.4);
    background-color: #F5F5F5; }
    .block-table_3 .table_3-row .table-items {
      border-right: solid 1px rgba(112, 112, 112, 0.4);
      height: 100%; }
      .block-table_3 .table_3-row .table-items > *:not(:last-child) {
        border-bottom: solid 1px rgba(112, 112, 112, 0.4); }
    .block-table_3 .table_3-row .items {
      text-align: center;
      padding: 10px 0; }
    .block-table_3 .table_3-row .head {
      padding-left: 10px;
      padding-right: 10px;
      min-width: 120px;
      display: flex;
      align-items: center;
      border-right: solid 1px rgba(112, 112, 112, 0.4); }
      @media (min-width: 992px) {
        .block-table_3 .table_3-row .head {
          padding-left: 60px; } }
      .block-table_3 .table_3-row .head > * {
        font-weight: 700; }
    .block-table_3 .table_3-row .infor {
      flex: 1;
      display: flex;
      align-items: center; }

.table-detail {
  margin-top: 32px; }
  @media (min-width: 768px) {
    .table-detail {
      margin-top: 46px; } }
  .table-detail__head > * {
    font-weight: 700;
    font-size: 15px;
    color: #171717; }
    @media (min-width: 768px) {
      .table-detail__head > * {
        font-size: 1.5rem; } }
    @media (min-width: 1200px) {
      .table-detail__head > * {
        font-size: 1.6rem; } }
  .table-detail__img {
    margin-top: 18px; }
    @media (min-width: 768px) {
      .table-detail__img {
        margin-top: 16px; } }
    .table-detail__img img {
      width: auto;
      height: auto;
      max-width: 100%;
      max-height: 220px; }
  .table-detail__system {
    margin-top: 32px; }
    @media (min-width: 768px) {
      .table-detail__system {
        margin-top: 24px; } }
    .table-detail__system .system {
      border: 1px solid #707070;
      border-collapse: collapse; }
      .table-detail__system .system tr {
        display: flex;
        flex-direction: column; }
        .table-detail__system .system tr > * {
          padding: 10px 20px;
          text-align: center;
          border-collapse: collapse;
          border: 1px solid #707070;
          font-size: 15px;
          color: #171717; }
          @media (min-width: 768px) {
            .table-detail__system .system tr > * {
              font-size: 1.5rem; } }
          @media (min-width: 1200px) {
            .table-detail__system .system tr > * {
              font-size: 1.6rem; } }
        .table-detail__system .system tr th {
          font-weight: 700; }
          .table-detail__system .system tr th:not(:first-child) {
            display: none; }
            @media (min-width: 576px) {
              .table-detail__system .system tr th:not(:first-child) {
                display: table-cell; } }
        .table-detail__system .system tr td:first-child {
          font-weight: 700; }
        @media (min-width: 576px) {
          .table-detail__system .system tr {
            display: table-row;
            flex-direction: initial; } }
  .table-detail__desc {
    margin-top: 20px; }
    @media (min-width: 768px) {
      .table-detail__desc {
        margin-top: 16px; } }
    .table-detail__desc > * {
      font-weight: 400;
      font-size: 15px;
      color: #171717; }
      @media (min-width: 768px) {
        .table-detail__desc > * {
          font-size: 1.5rem; } }
      @media (min-width: 1200px) {
        .table-detail__desc > * {
          font-size: 1.6rem; } }

.box-1 {
  background: #D8E5F2;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  transition: all 0.3s linear;
  position: relative;
  height: 100%;
  padding: 8px; }
  .box-1__content {
    padding: 30px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center; }
  .box-1__title {
    margin-top: 30px; }
    .box-1__title > * {
      font-weight: 700;
      font-size: 2rem;
      text-transform: uppercase;
      color: #171717;
      transition: all 0.3s linear; }
      @media (min-width: 768px) {
        .box-1__title > * {
          font-size: 2.2rem; } }
      @media (min-width: 1400px) {
        .box-1__title > * {
          font-size: 2.4rem; } }
  .box-1__infor {
    margin-top: 24px; }
    .box-1__infor > * {
      font-size: 15px;
      color: #171717;
      transition: all 0.3s linear; }
      @media (min-width: 768px) {
        .box-1__infor > * {
          font-size: 1.5rem; } }
      @media (min-width: 1200px) {
        .box-1__infor > * {
          font-size: 1.6rem; } }
  .box-1:hover {
    background: #1F6BB4; }
    .box-1:hover .box-1__title > * {
      color: #FFFFFF; }
    .box-1:hover .box-1__infor > * {
      color: #FFFFFF; }

.option-form__label {
  margin-bottom: 20px;
  font-size: 15px;
  color: #171717; }
  @media (min-width: 768px) {
    .option-form__label {
      font-size: 1.6rem; } }
  @media (min-width: 1400px) {
    .option-form__label {
      font-size: 1.8rem; } }
  .option-form__label span {
    color: #B50006; }

.option-form-select {
  position: relative; }
  .option-form-select .option-active {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    border: 1px solid #707070;
    padding: 20px 10px 20px 20px;
    font-size: 15px;
    color: #171717; }
    @media (min-width: 768px) {
      .option-form-select .option-active {
        font-size: 1.5rem; } }
    @media (min-width: 1200px) {
      .option-form-select .option-active {
        font-size: 1.6rem; } }
    .option-form-select .option-active .span {
      flex: 1; }
    .option-form-select .option-active .span, .option-form-select .option-active .svg {
      user-select: none;
      pointer-events: none;
      transition: .5s; }
    .option-form-select .option-active.clicked ~ .option-drops {
      height: auto;
      opacity: 1; }
    .option-form-select .option-active.clicked .svg {
      transform: rotate(180deg); }
  .option-form-select .option-drops {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 11;
    width: 100%;
    max-height: 180px;
    overflow-y: auto;
    font-size: 15px;
    color: #171717;
    background-color: #F5F5F5;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    transition: 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    height: 0;
    opacity: 0; }
    @media (min-width: 768px) {
      .option-form-select .option-drops {
        font-size: 1.6rem; } }
    @media (min-width: 1400px) {
      .option-form-select .option-drops {
        font-size: 1.8rem; } }
    .option-form-select .option-drops__item {
      padding: 20px 10px 20px 20px; }

.block-dictionary .dictionary-alpha {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 0 -3px;
  margin-bottom: 40px; }
  .block-dictionary .dictionary-alpha_items {
    min-width: 50px;
    margin: 0 3px;
    padding: 5px 15px;
    text-align: center;
    background-color: #1F6BB4;
    cursor: pointer;
    color: white;
    transition: .2s ease;
    border-radius: 10px;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 15px;
    margin-bottom: 5px; }
    @media (min-width: 768px) {
      .block-dictionary .dictionary-alpha_items {
        font-size: 1.6rem; } }
    @media (min-width: 1400px) {
      .block-dictionary .dictionary-alpha_items {
        font-size: 1.8rem; } }
    .block-dictionary .dictionary-alpha_items:hover, .block-dictionary .dictionary-alpha_items.active {
      background-color: #B50006; }

.block-dictionary .dictionary-words {
  display: none;
  overflow: visible; }
  .block-dictionary .dictionary-words.active {
    display: block;
    animation: fadeUp 1 500ms; }

@keyframes fadeUp {
  0% {
    transform: translateY(100px); }
  100% {
    transform: translateY(0px); } }
  .block-dictionary .dictionary-words_items:not(:first-child) {
    margin-top: 60px; }
  .block-dictionary .dictionary-words_items > *:not(:first-child) {
    margin-top: 5px; }

.block_index-news {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: white; }
  .block_index-news__img {
    width: 100%;
    overflow: hidden;
    position: relative;
    padding-top: 62.5%;
    height: 0; }
    .block_index-news__img > img {
      position: absolute;
      top: 0;
      left: 0; }
    .block_index-news__img::after {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      content: "";
      opacity: 0;
      transition: .8s ease;
      background-color: #0C1E72;
      mix-blend-mode: multiply;
      z-index: 1; }
    .block_index-news__img img {
      transition: .8s ease; }
    .block_index-news__img-hover {
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%) scale(1.3);
      left: 50%;
      width: 30%;
      opacity: 0;
      transition: .8s ease;
      z-index: 2; }
  .block_index-news:hover .block_index-news__img::after {
    opacity: .6; }
  .block_index-news:hover .block_index-news__img img {
    transform: scale(1.2); }
  .block_index-news:hover .block_index-news__img-hover {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1); }
  .block_index-news:hover .block_index-news__decs-title {
    color: #00388E; }
  .block_index-news:hover .btn-svg {
    transform: rotate(-45deg); }
  .block_index-news:hover .btn-seemore_link {
    color: #E50019; }
  .block_index-news__decs {
    height: 100%;
    margin-left: unset;
    width: auto;
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 25px 20px; }
    @media (max-width: 1024px) {
      .block_index-news__decs {
        padding: 15px; } }
    .block_index-news__decs > *:not(:last-child) {
      margin-bottom: 16px; }
      @media (max-width: 1280px) {
        .block_index-news__decs > *:not(:last-child) {
          margin-bottom: 10px; } }
      @media (max-width: 1024px) {
        .block_index-news__decs > *:not(:last-child) {
          margin-bottom: 8px; } }
    .block_index-news__decs-title {
      transition: .3s ease;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-transform: uppercase;
      line-height: 1.4;
      font-size: 2rem; }
      @media (max-width: 1280px) {
        .block_index-news__decs-title {
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          overflow: hidden; } }
      @media (min-width: 768px) {
        .block_index-news__decs-title {
          font-size: 2.2rem; } }
      @media (min-width: 1400px) {
        .block_index-news__decs-title {
          font-size: 2.4rem; } }
    .block_index-news__decs-time {
      display: inline-flex;
      align-items: center; }
      .block_index-news__decs-time .time {
        margin-left: 10px;
        color: #171717;
        font-style: italic;
        font-size: 15px; }
        @media (min-width: 768px) {
          .block_index-news__decs-time .time {
            font-size: 1.6rem; } }
        @media (min-width: 1400px) {
          .block_index-news__decs-time .time {
            font-size: 1.8rem; } }
    .block_index-news__decs-infor {
      line-height: 1.3;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      font-size: 15px; }
      @media (min-width: 768px) {
        .block_index-news__decs-infor {
          font-size: 1.6rem; } }
      @media (min-width: 1400px) {
        .block_index-news__decs-infor {
          font-size: 1.8rem; } }
      @media (min-width: 1200px) {
        .block_index-news__decs-infor {
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          overflow: hidden; } }
    .block_index-news__decs-btn {
      position: relative;
      z-index: 2;
      margin-top: auto; }

.box-2 {
  display: flex;
  flex-direction: column;
  cursor: pointer; }
  .box-2:hover .box-2__img::after {
    height: 100%; }
  .box-2__img {
    width: 100%;
    height: 0;
    position: relative;
    padding-top: 100%; }
    .box-2__img img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%; }
    .box-2__img::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 0%;
      z-index: 1;
      background-color: #1F6BB4;
      opacity: 0.5;
      transition: 0.5s ease;
      transform-origin: top; }
  .box-2__album {
    padding: 16px 8px;
    width: 100%;
    text-align: center; }
    @media (min-width: 768px) {
      .box-2__album {
        padding: 20px 12px; } }
    .box-2__album > * {
      font-size: 2rem;
      font-weight: 700;
      color: #171717;
      transition: .5s ease; }
      @media (min-width: 768px) {
        .box-2__album > * {
          font-size: 2.2rem; } }
      @media (min-width: 1400px) {
        .box-2__album > * {
          font-size: 2.4rem; } }
  .box-2__video {
    width: 100%;
    height: 0;
    position: relative;
    padding-top: 100%; }
    .box-2__video img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%; }
    .box-2__video--play {
      position: absolute;
      top: 50%;
      left: 50%;
      z-index: 1;
      transition: 0.5s ease;
      transform-origin: center;
      transform: translate(-50%, -50%) scale(1);
      opacity: 1; }
      @media (min-width: 992px) {
        .box-2__video--play {
          transform: translate(-50%, -50%) scale(0);
          opacity: 0.5; } }
      .box-2__video--play svg {
        width: 55px;
        height: 46px; }
        @media (min-width: 576px) {
          .box-2__video--play svg {
            width: 90px;
            height: 76px; } }
        @media (min-width: 992px) {
          .box-2__video--play svg {
            width: 130px;
            height: 110px; } }
  .box-2[data-box="video"]:hover .box-2__video--play {
    transform: translate(-50%, -50%) scale(1);
    opacity: 1; }
  .box-2[data-box="video"]:hover .box-2__album > * {
    color: #B50006; }

.section-popup {
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  transition: .5s ease;
  clip-path: polygon(0 0, 100% 0, 100% 0, 0 0); }
  .section-popup.show {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%); }
  .section-popup::after {
    z-index: -2;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(19, 60, 99, 0.95); }
  .section-popup .popup-video {
    height: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap; }
    .section-popup .popup-video-wrapper {
      position: relative;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column; }
      .section-popup .popup-video-wrapper iframe {
        width: 100%;
        height: 70%;
        position: relative;
        z-index: 1; }
      .section-popup .popup-video-wrapper .popup-video-photo {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100%;
        height: 70%;
        transform: translate(-50%, -50%);
        z-index: 0; }
  .section-popup .popup-album {
    height: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap; }
    .section-popup .popup-album-wrapper {
      position: relative;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column; }
      @media (min-width: 1200px) {
        .section-popup .popup-album-wrapper {
          flex: 0 0 auto;
          width: 58.33333%; } }
  .section-popup .popup-album-swiper {
    position: relative;
    width: 100%;
    height: 50%; }
    .section-popup .popup-album-swiper .swiper {
      width: 100%;
      height: 100%; }
  .section-popup .popup-album-thumbs {
    width: 100%;
    max-height: 20%;
    margin-top: 5%; }
  .section-popup .popup-close {
    position: absolute;
    top: 0;
    right: 0;
    width: 25px;
    height: 25px;
    transform: translateY(calc(-100% - 20px));
    cursor: pointer; }
    .section-popup .popup-close .line {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      height: 4px;
      border-radius: 4px;
      background-color: #707070;
      transition: .5s ease; }
      .section-popup .popup-close .line:first-child {
        transform: translate(-50%, -50%) rotate(45deg); }
      .section-popup .popup-close .line:last-child {
        transform: translate(-50%, -50%) rotate(-45deg); }
    .section-popup .popup-close:hover .line:first-child {
      transform: translate(-50%, -50%) rotate(-45deg); }
    .section-popup .popup-close:hover .line:last-child {
      transform: translate(-50%, -50%) rotate(45deg); }

#footer {
  background-color: #1F6BB4; }
  #footer .copyright {
    background-color: #00529B;
    width: 100%;
    padding: 15px 0; }
    @media (max-width: 480px) {
      #footer .copyright {
        padding: 10px 0; } }
    #footer .copyright-name {
      color: white;
      text-align: center;
      font-size: 15px;
      font-weight: 700; }
      @media (min-width: 768px) {
        #footer .copyright-name {
          font-size: 1.6rem; } }
      @media (min-width: 1400px) {
        #footer .copyright-name {
          font-size: 1.8rem; } }
  #footer .footer {
    display: flex;
    flex-wrap: wrap;
    margin-top: -40px;
    padding: 28px 0; }
    #footer .footer-block {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      text-align: center;
      flex: 0 0 100%;
      width: 100%;
      padding-right: 0px;
      padding-top: 40px; }
      @media (min-width: 576px) {
        #footer .footer-block {
          text-align: left;
          align-items: initial; } }
      @media (min-width: 576px) {
        #footer .footer-block {
          flex: 0 0 50%;
          width: 50%;
          padding-right: 20px; }
          #footer .footer-block:nth-of-type(2n) {
            padding-right: 0; } }
      @media (min-width: 992px) {
        #footer .footer-block {
          padding-right: 30px;
          flex: 0 0 calc(100% /4);
          width: calc(100% /4); }
          #footer .footer-block:nth-of-type(2n) {
            padding-right: 30px; }
          #footer .footer-block:nth-of-type(4) {
            padding-right: 0; } }
      @media (min-width: 1200px) {
        #footer .footer-block {
          padding-right: 12px;
          flex: 0 0 calc((100% - 250px )/4);
          width: calc((100% - 250px )/4); }
          #footer .footer-block:nth-of-type(2n) {
            padding-right: 12px; }
          #footer .footer-block:nth-of-type(4) {
            padding-right: 12px; } }
      @media (min-width: 1400px) {
        #footer .footer-block {
          padding-right: 30px;
          flex: 0 0 calc((100% - 250px )/4);
          width: calc((100% - 250px )/4); }
          #footer .footer-block:nth-of-type(2n) {
            padding-right: 30px; }
          #footer .footer-block:nth-of-type(4) {
            padding-right: 30px; } }
      @media (min-width: 1680px) {
        #footer .footer-block {
          padding-right: 60px; } }
      #footer .footer-block.map-block {
        flex: 0 0 100%;
        width: 100%;
        min-height: 400px;
        padding-right: 0; }
        @media (min-width: 1200px) {
          #footer .footer-block.map-block {
            width: 250px;
            flex: 0 0 250px;
            min-height: initial; } }
        #footer .footer-block.map-block .footer-block_items {
          height: 100%;
          width: 100%; }
      #footer .footer-block_items {
        display: flex;
        flex-direction: column;
        width: 100%; }
        #footer .footer-block_items > *:not(:first-child) {
          margin-top: 20px; }
        #footer .footer-block_items:not(:first-child) {
          margin-top: 40px; }
    #footer .footer .mt-auto {
      margin-top: auto; }
    #footer .footer-title {
      font-size: 17px;
      font-weight: 700;
      text-transform: uppercase;
      color: white; }
      @media (min-width: 768px) {
        #footer .footer-title {
          font-size: 1.7rem; } }
      @media (min-width: 1200px) {
        #footer .footer-title {
          font-size: 1.9rem; } }
    #footer .footer-infor > * {
      font-weight: 700;
      color: white;
      transition: .3s ease; }
    #footer .footer-infor a:hover {
      color: #E50019; }
    #footer .footer-infor span {
      margin-left: 5px;
      font-weight: 400; }
      @media (min-width: 1400px) {
        #footer .footer-infor span {
          margin-left: 5px; } }
    #footer .footer-secure img {
      width: auto;
      height: auto; }
    #footer .footer-link {
      display: flex;
      height: 37px;
      justify-content: center; }
      @media (min-width: 576px) {
        #footer .footer-link {
          justify-content: initial; } }
      #footer .footer-link__img {
        height: 100%; }
        #footer .footer-link__img img {
          object-fit: contain; }
    #footer .footer-member {
      height: 37px;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      max-width: 100%;
      margin-left: auto;
      margin-right: auto; }
      @media (min-width: 576px) {
        #footer .footer-member {
          justify-content: initial; } }
      @media (min-width: 576px) {
        #footer .footer-member {
          margin-left: initial;
          margin-right: initial; } }
      #footer .footer-member__img:not(:first-child) {
        margin-left: 5px; }
      #footer .footer-member__img img {
        width: 37px;
        height: 37px;
        object-fit: cover; }
    #footer .footer-svg {
      margin-right: 5px; }
      #footer .footer-svg svg path {
        transition: .2s ease; }
      #footer .footer-svg:hover svg path {
        fill: #E50019; }
    #footer .footer-img {
      max-width: 150px;
      margin-right: 5px;
      margin-top: 8px; }
      #footer .footer-img img {
        width: auto;
        object-fit: contain; }
    #footer .footer-select {
      position: relative;
      max-width: 100%;
      width: 100%; }
      @media (min-width: 576px) {
        #footer .footer-select {
          min-width: 220px;
          width: auto; } }
      #footer .footer-select::after {
        content: "";
        position: absolute;
        width: 0;
        height: 0;
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-top: 12px solid #B50006;
        top: 50%;
        right: 15px;
        transform: translateY(-50%);
        z-index: 1;
        pointer-events: none; }
      #footer .footer-select #footerSelect {
        height: 37px;
        border: none;
        appearance: none;
        padding: 0 15px;
        color: #707070;
        width: 100%; }

@keyframes fixedHeader {
  0% {
    top: 0%;
    transform: translateY(-100%); }
  100% {
    top: 0;
    transform: translateY(0%);
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16); } }

@keyframes bounceInUp {
  0% {
    transform: translateY(2000px); }
  60% {
    transform: translateY(-30px); }
  80% {
    transform: translateY(10px); }
  100% {
    transform: translateY(0); } }

#header {
  background-color: white; }
  #header.scroll {
    top: 0%;
    left: 0;
    position: fixed;
    width: 100%;
    z-index: 999;
    transform: translateY(-100%);
    animation: fixedHeader 1.5s ease forwards; }

.header {
  max-width: 100%;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  position: relative;
  overflow: initial; }
  @media (min-width: 992px) {
    .header {
      box-shadow: initial; } }
  .header-container {
    display: flex;
    overflow: initial; }
  .header-hamburger {
    position: absolute;
    top: 50%;
    left: 2%;
    transform: translateY(-50%);
    z-index: 1;
    width: 32px;
    height: 30px;
    background-color: transparent;
    cursor: pointer; }
    @media (min-width: 992px) {
      .header-hamburger {
        display: none; } }
    .header-hamburger .line {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      height: 4px;
      background-color: #707070;
      border-radius: 4px;
      transition: .3s all linear; }
      .header-hamburger .line:first-child {
        top: 10%;
        transform: translate(-50%, 0); }
      .header-hamburger .line:last-child {
        bottom: 10%;
        top: auto;
        transform: translate(-50%, 0); }
    .header-hamburger.active .line:not(:first-child):not(:last-child) {
      display: none; }
    .header-hamburger.active .line:first-child {
      top: 50%;
      transform: translate(-50%, -50%) rotate(45deg); }
    .header-hamburger.active .line:last-child {
      bottom: auto;
      top: 50%;
      transform: translate(-50%, -50%) rotate(-45deg); }
    .header-hamburger.active ~ .header-nav-mobile {
      opacity: 1;
      transform: translate(0%, 0); }
  .header-nav-mobile {
    position: absolute;
    top: 100%;
    left: 0;
    height: calc(100vh - 64px);
    opacity: 0;
    transform: translate(-100%, 0);
    transition: .3s all linear;
    background-color: #FFFFFF;
    z-index: 999;
    width: 100%;
    max-width: 100%;
    overflow: auto;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16); }
    @media (min-width: 576px) {
      .header-nav-mobile {
        width: 450px; } }
    @media (min-width: 992px) {
      .header-nav-mobile {
        display: none; } }
    .header-nav-mobile .nav {
      border-top: 1px solid #707070;
      padding: 0 24px; }
      .header-nav-mobile .nav__item .search {
        padding-top: 12px;
        margin-left: 20px;
        flex: 1;
        display: flex;
        border-bottom: 1px solid #707070; }
        .header-nav-mobile .nav__item .search input {
          flex: 1;
          background: transparent;
          border: none;
          outline: none;
          color: #171717;
          font-weight: 400;
          padding: 4px 4px 4px 10px;
          font-family: "Roboto", sans-serif;
          font-size: 15px; }
          .header-nav-mobile .nav__item .search input:focus {
            outline: none;
            border: none; }
          .header-nav-mobile .nav__item .search input::placeholder {
            color: #171717; }
        .header-nav-mobile .nav__item .search button {
          display: block;
          background: transparent;
          border: none;
          outline: none; }
          .header-nav-mobile .nav__item .search button svg .search-btn-item {
            stroke: #171717;
            opacity: .3; }
          .header-nav-mobile .nav__item .search button:hover svg .search-btn-item {
            opacity: 1; }
          .header-nav-mobile .nav__item .search button:focus {
            outline: none;
            border: none;
            opacity: .9; }
          .header-nav-mobile .nav__item .search button:hover {
            opacity: .9; }
      .header-nav-mobile .nav__item .languages {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        padding: 12px 0;
        margin: -4px -6px 0 -6px; }
        .header-nav-mobile .nav__item .languages > * {
          padding: 4px 6px 0 6px; }
        .header-nav-mobile .nav__item .languages .choose {
          color: #171717;
          font-size: 15px; }
        .header-nav-mobile .nav__item .languages-list {
          flex: 1;
          display: flex;
          flex-direction: row;
          list-style-type: none;
          align-items: center; }
        .header-nav-mobile .nav__item .languages-item {
          width: 32px;
          height: 22px;
          cursor: pointer;
          position: relative; }
          .header-nav-mobile .nav__item .languages-item:not(:first-child) {
            margin-left: 12px; }
          .header-nav-mobile .nav__item .languages-item::after {
            content: "";
            position: absolute;
            bottom: 0%;
            left: 0%;
            width: 100%;
            height: 100%;
            background-color: #1B2335;
            opacity: 0.5;
            transition: .3s ease; }
          .header-nav-mobile .nav__item .languages-item:hover::after, .header-nav-mobile .nav__item .languages-item.active::after {
            height: 0%;
            opacity: 0; }
      .header-nav-mobile .nav__item .menu {
        display: flex;
        flex-direction: column;
        list-style: none; }
        .header-nav-mobile .nav__item .menu-item {
          border-top: 1px solid #707070; }
          .header-nav-mobile .nav__item .menu-item > a {
            cursor: pointer;
            padding: 12px 0;
            color: #171717;
            font-weight: 700;
            text-transform: uppercase;
            font-size: 15px;
            display: flex;
            flex-direction: row;
            align-items: center; }
            .header-nav-mobile .nav__item .menu-item > a span {
              flex: 1; }
            .header-nav-mobile .nav__item .menu-item > a svg {
              transition: .5s ease; }
          .header-nav-mobile .nav__item .menu-item:focus > a, .header-nav-mobile .nav__item .menu-item:hover > a, .header-nav-mobile .nav__item .menu-item.active > a {
            color: #B50006; }
          .header-nav-mobile .nav__item .menu-item__sub {
            height: 0;
            opacity: 0;
            transition: all .5s ease;
            clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
            visibility: hidden; }
          .header-nav-mobile .nav__item .menu-item.clicked a svg {
            transform: rotate(180deg); }
          .header-nav-mobile .nav__item .menu-item.clicked .menu-item__sub {
            opacity: 1;
            height: auto;
            clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
            visibility: initial; }
          .header-nav-mobile .nav__item .menu-item__sub ul {
            list-style-type: none; }
            .header-nav-mobile .nav__item .menu-item__sub ul li.active > a {
              color: #B50006; }
            .header-nav-mobile .nav__item .menu-item__sub ul li a {
              padding: 12px 0;
              color: #171717;
              font-weight: 700;
              text-transform: uppercase;
              font-size: 15px;
              display: flex;
              flex-direction: row;
              align-items: center; }
              .header-nav-mobile .nav__item .menu-item__sub ul li a span {
                flex: 1; }
              .header-nav-mobile .nav__item .menu-item__sub ul li a svg {
                transition: all .5s ease; }
            .header-nav-mobile .nav__item .menu-item__sub ul li ul {
              height: 0;
              opacity: 0;
              transition: all .5s ease;
              clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
              visibility: hidden; }
            .header-nav-mobile .nav__item .menu-item__sub ul li.clicked a svg {
              transform: rotate(180deg); }
            .header-nav-mobile .nav__item .menu-item__sub ul li.clicked ul {
              opacity: 1;
              height: auto;
              clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
              visibility: initial; }
  .header-logo {
    position: relative;
    height: 48px;
    margin: 8px auto; }
    .header-logo svg {
      height: 48px;
      width: 140px; }
    @media (min-width: 992px) {
      .header-logo {
        height: 72px;
        margin-top: 12px;
        margin-bottom: 12px; }
        .header-logo svg {
          height: 72px;
          width: 176px; } }
    @media (min-width: 1200px) {
      .header-logo {
        height: 86px;
        margin-top: 19px;
        margin-bottom: 19px; }
        .header-logo svg {
          width: 248px;
          height: 86px; } }
  .header-nav {
    display: none; }
    @media (min-width: 992px) {
      .header-nav {
        flex: 1;
        display: flex;
        flex-direction: column; } }
    .header-nav-top {
      background-color: #1F6BB4;
      width: 50%;
      margin-left: 50%;
      padding: 15px 0;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      position: relative; }
      .header-nav-top .search {
        flex: 1;
        display: flex; }
        .header-nav-top .search input {
          flex: 1;
          background: transparent;
          border: none;
          outline: none;
          color: white;
          font-weight: 700;
          padding: 4px;
          font-family: "Roboto", sans-serif; }
          .header-nav-top .search input:focus {
            outline: none;
            border: none; }
          .header-nav-top .search input::placeholder {
            color: white; }
        .header-nav-top .search button {
          display: block;
          background: transparent;
          border: none;
          outline: none; }
          .header-nav-top .search button:focus {
            outline: none;
            border: none;
            opacity: .9; }
          .header-nav-top .search button:hover {
            opacity: .9; }
      .header-nav-top .languages {
        display: flex;
        flex-direction: row;
        list-style-type: none;
        align-items: center; }
        .header-nav-top .languages-item {
          width: 32px;
          height: 22px;
          cursor: pointer;
          position: relative; }
          .header-nav-top .languages-item:not(:first-child) {
            margin-left: 12px; }
          .header-nav-top .languages-item::after {
            content: "";
            position: absolute;
            bottom: 0%;
            left: 0%;
            width: 100%;
            height: 100%;
            background-color: #1B2335;
            opacity: 0.5;
            transition: .3s ease; }
          .header-nav-top .languages-item:hover::after, .header-nav-top .languages-item.active::after {
            height: 0%;
            opacity: 0; }
      .header-nav-top > * {
        padding-left: 30px;
        margin-left: 30px;
        border-left: 2px solid white; }
      .header-nav-top::before {
        content: "";
        position: absolute;
        top: 0;
        right: calc(100% - 1px);
        height: 100%;
        width: 100px;
        background-color: #1F6BB4;
        clip-path: polygon(100% 0, 0 0, 100% 101%); }
      .header-nav-top::after {
        content: "";
        position: absolute;
        top: 0;
        left: calc(100% - 1px);
        width: 1000px;
        background-color: #1F6BB4;
        height: 100%; }
    .header-nav-bottom {
      width: 100%;
      flex: 1; }
      .header-nav-bottom .menu {
        display: flex;
        flex-direction: row;
        list-style: none;
        justify-content: flex-end;
        align-items: center;
        height: 100%; }
        .header-nav-bottom .menu-item {
          white-space: nowrap;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative; }
          .header-nav-bottom .menu-item:not(:first-child) {
            margin-left: 22px; }
            @media (min-width: 1200px) {
              .header-nav-bottom .menu-item:not(:first-child) {
                margin-left: 28px; } }
            @media (min-width: 1400px) {
              .header-nav-bottom .menu-item:not(:first-child) {
                margin-left: 36px; } }
            @media (min-width: 1400px) {
              .header-nav-bottom .menu-item:not(:first-child) {
                margin-left: 36px; } }
            @media (min-width: 1680px) {
              .header-nav-bottom .menu-item:not(:first-child) {
                margin-left: 3.4vw; } }
          .header-nav-bottom .menu-item > a {
            color: #171717;
            font-weight: 700;
            text-transform: uppercase;
            font-size: 1.6rem; }
            @media (min-width: 1400px) {
              .header-nav-bottom .menu-item > a {
                font-size: 1.7rem; } }
            @media (min-width: 1680px) {
              .header-nav-bottom .menu-item > a {
                font-size: 1.9rem; } }
          .header-nav-bottom .menu-item:hover > a, .header-nav-bottom .menu-item.active > a {
            color: #B50006; }
          .header-nav-bottom .menu-item:hover .menu-item__sub {
            display: block; }
          .header-nav-bottom .menu-item__sub {
            display: none;
            z-index: 999;
            background-color: transparent;
            position: absolute;
            top: 100%;
            left: 50%;
            transform: translateX(-50%); }
            .header-nav-bottom .menu-item__sub ul {
              border-top: 6px solid #1F6BB4;
              list-style-type: none;
              box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
              /* width */
              /* Tra  ck */
              /* Handle */
              /* Handle on hover */ }
              .header-nav-bottom .menu-item__sub ul::-webkit-scrollbar {
                width: 3px; }
              .header-nav-bottom .menu-item__sub ul::-webkit-scrollbar-track {
                background: #f1f1f1; }
              .header-nav-bottom .menu-item__sub ul::-webkit-scrollbar-thumb {
                background: #888; }
              .header-nav-bottom .menu-item__sub ul::-webkit-scrollbar-thumb:hover {
                background: #555; }
              .header-nav-bottom .menu-item__sub ul li {
                background-color: white;
                box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
                position: relative; }
                .header-nav-bottom .menu-item__sub ul li:not(:first-child) {
                  border-top: 1px solid #1F6BB4; }
                @media (max-height: 768px) {
                  .header-nav-bottom .menu-item__sub ul li {
                    margin-top: 0px; }
                    .header-nav-bottom .menu-item__sub ul li:first-child {
                      margin-top: 2px; } }
                .header-nav-bottom .menu-item__sub ul li.active > a {
                  color: #B50006; }
                .header-nav-bottom .menu-item__sub ul li a {
                  display: block;
                  width: 300px;
                  color: #171717;
                  font-weight: 400;
                  font-size: 1.5rem;
                  padding: 12px 16px;
                  display: flex;
                  flex-direction: row;
                  align-items: center; }
                  @media (min-width: 1680px) {
                    .header-nav-bottom .menu-item__sub ul li a {
                      font-size: 1.6rem; } }
                  .header-nav-bottom .menu-item__sub ul li a span {
                    flex: 1;
                    white-space: normal; }
                  .header-nav-bottom .menu-item__sub ul li a svg {
                    transform: rotate(-90deg); }
                  .header-nav-bottom .menu-item__sub ul li a:hover {
                    color: #B50006; }
                .header-nav-bottom .menu-item__sub ul li:hover ul {
                  display: block; }
                .header-nav-bottom .menu-item__sub ul li ul {
                  display: none;
                  position: absolute;
                  top: 0;
                  left: 100%; }
                  .header-nav-bottom .menu-item__sub ul li ul li {
                    margin-left: 8px; }
                    .header-nav-bottom .menu-item__sub ul li ul li:first-child {
                      margin-top: 0; }

.banner {
  position: relative;
  height: 0;
  padding-top: 160%; }
  @media (min-width: 576px) {
    .banner {
      padding-top: 120%; } }
  @media (min-width: 768px) {
    .banner {
      padding-top: 75%; } }
  @media (min-width: 992px) {
    .banner {
      padding-top: 56.25%; } }
  .banner-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }
    .banner-wrapper .swiper, .banner-wrapper .swiper-wrapper, .banner-wrapper .swiper-slide {
      height: 100%; }
  .banner-slide {
    height: 100%; }
  .banner-img {
    width: 100%;
    height: 100%; }
    .banner-img::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
      opacity: 0.3;
      border: 1px solid #707070;
      background-color: #171717; }
  .banner-content {
    z-index: 1;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 96%;
    color: white;
    text-align: center; }
    @media (min-width: 768px) {
      .banner-content {
        width: 82%; } }
    @media (min-width: 992px) {
      .banner-content {
        width: 74%; } }
    @media (min-width: 1200px) {
      .banner-content {
        width: 72%; } }
    @media (min-width: 1400px) {
      .banner-content {
        width: 58%; } }
    @media (min-width: 1680px) {
      .banner-content {
        width: 48%; } }
    .banner-content__head > * {
      color: inherit;
      font-weight: 700;
      font-size: 2.6rem;
      text-transform: uppercase; }
      @media (min-width: 768px) {
        .banner-content__head > * {
          font-size: 2.8rem; } }
      @media (min-width: 1400px) {
        .banner-content__head > * {
          font-size: 3.2rem; } }
    .banner-content__sub {
      margin-top: 15px; }
      .banner-content__sub > * {
        color: inherit;
        font-weight: 700;
        font-size: 5rem;
        text-transform: uppercase; }
        @media (min-width: 768px) {
          .banner-content__sub > * {
            font-size: 5.4rem; } }
        @media (min-width: 1200px) {
          .banner-content__sub > * {
            font-size: 6rem; } }
    .banner-content__desc {
      margin-top: 36px; }
      .banner-content__desc > * {
        font-weight: 700;
        font-size: 2rem; }
        @media (min-width: 768px) {
          .banner-content__desc > * {
            font-size: 2.2rem; } }
        @media (min-width: 1400px) {
          .banner-content__desc > * {
            font-size: 2.4rem; } }
    .banner-content__btn {
      margin-top: 56px; }

#pp-nav ul li {
  width: 10px;
  height: 10px;
  margin: 20px; }
  #pp-nav ul li a span {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-color: none;
    border: none;
    transition: .2s ease-in-out; }
  #pp-nav ul li a.active span {
    z-index: 2;
    width: 14px;
    height: 14px; }
  #pp-nav ul li a.active::after {
    position: absolute;
    top: 50%;
    left: 50%;
    content: "";
    width: 20px;
    height: 20px;
    background-color: #133C63;
    border-radius: 50%;
    transform: translate(-50%, -50%);
    z-index: -1; }

#breadcrumb {
  width: 100%; }

.breadcrumb {
  background-color: #F5F5F5;
  width: 100%; }
  .breadcrumb-list {
    padding-top: 15px;
    padding-bottom: 18px;
    display: flex;
    align-items: center;
    list-style-type: none;
    overflow: auto; }
  .breadcrumb-item {
    display: flex;
    align-items: center;
    line-height: 1.4;
    white-space: nowrap; }
    .breadcrumb-item svg path {
      stroke: #B50006; }
    .breadcrumb-item > * {
      font-size: 17px;
      color: #171717;
      margin-right: 12px;
      font-weight: 700; }
      @media (min-width: 768px) {
        .breadcrumb-item > * {
          font-size: 1.7rem; } }
      @media (min-width: 1200px) {
        .breadcrumb-item > * {
          font-size: 1.9rem; } }
    .breadcrumb-item.active .breadcrumb-item__link {
      color: #B50006; }

.swiper {
  width: 100%; }

.index-1 {
  background-image: url(../assets/images/index/welcome.png);
  background-size: cover;
  padding-bottom: 64px; }
  @media (min-width: 768px) {
    .index-1 {
      padding-bottom: 110px; } }
  .index-1 .container, .index-1 .section-popup .popup-video, .section-popup .index-1 .popup-video, .index-1 .section-popup .popup-album, .section-popup .index-1 .popup-album, .index-1 .index-3 .plane, .index-3 .index-1 .plane {
    overflow: initial; }
  .index-1__wrapper {
    margin: 0 auto; }
  .index-1 .wrapper-topImg {
    width: 100%;
    position: relative;
    padding-top: 62.5%; }
    .index-1 .wrapper-topImg img {
      position: absolute;
      top: 0;
      left: 0; }
  .index-1 .wrapper-topContent {
    margin-top: 18px;
    padding: 0px 8px; }
    @media (min-width: 992px) {
      .index-1 .wrapper-topContent {
        padding-left: 40px; } }
    @media (min-width: 1400px) {
      .index-1 .wrapper-topContent {
        padding-left: 80px; } }
    .index-1 .wrapper-topContent__infor {
      margin-top: 18px; }
      @media (min-width: 1400px) {
        .index-1 .wrapper-topContent__infor {
          margin-top: 24px; } }
      .index-1 .wrapper-topContent__infor > * {
        font-size: 2rem;
        color: #171717; }
        @media (min-width: 768px) {
          .index-1 .wrapper-topContent__infor > * {
            font-size: 2.2rem; } }
        @media (min-width: 1200px) {
          .index-1 .wrapper-topContent__infor > * {
            font-size: 2.4rem; } }
    .index-1 .wrapper-topContent__btn {
      margin-top: 25px; }
      @media (min-width: 992px) {
        .index-1 .wrapper-topContent__btn {
          margin-top: 30px; } }
      @media (min-width: 1400px) {
        .index-1 .wrapper-topContent__btn {
          margin-top: 40px; } }
  .index-1 .wrapper-botContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px 5px;
    margin-top: 12px; }
    @media (min-width: 1400px) {
      .index-1 .wrapper-botContent.right-border {
        border-right: 2px solid #1F6BB4; } }
    @media (min-width: 1400px) {
      .index-1 .wrapper-botContent {
        border-right: 2px solid #1F6BB4; } }
    .index-1 .wrapper-botContent.non-border {
      border-right: unset; }
    .index-1 .wrapper-botContent__img {
      height: 110px;
      display: flex;
      align-items: center; }
    .index-1 .wrapper-botContent__title {
      margin-top: 32px;
      text-align: center; }
      .index-1 .wrapper-botContent__title > * {
        font-weight: 700;
        font-size: 2rem;
        color: #0C1E72; }
        @media (min-width: 768px) {
          .index-1 .wrapper-botContent__title > * {
            font-size: 2.2rem; } }
        @media (min-width: 1400px) {
          .index-1 .wrapper-botContent__title > * {
            font-size: 2.4rem; } }
        @media (min-width: 576px) {
          .index-1 .wrapper-botContent__title > * {
            font-size: 2.6rem; } }
  @media (min-width: 576px) and (min-width: 768px) {
    .index-1 .wrapper-botContent__title > * {
      font-size: 2.8rem; } }
  @media (min-width: 576px) and (min-width: 1400px) {
    .index-1 .wrapper-botContent__title > * {
      font-size: 3.2rem; } }

.index-2__wrapper {
  padding-bottom: 64px; }
  @media (min-width: 768px) {
    .index-2__wrapper {
      padding-bottom: 110px; } }

.index-2 .wrapper-title {
  display: flex;
  align-items: center;
  flex-direction: column; }
  .index-2 .wrapper-title__infor > * {
    text-align: center;
    font-weight: 700;
    font-size: 1.8rem;
    color: #171717;
    line-height: 1.4; }
    @media (min-width: 768px) {
      .index-2 .wrapper-title__infor > * {
        font-size: 1.9rem; } }
    @media (min-width: 1400px) {
      .index-2 .wrapper-title__infor > * {
        font-size: 2rem; } }

.index-2 .wrapper-content {
  max-width: 1920px;
  margin: auto; }
  .index-2 .wrapper-content .swiper-slide:nth-child(even) .wrapper-post {
    flex-direction: column-reverse; }
  .index-2 .wrapper-content .wrapper-post {
    display: flex;
    width: 100%;
    flex-direction: column; }
    .index-2 .wrapper-content .wrapper-post__imgService {
      width: 100%;
      position: relative;
      flex: 1;
      padding-bottom: 118%; }
      @media (min-width: 576px) {
        .index-2 .wrapper-content .wrapper-post__imgService {
          padding-bottom: 100%; } }
      .index-2 .wrapper-content .wrapper-post__imgService img {
        flex: 1;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0; }
    .index-2 .wrapper-content .wrapper-post__detailService {
      position: relative;
      width: 100%;
      padding-bottom: 118%;
      flex: 1;
      background: #F6F6F6; }
      @media (min-width: 576px) {
        .index-2 .wrapper-content .wrapper-post__detailService {
          padding-bottom: 100%; } }
      .index-2 .wrapper-content .wrapper-post__detailService .wrapper-post_box {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center; }
      .index-2 .wrapper-content .wrapper-post__detailService .detailService-img {
        width: 35%;
        max-width: 150px; }
        .index-2 .wrapper-content .wrapper-post__detailService .detailService-img img {
          object-fit: contain; }
      .index-2 .wrapper-content .wrapper-post__detailService .detailService-decs {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0 2vw; }
      .index-2 .wrapper-content .wrapper-post__detailService .detailService-title > * {
        text-align: center;
        font-weight: 700;
        font-size: 2rem;
        color: #171717; }
        @media (min-width: 768px) {
          .index-2 .wrapper-content .wrapper-post__detailService .detailService-title > * {
            font-size: 2.2rem; } }
        @media (min-width: 1400px) {
          .index-2 .wrapper-content .wrapper-post__detailService .detailService-title > * {
            font-size: 2.4rem; } }
      .index-2 .wrapper-content .wrapper-post__detailService .detailService-infor > * {
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        overflow: hidden;
        font-size: 15px;
        text-align: center;
        color: #171717; }
        @media (max-width: 900px) {
          .index-2 .wrapper-content .wrapper-post__detailService .detailService-infor > * {
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden; } }
        @media (min-width: 768px) {
          .index-2 .wrapper-content .wrapper-post__detailService .detailService-infor > * {
            font-size: 1.5rem; } }
        @media (min-width: 1200px) {
          .index-2 .wrapper-content .wrapper-post__detailService .detailService-infor > * {
            font-size: 1.6rem; } }
      .index-2 .wrapper-content .wrapper-post__detailService-btn {
        margin-top: 24px; }

.index-2 .wrapper-btn {
  display: flex;
  justify-content: center; }

.index-3 {
  position: relative;
  z-index: 2; }
  .index-3 .traingle {
    position: absolute;
    z-index: 1;
    top: 97%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(300deg); }
  .index-3 .plane {
    position: absolute;
    z-index: 2;
    transform: translate(-50%, 50%);
    display: none;
    height: calc(20vw + 56px);
    bottom: 8%;
    left: 18%; }
    .index-3 .plane--animation {
      width: 100%;
      height: 100%; }
    .index-3 .plane img {
      width: auto;
      height: auto;
      max-height: 100%;
      object-fit: contain;
      object-position: left; }
    @media (min-width: 576px) {
      .index-3 .plane {
        display: block; } }
    @media (min-width: 576px) {
      .index-3 .plane {
        bottom: 4%;
        left: 10%; } }
    @media (min-width: 768px) {
      .index-3 .plane {
        height: calc(18vw + 56px);
        bottom: 8%;
        left: 18%; } }
    @media (min-width: 992px) {
      .index-3 .plane {
        height: calc(15vw + 56px);
        bottom: 7%;
        left: 30%; } }
    @media (min-width: 1200px) {
      .index-3 .plane {
        height: calc(180px + 120px);
        bottom: 2%;
        left: 45%; } }
  .index-3--layer-1 {
    background: #D1D1D1;
    clip-path: ellipse(120% 99% at 50% 0%); }
    @media (min-width: 768px) {
      .index-3--layer-1 {
        clip-path: ellipse(100% 99% at 50% 0%); } }
    @media (min-width: 992px) {
      .index-3--layer-1 {
        clip-path: ellipse(87% 99% at 50% 0%); } }
  .index-3--layer-2 {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    z-index: 1;
    position: relative;
    margin: auto;
    padding-top: 78px;
    padding-bottom: 20%;
    clip-path: ellipse(120% 78% at 50% 18%); }
    @media (min-width: 768px) {
      .index-3--layer-2 {
        padding-top: 56px; } }
    @media (min-width: 768px) {
      .index-3--layer-2 {
        padding-bottom: 18%;
        clip-path: ellipse(100% 78% at 50% 18%); } }
    @media (min-width: 992px) {
      .index-3--layer-2 {
        padding-bottom: 15%;
        clip-path: ellipse(87% 78% at 50% 19%); } }
    @media (min-width: 1200px) {
      .index-3--layer-2 {
        padding-bottom: 180px; } }
  .index-3 .overlay-img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1; }
  .index-3_wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto; }
    .index-3_wrapper-decs {
      font-size: 2.6rem;
      color: #171717;
      text-transform: uppercase;
      text-align: center;
      padding-left: 30px;
      padding-right: 30px; }
      @media (min-width: 768px) {
        .index-3_wrapper-decs {
          font-size: 2.8rem; } }
      @media (min-width: 1400px) {
        .index-3_wrapper-decs {
          font-size: 3.2rem; } }
    .index-3_wrapper-form {
      margin-top: 52px; }
      @media (min-width: 768px) {
        .index-3_wrapper-form {
          margin-top: 36px; } }
      .index-3_wrapper-form .form-input {
        /* FF 4-18 */
        /* FF 19+ */
        /* IE 10+ */ }
        .index-3_wrapper-form .form-input input, .index-3_wrapper-form .form-input textarea {
          width: 100%;
          min-height: 60px;
          border: none;
          padding: 10px;
          resize: none;
          box-shadow: 0 4px 10px rgba(0, 0, 0, 0.16);
          font-size: 15px;
          background-color: white;
          color: #171717; }
          @media (min-width: 768px) {
            .index-3_wrapper-form .form-input input, .index-3_wrapper-form .form-input textarea {
              font-size: 1.6rem; } }
          @media (min-width: 1400px) {
            .index-3_wrapper-form .form-input input, .index-3_wrapper-form .form-input textarea {
              font-size: 1.8rem; } }
          .index-3_wrapper-form .form-input input::placeholder, .index-3_wrapper-form .form-input textarea::placeholder {
            color: rgba(23, 23, 23, 0.75); }
        .index-3_wrapper-form .form-input input:focus::-webkit-input-placeholder,
        .index-3_wrapper-form .form-input textarea:focus::-webkit-input-placeholder {
          color: transparent; }
        .index-3_wrapper-form .form-input input:focus:-moz-placeholder,
        .index-3_wrapper-form .form-input textarea:focus:-moz-placeholder {
          color: transparent; }
        .index-3_wrapper-form .form-input input:focus::-moz-placeholder,
        .index-3_wrapper-form .form-input textarea:focus::-moz-placeholder {
          color: transparent; }
        .index-3_wrapper-form .form-input input:focus:-ms-input-placeholder,
        .index-3_wrapper-form .form-input textarea:focus:-ms-input-placeholder {
          color: transparent; }
      .index-3_wrapper-form > *:not(:last-child) {
        margin-bottom: 20px; }
        @media (min-width: 768px) {
          .index-3_wrapper-form > *:not(:last-child) {
            margin-bottom: 38px; } }

.index-4 {
  position: relative;
  z-index: 1;
  padding-top: 15%;
  margin-top: -15%;
  background-repeat: no-repeat;
  background-size: cover; }
  .index-4_wrapper {
    margin: auto;
    position: relative;
    z-index: 1; }
  .index-4::after {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    content: "";
    background: #1f6bb4;
    background: linear-gradient(140deg, #1f6bb4 19%, white 100%);
    opacity: .15;
    pointer-events: none; }
  .index-4_content {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    margin-top: 48px; }
    @media (min-width: 1200px) {
      .index-4_content {
        padding: 0 30px;
        margin-top: 0px; } }
    .index-4_content-decs {
      margin-top: 10px;
      font-weight: 700;
      font-size: 1.8rem;
      line-height: 1.5; }
      @media (min-width: 768px) {
        .index-4_content-decs {
          font-size: 1.9rem; } }
      @media (min-width: 1400px) {
        .index-4_content-decs {
          font-size: 2rem; } }
  .index-4_swiper .swiper-button-prev,
  .index-4_swiper .swiper-button-next {
    width: 50px;
    height: 50px;
    background-color: #1F6BB4; }
    @media (max-width: 768px) {
      .index-4_swiper .swiper-button-prev,
      .index-4_swiper .swiper-button-next {
        width: 40px;
        height: 40px; } }
    .index-4_swiper .swiper-button-prev::after,
    .index-4_swiper .swiper-button-next::after {
      content: none; }
  .index-4_swiper .swiper-button-prev {
    left: -82px; }
    @media (max-width: 1024px) {
      .index-4_swiper .swiper-button-prev {
        top: 30%;
        left: 0px; } }
  .index-4_swiper .swiper-button-next {
    right: -82px; }
    @media (max-width: 1024px) {
      .index-4_swiper .swiper-button-next {
        top: 30%;
        right: 0px; } }
    .index-4_swiper .swiper-button-next svg {
      transform: rotate(180deg); }
  .index-4_swiper .index-news {
    height: 100%; }
  .index-4_btnall {
    width: 100%;
    text-align: center; }
  .index-4_partner .partner .swiper {
    padding-bottom: 82px; }
    @media (max-width: 768px) {
      .index-4_partner .partner .swiper {
        padding-bottom: 48px; } }
    .index-4_partner .partner .swiper .swiper-slide {
      box-shadow: 0 4px 10px rgba(0, 0, 0, 0.16);
      background-color: white;
      padding: 20px 20px; }

.news-item {
  margin-bottom: 24px;
  padding-bottom: 30px;
  border-bottom: 0.5px solid #707070; }
  @media (min-width: 992px) {
    .news-item {
      margin-bottom: 32px; } }
  @media (min-width: 1400px) {
    .news-item {
      margin-bottom: 40px; } }
  @media (min-width: 992px) {
    .news-item {
      padding-bottom: 40px; } }
  @media (min-width: 1400px) {
    .news-item {
      padding-bottom: 52px; } }

.news-nav {
  height: 100px;
  background-color: red; }

.achievement-1 .wrapper2:not(:first-child) .title-wrapper {
  padding-top: 27px;
  margin-top: 42px;
  border-top: 1px solid #707070; }

.si-1 .button-choose {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  background-color: #1F6BB4;
  border: 1px solid #1F6BB4;
  padding: 22px 8px;
  font-weight: 400;
  font-size: 18px;
  cursor: pointer;
  transition: .3s all linear;
  user-select: none;
  white-space: nowrap; }
  @media (min-width: 576px) {
    .si-1 .button-choose {
      font-size: 2vw; } }
  @media (min-width: 1200px) {
    .si-1 .button-choose {
      font-size: 1.2vw; } }
  @media (min-width: 1400px) {
    .si-1 .button-choose {
      font-size: 2rem; } }
  .si-1 .button-choose.active, .si-1 .button-choose:hover, .si-1 .button-choose:focus {
    background-color: white;
    color: #171717; }

.si-1__section {
  display: none; }
  .si-1__section.active {
    display: block; }
  .si-1__section-form .block-form:not(:first-child) {
    margin-top: 40px; }

@media (min-width: 768px) {
  .ca-1-list .ca-1__col:nth-of-type(2n + 1) {
    border-right: 1px solid #707070; } }

.ca-1-list .ca-1__col:nth-of-type(n + 2) .ca-1__item {
  padding-top: 12px;
  margin-top: 12px;
  border-top: 1px solid #707070; }
  @media (min-width: 768px) {
    .ca-1-list .ca-1__col:nth-of-type(n + 2) .ca-1__item {
      padding-top: 0;
      margin-top: 0;
      border-top: none; } }

.ca-1__item {
  height: 100%; }

.ca-1-map {
  position: relative;
  width: 100%;
  height: 56.25vw;
  min-height: 450px;
  max-height: 725px; }
  .ca-1-map > * {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.unit-table__item:not(:first-child) {
  margin-top: 16px; }

.surcharge-table__item-title {
  margin-bottom: 10px; }
  .surcharge-table__item-title > * {
    font-size: 15px;
    color: #B50006;
    font-weight: 700; }
    @media (min-width: 768px) {
      .surcharge-table__item-title > * {
        font-size: 1.5rem; } }
    @media (min-width: 1200px) {
      .surcharge-table__item-title > * {
        font-size: 1.6rem; } }

.surcharge-table__item:not(:first-child) {
  margin-top: 24px; }
  @media (min-width: 768px) {
    .surcharge-table__item:not(:first-child) {
      margin-top: 30px; } }

.container-1-table__item:not(:first-child) {
  margin-top: 32px; }
  @media (min-width: 768px) {
    .container-1-table__item:not(:first-child) {
      margin-top: 40px; } }

.schedule-content {
  display: flex;
  flex-direction: column; }
  .schedule-content > * {
    padding-bottom: 20px;
    border-bottom: 1px solid #707070; }
    .schedule-content > *:not(:last-child) {
      margin-bottom: 20px; }

.transport .container, .transport .section-popup .popup-video, .section-popup .transport .popup-video, .transport .section-popup .popup-album, .section-popup .transport .popup-album, .transport .index-3 .plane, .index-3 .transport .plane {
  overflow: initial; }

.video-wrapper > *:first-child .box-2__img {
  padding-top: 56.25%; }

.video-wrapper > *:first-child .box-2__video {
  padding-top: 56.25%; }

.search-more,
.video-more,
.image-more {
  text-align: center; }
  @media (min-width: 1200px) {
    .search-more,
    .video-more,
    .image-more {
      text-align: left; } }

.search-wrapper .total > * {
  color: #707070;
  font-size: 1.8rem; }
  @media (min-width: 768px) {
    .search-wrapper .total > * {
      font-size: 1.9rem; } }
  @media (min-width: 1400px) {
    .search-wrapper .total > * {
      font-size: 2rem; } }

.search-wrapper .content > * {
  margin-top: 32px; }

.search-wrapper .content-item a {
  font-weight: 700;
  color: #133C63;
  font-size: 2rem;
  transition: .3s all; }
  @media (min-width: 768px) {
    .search-wrapper .content-item a {
      font-size: 2.2rem; } }
  @media (min-width: 1400px) {
    .search-wrapper .content-item a {
      font-size: 2.4rem; } }

.search-wrapper .content-item p {
  margin-top: 8px; }

.search-wrapper .content-item:hover a {
  color: #E50019; }
