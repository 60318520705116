.achievement-1{
    .wrapper2{
        &:not(:first-child){
            .title-wrapper{
                padding-top: 27px;
                margin-top: 42px;
                border-top: 1px solid map-get($color, bor2)
            }
        }
    }
}