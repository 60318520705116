/*!
* source: https://getbootstrap.com/
* Bootstrap Grid v5.0.2 (https://getbootstrap.com/)
* Copyright 2011-2021 The Bootstrap Authors
* Copyright 2011-2021 Twitter, Inc.
* Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
*/

// mobile first

.container {
  --bs-gutter-x: -1.5rem;

  width: 96%;
  // padding-right: var(--bs-gutter-x, 0.75rem);
  // padding-left: var(--bs-gutter-x, 0.75rem);
  margin-right: auto;
  margin-left: auto;
  overflow: hidden;
}

@each $key, $size in $mdWidth {
  @media (min-width: $size){  //> 576px
    .container{
      --bs-gutter-x: 0;
      max-width: map-get($containerWidth, $key);
    }
  }
}

.col {
  flex: 1 0 0%;
}

.row{
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;

  // xét cho 2 bên hông ra ngoài sát viền và top
  margin-top: calc(var(--bs-gutter-y) * -1);
  margin-right: calc(var(--bs-gutter-x) * -.5);
  margin-left: calc(var(--bs-gutter-x) * -.5);
  width: calc(100% + (var(--bs-gutter-x) * 0.5 * 2));

  &>* {
    box-sizing: border-box;
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;

    // khoảng 2 column top và left right
    padding-right: calc(var(--bs-gutter-x) * .5);
    padding-left: calc(var(--bs-gutter-x) * .5);
    margin-top: var(--bs-gutter-y);
  }
  &-cols{
    &-auto>* {
      flex: 0 0 auto;
      width: auto;
    }
    
    &-1>* {
      flex: 0 0 auto;
      width: 100%;
    }
    
    &-2>* {
      flex: 0 0 auto;
      width: 50%;
    }
    
    &-3>* {
      flex: 0 0 auto;
      width: 33.3333333333%;
    }
    
    &-4>* {
      flex: 0 0 auto;
      width: 25%;
    }
    
    &-5>* {
      flex: 0 0 auto;
      width: 20%;
    }
    
    &-6>* {
      flex: 0 0 auto;
      width: 16.6666666667%;
    }
  }
}

@each $key, $size in $mdWidth{
  @media (min-width: $size){
    .col-#{$key}{
      flex: 1 0 0%;
    }

    .row-cols-#{$key}-auto>* {
      flex: 0 0 auto;
      width: auto;
    }

    @each $num, $valNum in $flexRow{
      .row-cols-#{$key}-#{$num}>*{
        flex: 0 0 auto;
        width: $valNum;
      }
    }
  }
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

@each $colNum, $colVal in $flexCol{
  .col-#{$colNum}{
    flex: 0 0 auto;
    width: $colVal;
  }
  .col-#{$colNum}-important{
    flex: 0 0 auto !important;
    width: $colVal !important;
  }
}

@each $offNum, $offVal in $flexOffset{
  .offset-#{$offNum} {
    margin-left: $offVal;
  }
}

@each $gutNum, $gutVal in $flexGutter{
  .gx-#{$gutNum} {
    --bs-gutter-x: #{$gutVal};
  }
  .gy-#{$gutNum} {
    --bs-gutter-y: #{$gutVal};
  }
}

@each $key, $size in $mdWidth{
  @media (min-width: $size){
    .col-#{$key}-auto {
      flex: 0 0 auto;
      width: auto;
    }
    @each $colNum, $colVal in $flexCol{
      .col-#{$key}-#{$colNum}{
        flex: 0 0 auto;
        width: $colVal;
      }
    }

    @each $offNum, $offVal in $flexOffset{
      .offset-#{$key}-#{$offNum}>*{
        margin-left: $offVal;
      }
    }

    @each $gutNum, $gutVal in $flexGutter{
      .gx-#{$key}-#{$gutNum} {
        --bs-gutter-x: #{$gutVal};
      }
      .gy-#{$key}-#{$gutNum} {
        --bs-gutter-y: #{$gutVal};
      }
    }

    // .gx-lg-4 // padding-left: 1.5rem 
  }
}

/*# sourceMappingURL=bootstrap-grid.css.map */