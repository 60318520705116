.btn-seemore{
    display: inline-flex;
    align-items: center;
    color: white;

    &_link{
        transition: .3s ease-in-out;
        margin-left: 10px;
        text-transform: uppercase;
        color: map-get($color , tx1 );
        // @include family--second;
        @include bold;
        @include sizeBs(small);
    }
    .btn-svg{
        margin-bottom: -3px;
        transition: .3s ease-in-out;
    }
    &:hover{
        .btn-svg{
            transform: rotate(-45deg);
        }
        .btn-seemore_link{
            color: map-get($color, fill3 );
        }
    }
    &.nonupercase{
        .btn-seemore_link{

            text-transform: capitalize;
        }
    }
    &.bg{
        padding: 15px 25px;
        background-color: map-get($color, mode2 );
        transition: .3s ease-in-out;
        position: relative;
        &.full-width{
            width: 100%;
            text-align: center;
            display: flex;
            justify-content: center;
        }
        &:hover{
            background-color: map-get($color, mode1 );
            .btn-seemore_link{
                color: white;
            }
            .btn-svg{
    
                path{
                    stroke: white;
                }
            }
        }
        .btn-seemore_link{
            color: white;
        }
        .btn-svg{
            path{
                stroke: white;
            }
        }
    }

    &.red{
        padding: 15px 25px;
        background-color: map-get($color, mode3);
        transition: .3s ease-in-out;
        position: relative;
        &.full-width{
            text-align: center;
            display: flex;
            justify-content: center;
        }
        &:hover{
            background-color: map-get($color, mode3);
            .btn-seemore_link{
                color: white;
            }
            .btn-svg{
    
                path{
                    stroke: white;
                }
            }
        }
        .btn-seemore_link{
            color: white;
        }
        .btn-svg{
            path{
                stroke: white;
            }
        }
    }

    &.calling{
        opacity: .6;
        pointer-events: none;
        cursor: initial;
        background-color: map-get($color, mode1 );

        .btn-svg{
            transform: rotate(-45deg);
        }
        .btn-seemore_link{
            color: white;
        }
    }
}