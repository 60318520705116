@keyframes rotateSvg {
    0%{
        transform: rotate(0);
    }
    100%{
        transform: rotate(359deg);
    }
}
@keyframes strokeSvg {
    0%{
        stroke-dashoffset: 0;
        stroke-dasharray: 15 5;
    }
    100%{
        stroke-dashoffset: -179;
        stroke-dasharray: 15 5;
    }
}

.settings{
    z-index: 999;
    position: fixed;
    top: 20%;
    right: 0%;
    // transform: translateY(-50%);
    --timeAnimation: 3s;

    display: none;

    @include rpmin(lg){
        display: block;
    }

    &-item{
        &:not(:first-child){
            margin-top: 38px;
        }

        .fixed{
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            align-items: flex-start;

            // --bgFixed: #1F6BB4;
            // --fillFixed: #fff;

            &.fixed--white{
                --bgFixed: #fff;
                --fillFixed: #1F6BB4;

                // .fixed-list{
                //     @include shadow(s1);
                // }

                --fillhover01: #1F6BB4;
                --fillhover02: #fff;

                .fixed{
                    &-item{
                        &__icon{
                            path{
                                transition: .5s ease;
                            }
                        }

                        &:hover{
                            .pathFixed01{
                                fill: var(--fillhover01);
                            }
                            .pathFixed02{
                                fill: var(--fillhover02);
                            }
                        }
                    }
                }
            }

            &.fixed--main{
                --bgFixed: #1F6BB4;
                --fillFixed: #fff;

                --fillhover: #1F6BB4;

                .fixed{
                    &-item{
                        &__icon{
                            path{
                                transition: .5s ease;
                            }
                        }

                        &:hover{
                            .fixed-item__icon{
                                path{
                                    fill: var(--fillhover);
                                }
                            }
                        }
                    }
                }
            }

            &-icons{
                background-color: var(--bgFixed);
                width: 77px;
                height: 77px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 50% 0 0 50%;

                .rotateSvg{
                    transform-origin: center;
                    animation: rotateSvg var(--timeAnimation) linear infinite;
                }

                .strokeSvg{
                    animation: strokeSvg var(--timeAnimation) linear infinite;
                }

                cursor: pointer;
                &.clicked ~ .fixed-list{
                    transform: scale(1);
                    height: auto;
                    width: auto;
                    padding: 20px 18px;
                    border-radius: 0 0 0 20px;
                }
            }

            &-list{
                background-color: var(--bgFixed);
                // padding: 20px 18px;
                // border-radius: 0 0 0 20px;

                height: 0;
                width: 0;
                padding: 0;
                transform: scale(0);
                transform-origin: top left;
                transition: .5s ease;
            }

            

            &-item{
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                padding: 20px 10px;
                min-width: 105px;

                border: 1px solid var(--fillFixed);
                border-radius: 10px;

                &:not(:first-child){
                    margin-top: 16px;
                }

                
                &__title{
                    margin-top: 10px;
                    &>*{
                        transition: .5s ease;
                        @include bold;
                        color: var(--fillFixed);
                        @include sizeBs(small);
                    }
                }
                cursor: pointer;
                transition: .5s ease;

                &:hover{
                    background-color: var(--fillFixed);
                    
                    .fixed-item__title{
                        &>*{
                            color: var(--bgFixed);
                        }
                    }
                }
            }
        }
    }
}

.scroll-to-top{
    z-index: 999;
    position: fixed;
    bottom: 20%;
    right: 20px;

    width: 28px;
    height: 28px;

    background-color: map-get($color, fill2);

    display: flex;
    justify-content: center;
    align-items: center;

    @include shadow(s1);
    svg{
        transform: rotate(-90deg);
        transform-origin: center;
    }

    cursor: pointer;
    transition: .5s ease;
    &:hover{
        background-color: map-get($color, hover1);
    }
}