#pp-nav{
    ul li{
        width: 10px;
        height: 10px;
        margin: 20px ;
        a{
            span{
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                background-color: map-get($color, black-3 );
                border-color: none;
                border: none;
                transition: .2s ease-in-out;
            }
            &.active{
                span{
                    background-color: map-get($color, main-second );
                    z-index: 2;
                    width: 14px;
                    height: 14px;
                }
                &::after{
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    content: "";
                    width: 20px;
                    height: 20px;
                    background-color: map-get($color, main );
                    border-radius: 50%;
                    transform: translate(-50%, -50%);
                    z-index: -1;
                }
            }
        }
    }
}