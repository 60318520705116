.post-1 {
    &__content{
        margin-top: 16px;
        @include rpmin(md){
            margin-top: 22px;
        }
        // max-height: 62px;
        // overflow: hidden;
        &>*{
            @include  size(small);
            text-align: center;
            color: map-get($color, tx7);
            // @include p-line-clamp(3);
        }
        .font24{
            @include  size(big);
            text-align: center;
            @include bold;
            color: map-get($color, tx1 );
        }
    }

}