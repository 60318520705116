.news{
    &-item{
        &{
            // margin-top: 40px;
            // padding-top: 52px;
            @include blockBs(b40, margin-bottom);
            @include blockBs(b52, padding-bottom);
            border-bottom: 0.5px solid map-get($color, bor2);
        }
    }
    &-nav{
        height: 100px;
        background-color: red;
    }
}