.box-pagination{
    @include blockBs(b40, margin-top);

    list-style-type: none;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

    &>*{
        width: 40px;
        height: 40px;

        background-color: map-get($color, mode2);

        color: white;
        @include sizeBs(bodyText2);
        @include bold;

        white-space: nowrap;

        cursor: pointer;

        &.active{
            background-color: map-get($color, fill9);
            cursor: initial;
        }

        display: flex;
        justify-content: center;
        align-items: center;


        &:not(:first-child){
           margin-left: 8px; 
        }

        &.btn-hidden{
            opacity: 0.6;
            cursor: initial;
        }
    }

    .next{
        transform: rotate(180deg);
    }
}