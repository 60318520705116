.block-services {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    &__img {
        @include shadow(s1);
        width: 100%;
        overflow: hidden;
        padding-bottom: 70%;
        position: relative;
        margin-bottom: 20px;
        transition: .5s ease;

        .services-img {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
        }

        .services-hover {
            overflow: hidden;
            transition: .5s ease;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 0;
            background-color: white;
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;

            &_img {
                flex: 2;
                display: flex;
                align-items: center;
                position: relative;

                &::after {
                    position: absolute;
                    bottom: 15px;
                    left: 50%;
                    transform: translateX(-50%);
                    background-color: map-get($color, mode2);
                    content: "";
                    width: 70%;
                    height: 1px;
                }

                img {
                    height: 50%;
                    object-fit: contain;
                    opacity: 0;
                }
            }

            &_decs {
                flex: 1;
                color: map-get($color, mode2);
                @include sizeBs(bodyText1);
                text-align: center;
            }

        }

        img {
            transition: .5s ease;
        }
    }

    &:hover {
        .block-services__img {
            .services-hover {
                height: 100%;
                &_img {
                    img {
                        opacity: 1;
                    }
                }
            }
        }

        .block-services__decs {
            &-title {
                color: map-get($color, tx2);
            }
        }

        .btn-svg {
            transform: rotate(-45deg);
        }

        .btn-seemore_link {
            color: map-get($color, fill3);
        }

    }

    &__decs {
        height: 100%;
        width: auto;
        display: flex;
        flex-direction: column;

        // flex: 1;
        &>*:not(:last-child) {
            margin-bottom: 10px;

        }

        &-title {
            transition: .3s ease;
            @include p-line-clamp(3);
            @include sizeBs(bodyText1);
            color: map-get($color, txt1);
            line-height: 1.4;
        }

        &-infor {
            color: map-get($color, txt1);
            line-height: 1.3;
            @include p-line-clamp(3);
        }

        &-btn {
            position: relative;
            z-index: 2;
            margin-top: auto;
        }
    }
}