.schedule{
    &-content{
        display: flex;
        flex-direction: column;

        &>*{
            padding-bottom: 20px;
            border-bottom: 1px solid map-get($color, bor2);
            &:not(:last-child){
                margin-bottom: 20px;
            }
        }
    }
}