.table_title{
    text-align: center;
    padding: 20px 0;
    @include sizeBs(bodyText1);
    color:  white;
    background-color: map-get($color,fill2  );
    
}
.block-table_2{
    overflow: scroll;

    &::-webkit-scrollbar {
        display: none;
    }
    .table_2{
        width: 100%;
        tr{
            // th{
            //     // white-space: nowrap;
            // }
            td,th{
                min-width: 40px;
                padding: 15px 4px;
                @include rpmin(lg){
                    padding: 15px 10px;
                }

                text-align: center;
                background-color: map-get($color,fill5  );
                color: map-get($color,txt1);
                &.bg-blue{
                    background-color: map-get($color,fill2  );
                    color:white;
                    min-height: 80px;
                    @include bold;
                }
                &.f-30{
                    width: 30%;
                }
                &.f-35{
                    width: 35%;
                }
                &.f-40{
                    width: 40%;
                }
                &.f-45{
                    width: 45%;
                }
                &.f-50{
                    width: 50%;
                }
                &.bold{
                    @include bold;
                }

                &.text{
                    min-width: 180px;
                }
            }
            
        }
    }
    table, th, td{
        border: solid 1px rgba($color: map-get($color, bor2 ), $alpha: .4);
        border-collapse: collapse;
    }
}